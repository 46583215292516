import { Col } from "@amzn/stencil-react-components/layout";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { flushBuffer, trackStructEvent } from "@snowplow/browser-tracker";
import { lazy, Suspense, useContext, useEffect } from "react";
import { FormattedMessage, useIntl } from "react-intl";

import { BLOCKING_ERROR_TYPE } from "@/apis/constants";
import { Logger } from "@/apis/Logger";
import AllStepsCompleted from "@/components/AllStepsCompleted";
import ErrorBanner from "@/components/Commons/ErrorBanner";
import { AppNotAvailableError } from "@/components/Commons/ErrorBanner/AppNotAvailableError";
import { IneligibleError } from "@/components/Commons/ErrorBanner/IneligibleError";
import StepContainer from "@/components/Commons/StepContainer";
import { DAY1_ONBOARDING_STEPS, Step } from "@/components/constants";
import { ChatWithITSupportFlyoutLink } from "@/components/GetSupport";
import { setStepCache } from "@/helpers/CachedStepProgress";
import { getOS } from "@/helpers/os-helper";
import { useCheckReqs } from "@/hooks/useCheckReqs";
import { useLoadRegion } from "@/hooks/useLoadRegion";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import {
  Day1EventAction,
  Day1EventCategory,
  Day1EventLabel,
} from "@/types/snowplow-events";

const OS = getOS();

/**
 * Returns a filtered list of the steps that are relevant to display for this platform.
 * @param steps A full step list.
 */
export const getEnabledStepsForPlatform = (steps: Step[]) => {
  return steps.filter((step) => !step.platforms || step.platforms.includes(OS));
};
/**
 * Declare the constant outside the React component to avoid infinite re-rendering when the global app state is used
 * in the Home component.
 */
const ONBOARDING_STEPS: JSX.Element[] = getEnabledStepsForPlatform(
  DAY1_ONBOARDING_STEPS
).map((step) => {
  /**
   * Lazy load each step component. They are only loaded when it's rendered. Make sure a default export is created in
   * index.tsx of each step component directory. The step id should also match with the component directory to
   * ensure the step component to be loaded properly.
   * https://blog.logrocket.com/speed-up-react-app-dynamic-imports-route-centric-code-splitting/
   */
  const StepContent = lazy(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return import(`../${step.id}`).catch(
      () =>
        void Logger.error(
          `Unable to load the component for the step id: ${step.id}.`
        )
    );
  });

  return (
    /**
     * React.Suspense() allows us to conditionally suspend the rendering of a component until it has loaded.
     */
    <Suspense key={step.id} fallback={null}>
      <StepContainer stepId={step.id}>
        <StepContent />
      </StepContainer>
    </Suspense>
  );
});

export default function HomeContent(): JSX.Element {
  const intl = useIntl();

  /** Identity the healthy region and init Amplify configuration */
  const { isError: isLoadRegionError } = useLoadRegion({});

  const {
    isFetched: isCheckReqsFetched,
    isError: isCheckReqsError,
    candidateLocalStartDate,
    blockingErrorType,
  } = useCheckReqs();
  const { state, dispatch } = useContext(AppContext);
  const allStepsCompleted = getEnabledStepsForPlatform(state.steps).every(
    (step) => step.completed
  );

  /**
   * Set the refPage to provide context to IT Support should the new hire initiate
   * a chat with support
   */
  useEffect(() => {
    if (
      blockingErrorType === BLOCKING_ERROR_TYPE.BeforeDay1 &&
      candidateLocalStartDate
    ) {
      dispatch({
        type: ActionType.SET_CHAT_SUPPORT_REF_PAGE,
        chatSupportRefPage: "ezo-blocking-error-not-day-1",
      });
    }
  }, [blockingErrorType, candidateLocalStartDate]);

  useEffect(() => {
    if (allStepsCompleted) {
      trackStructEvent({
        category: Day1EventCategory.Complete,
        action: Day1EventAction.SectionToggled,
      });
      dispatch({
        type: ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT,
        nonDirectedChatSupportContext: "ON_WIZARD_COMPLETED_STEP",
      });
      // Force all buffered events to be flushed since this is the end of the app flow.
      // Otherwise buffered events may be lost.
      flushBuffer();
    }
  }, [allStepsCompleted]);

  // Keep the cache up to date when the wizard advances.
  useEffect(() => {
    setStepCache(state.currentStepNum - 1);
  }, [state.currentStepNum]);

  if (
    blockingErrorType === BLOCKING_ERROR_TYPE.BeforeDay1 &&
    candidateLocalStartDate
  ) {
    return (
      <ErrorBanner>
        <FormattedMessage
          id="error.beforeStartDate"
          values={{
            chatWithITSupportLink: (
              <ChatWithITSupportFlyoutLink
                localeMessageId="text.chatWithITSupport"
                metricProps={{
                  category: Day1EventCategory.BlockingError,
                  action: Day1EventAction.ChatWithSupport,
                  label: Day1EventLabel.NotDay1,
                }}
              />
            ),
            startDate: intl.formatDate(candidateLocalStartDate.toDate(), {
              month: "long",
              day: "numeric",
            }),
          }}
        />
      </ErrorBanner>
    );
  }

  /**
   * Failed to resolve a healthy EZO API region, so EZO is unavailable.
   * Direct the new hire to use First Aid and engage their manager to
   * onboard with virtual new hire orientation (vNHO)
   */
  if (isLoadRegionError) {
    return <AppNotAvailableError />;
  }

  if (isCheckReqsError) {
    return <IneligibleError />;
  }

  if (isCheckReqsFetched) {
    return (
      <Col gridGap="S400">
        {ONBOARDING_STEPS}
        {allStepsCompleted && <AllStepsCompleted />}
      </Col>
    );
  }

  return (
    <Spinner
      dataTestId="homeContentSpinner"
      showText
      size={SpinnerSize.Medium}
    />
  );
}
