import { languageObject } from "./locales";

/**
 * This locale file will be used by the application to change the language to Spanish
 * based on user selection.
 **/

const spanish: languageObject = {
  "page.title": "Día 1 de Amazon",
  "button.next": "Próxima",
  "button.back": "Atrás",
  "button.done": "Hecho",
  "text.then": "Entonces",
  "text.chatWithITSupport": "Chatea con el soporte de IT",
  "videoGuidance.title": "Guía en vídeo",
  "videoGuidance.example": "Ejemplo",
  "videoGuidance.button.altText": "Mostrar guía en vídeo",
  "icon.title": "Completado",
  "error.notSupportVideoTag": "Su navegador no admite la etiqueta de vídeo.",
  "welcome.expander.title": "Bienvenido a Amazon 🎉",
  "welcome.expander.body.greeting":
    "Nos alegra que estés aquí. Le guiaremos en la configuración de su nuevo portátil.",
  "welcome.expander.body.requirements.start": "Lo que necesitará:",
  "welcome.expander.body.requirements.list1":
    "Tu clave de seguridad te la enviamos por correo.",
  "welcome.expander.body.requirements.list2": "Una conexión wifi.",
  "welcome.expander.body.requirements.list3":
    "Su nombre de usuario y contraseña del portal de preembarque de Embark.",
  "welcome.expander.body.requirements.list4":
    "Acceda a su correo electrónico personal para recibir información adicional durante todo el proceso.",
  "welcome.expander.body.firstStep.start": "Primer paso: Conéctese a Wi-Fi",
  "welcome.expander.body.firstStep.list1":
    "Haz clic en el icono de Wi-Fi de la barra de tareas.",
  "welcome.expander.body.firstStep.list2": "Selecciona tu conexión Wi-Fi.",
  "welcome.expander.body.firstStep.list3":
    'Ingresa tu contraseña de Wi-Fi y haz clic en "Conectar".',
  "welcome.button.getStarted": "Comenzar",
  "identityCheck.expander.title": "Verificación de identidad",
  "identityCheck.expander.body.header": "Grabar vídeos",
  "identityCheck.expander.body.intro":
    "Graba vídeos con tus documentos de identidad junto a tu rostro y realiza una serie de gestos con las manos.",
  "identityCheck.expander.body.recordInfo.title":
    'Información sobre "Grabar vídeos"',
  "identityCheck.expander.body.recordInfo.body":
    'Antes de emitir las credenciales de acceso al sistema de Amazon, Amazon debe verificar tu identidad. Para ello, Amazon utiliza un sistema de verificación de identidad ("Sistema"). El uso del Sistema es completamente opcional y voluntario. El sistema funciona de la siguiente manera: sube una foto de una identificación con foto emitida por el gobierno ("Govt. Identifique con fotografía") y grabe un video en el que aparezca sosteniendo su identificación con fotografía. El nombre que aparece en el documento de identidad con fotografía debe ser sustancialmente similar al nombre que Amazon tiene registrado para ti. Uno de nuestros ingenieros de soporte verificará tu nombre y foto en tu foto de identidad con tu rostro en la grabación enviada.',
  "identityCheck.expander.body.whatIsCollected.title":
    "Qué información se recopila",
  "identityCheck.expander.body.whatIsCollected.intro":
    "Para verificar tu identidad, Amazon recopilará la siguiente información personal sobre ti:",
  "identityCheck.expander.body.whatIsCollected.item1":
    "Foto (s) de su identificación gubernamental con fotografía",
  "identityCheck.expander.body.whatIsCollected.item2":
    "Una autofoto tuya en vídeo",
  "identityCheck.expander.body.whatIsCollected.purpose":
    "Su información personal se utilizará únicamente con fines de identificación y verificación. Amazon destruirá toda la información personal en un plazo de 7 días a partir de la finalización del proceso de verificación, a menos que la ley exija conservar tu información personal durante un período más largo. Para obtener más información, consulte el Aviso de privacidad de recursos humanos de Amazon, que se incluye como parte de su paquete de incorporación, o a través del portal interno de Amazon, AtoZ, al completar el proceso de configuración del portátil.",
  "identityCheck.expander.body.light.instruction":
    "Una vez que comiences el desafío de gestos de identificación, tendrás <b>10 minutos</b> para grabar y enviar un único <b>vídeo de 10 segundos</b> en el que te veas completando los gestos.",
  "identityCheck.expander.body.helpLinkPre":
    "¿No puedes completar las acciones? Por favor, {chatWithITSupportLink} para verificar tu identidad en una videollamada de Chime.",
  "identityCheck.expander.body.helpLinkPost":
    "para verificar tu identidad en una videollamada de Chime.",
  "identityCheck.challenges.title": "Acciones de desafío",
  "identityCheck.challenges.completed":
    "Se han registrado tus acciones de desafío.",
  "identityCheck.challenges.instructions.completeByTime":
    "Completa este desafío antes del {time}",
  "identityCheck.challenges.instructions":
    "Sigue las instrucciones para grabar un único vídeo de 10 segundos realizando estos dos gestos:",
  "identityCheck.challenges.expired":
    "<b>Tu límite de tiempo ha caducado. </b>Por favor {chatWithIt}. Se le pedirá que participe en una videollamada y muestre su identificación a un ingeniero de soporte de IT.",
  "identityCheck.challenges.instructions.unhideButton":
    "Iniciar desafío de gestos",
  "identityCheck.challenges.forSeconds": "durante {second} segundos.",
  "identityCheck.button.recording": "Grabación: {countDownTimer}",
  "identityCheck.button.startRecord": "Comenzar a grabar",
  "identityCheck.button.enableCamera": "Activar cámara",
  "identityCheck.button.retry": "Reintentar",
  "identityCheck.do.title": "Qué hacer",
  "identityCheck.do.hint1": "Mira directamente a la cámara.",
  "identityCheck.do.hint2": "Utilice una exposición adecuada a la luz.",
  "identityCheck.dont.title": "No lo hagas",
  "identityCheck.dont.hint1":
    "Cubra su identificación con los dedos o la mano.",
  "identityCheck.dont.hint2": "Cúbrase la cara con una máscara.",
  "identityCheck.dont.hint3":
    "Use gafas de sol (las gafas graduadas están bien).",
  "identityCheck.dont.hint4":
    "Use sombreros o gorras (está bien usar sombreros religiosos que no le cubran la cara).",
  "identityCheck.full.steps.recordPrimaryId":
    "Graba un vídeo con {requiredDocuments, plural, one {gobierno} other {primary}} ID",
  "identityCheck.full.steps.recordSecondaryId":
    "Grabar vídeo con una identificación secundaria",
  "identityCheck.full.steps.secondaryIdGovIssued":
    "¿Su identificación secundaria es emitida por el gobierno?",
  "identityCheck.secondaryIdGovIssued.instruction":
    "La identificación secundaria puede ser un documento no gubernamental con su nombre y foto. Traiga esto el primer día.",
  "identityCheck.full.steps.recordChallengeActions":
    "Registrar las acciones de desafío",
  "identityCheck.full.primaryId.expandAltText":
    "Haga clic para subir {requiredDocuments, plural, one {gobierno} other {primary}} ID",
  "identityCheck.full.secondaryId.expandAltText":
    "Haga clic para cargar un identificador secundario",
  "identityCheck.full.challengeActions.expandAltText":
    "Haz clic para subir las acciones del desafío",
  "identityCheck.primaryId.instruction":
    "Graba un vídeo de 15 segundos con tu carné físico {requiredDocuments, plural, one {gubernamental} other {primario}} junto a tu rostro y luego frente a la cámara. Esta identificación debe ser una identificación emitida por el gobierno vigente de esta lista de documentos aceptados. Debe mostrar su nombre, fecha de nacimiento y fotografía.",
  "identityCheck.secondaryId.instruction":
    "Graba un video de 15 segundos con tu identificación física secundaria al lado de tu rostro y luego frente a la cámara. La identificación secundaria puede ser una identificación diferente emitida por el gobierno (por ejemplo, una licencia de conducir) o una identificación con foto no emitida por el gobierno (por ejemplo, una tarjeta de Costco o una identificación de estudiante).",
  "identityCheck.primaryIdCountry.select.label":
    "¿En qué país se emitió tu documento de identidad?",
  "identityCheck.primaryId.select.label": "Tipo de identificación",
  "identityCheck.primaryId.select.placeholder":
    "Seleccione el tipo de identificación",
  "identityCheck.primaryId.country.select.placeholder":
    "Seleccione el país emisor del documento de identidad",
  "identityCheck.primaryId.video.instruction":
    "Coloca tu carné de identidad {requiredDocuments, plural,  one {gubernamental} other {primario}} junto a tu cara y muévelo hacia la cámara.",
  "identityCheck.primaryId.video.instruction2":
    "Mantén tu identificación a 3 pulgadas de la cámara hasta que esté enfocada.",
  "identityCheck.primaryId.completed":
    "Se han registrado tu identificación principal y tu rostro.",
  "identityCheck.governmentId.completed":
    "Se han registrado su identificación gubernamental y su rostro.",
  "identityCheck.secondaryId.completed":
    "Se han registrado tu identificación secundaria y tu rostro.",
  "identityCheck.primary.title":
    "Graba un vídeo con tu {requiredDocuments, plural, one {gubernamental} other {primary}} ID",
  "identityCheck.secondaryID.governmentID.radio1":
    "Identificación emitida por el gobierno",
  "identityCheck.secondaryID.governmentID.radio2":
    "Identificación no emitida por un gobierno",
  "identityCheck.secondaryId.title": "Graba un vídeo con tu ID secundario",
  "identityCheck.secondaryId.video.instruction":
    "Coloca tu ID secundario junto a tu cara y muévela hacia la cámara.",
  "identityCheck.secondaryId.video.instruction2":
    "Mantén tu identificación a 3 pulgadas de la cámara hasta que esté enfocada.",
  "identityCheck.button.goBackAltText": "Regresar",
  "identityCheck.button.submitReview": "Enviar para revisión",
  "identityCheck.button.loading": "Enviando...",
  "identityCheck.button.tooltip.disallowResubmit":
    "La grabación de vídeo ya se ha enviado.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Selecciona un {requiredDocuments, plural, one {gubernamental} other {primario}} ID en el menú desplegable de selección.",
  "identityCheck.button.tooltip.missingSecondaryIdGovIssued":
    "Selecciona si tu tipo de identificación secundario es emitido por el gobierno usando los botones de radio.",
  "identityCheck.errors.unableToVerify":
    "No hemos podido verificar tu identidad. Por favor {link}. Se te pedirá que te unas a una videollamada y muestres tu ID a un agente de soporte de IT de Amazon, y se te proporcionará otra ruta para configurar tu portátil.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>No hemos podido verificar tu identidad mediante la verificación manual de identidad. </b>En su lugar, intenta utilizar la <a>verificación biométrica de identidad</a>.",
  "identityCheck.errors.failedToSubmit":
    "No hemos podido enviar tu vídeo. Vuelve a intentarlo en unos segundos o {link} para obtener más ayuda.",
  "identityCheck.errors.chatWithIT": "Chatea con el soporte de IT",
  "identityCheck.pending.pleaseWait":
    "Mientras se verifican tus vídeos, no salgas de esta página.",
  "identityCheck.pending.estimatedWaitTime":
    "El tiempo de espera típico para la verificación de identidad es de {minTimeMin} a {maxTimeMin} minutos.",
  "identityCheck.verified.successMsg": "Hemos verificado tu identidad.",
  "identityCheck.ivv.body.intro":
    "La configuración de tu nombre de usuario y contraseña de Amazon requiere un nivel de seguridad adicional para demostrar que eres tú y no alguien que se hace pasar por ti.",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Marque las dos casillas anteriores y haga clic en {agree} para confirmar que ha leído y entendido, o haga clic en Cancelar para verificar su identidad mediante un proceso manual.",
  "identityCheck.ivv.failedBody":
    "<b>No hemos podido verificar tu identidad mediante un escaneo facial automático. </b><a>Graba vídeos</a> con tus documentos de identidad junto a la cara y realiza una serie de gestos con las manos.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>No hemos podido verificar tu identidad mediante un escaneo facial automático. </b>{chatWithITSupportLink} para obtener más ayuda.",
  "identityCheck.ivv.inFlight":
    "Ya tienes una verificación durante el vuelo y no puedes iniciar una nueva. Actualice la página periódicamente para comprobar el estado.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Finalización de la verificación de identidad</b>",
  "identityCheck.ivv.pendingBackendAgreement":
    'Este proceso puede tardar unos minutos. Si llevas esperando más de 10 minutos, usa el botón "Obtener ayuda" para obtener ayuda.',
  "identityCheck.ivv.scanAbortHeader":
    "¿Tiene problemas con el escaneo automático?",
  "identityCheck.ivv.scanAbortBody":
    "No hay problema En su lugar, puedes usar nuestro <openModal>proceso de verificación alternativo</openModal>.",
  "identityCheck.ivv.scanAbortModalHeader":
    "¿Estás seguro de que quieres usar un proceso de verificación alternativo?",
  "identityCheck.ivv.scanAbortModalBody":
    " <p>Si ha decidido omitir el escaneo biométrico automático. Solo para que sepas, no podrás volver atrás y usar la opción de escaneo facial después de esto. </p><p>El proceso de verificación alternativo puede tardar un poco más, pero nuestro equipo te explicará todo lo que necesitas para configurar tu portátil el primer día. </p>",
  "identityCheck.ivv.scanAbortModalCancel": "Cancelar",
  "identityCheck.ivv.scanAbortModalConfirm": "Confirmar",
  "identityCheck.pathchooser.title":
    "Selecciona tu preferencia de verificación de uno de los siguientes métodos.",
  "identityCheck.pathchooser.card.recommended": "Recomendado",
  "identityCheck.pathchooser.card.estCompletionAutomated":
    "Tiempo para completar: <b>menos de {count} {unit}</b>",
  "identityCheck.pathchooser.card.estCompletionManual":
    "Tiempo para completar: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "minuto",
  "identityCheck.pathchooser.card.unitMinutes": "minutos",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Tipo de verificación: <b>Automatizado</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Tipo de verificación: <b>Manual</b>",
  "identityCheck.pathchooser.card.oneId":
    "Identificación requerida: <b>1 identificación emitida por el gobierno</b>",
  "identityCheck.pathchooser.card.twoIdsAutomated":
    "Identificaciones requeridas: <b>2 identificaciones emitidas por el gobierno</b>",
  "identityCheck.pathchooser.card.twoIdsManual":
    "Identificaciones requeridas: <b>1 identificación emitida por el gobierno, 1 identificación secundaria</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "Debe seleccionarse un tipo de verificación",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "La verificación ya está en marcha",
  "identityCheck.pathchooser.card.ivv.title": "Escaneo facial automatizado",
  "identityCheck.pathchooser.card.ivv.body":
    "Escanea tu rostro el primer día y lo compararemos con la foto de tu documento de identidad emitido por el gobierno. Su identidad se confirmará de inmediato y estará listo para comenzar.",
  "identityCheck.ivv.countDownTimer":
    ' restante hasta iniciar un proceso de verificación alternativo ("revisión verificada por humanos")',
  "identityCheck.pathchooser.card.manual.title": "Grabar vídeos",
  "identityCheck.pathchooser.card.manual.body":
    "Graba vídeos con tus documentos de identidad junto a tu rostro y realiza una serie de gestos con las manos. Envía estos vídeos a un verificador humano de Amazon para que los revise.",
  "identityCheck.pathchooser.card.contactsupport.title":
    "Contactar con el soporte",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Hable con un ingeniero de soporte de IT en tiempo real y verifique su identidad mediante una videoconferencia en Amazon Chime, quien lo guiará a través de un proceso de configuración manual.",
  "identityCheck.pathchooser.additionalConcerns":
    "Si tienes algún problema o tienes más dudas, ponte en contacto con nuestro equipo de soporte de IT.",
  "identityCheck.pathchooser.additionalConcerns.contactIt":
    "Pueden ayudarlo a través de una videollamada segura para verificar su identidad. {chatWithITSupportLink}.",
  "identityCheck.pathchooser.additionalConcerns.chimeVerificationType":
    "Tipo de verificación: <b>llamada de videoconferencia</b>",
  "identityCheck.pathchooser.submitBtn": "Enviar",
  "identityCheck.pathchooser.continueBtn": "Continuar",
  "amazonUsername.expander.title": "Nombre de usuario de Amazon",
  "amazonUsername.expander.body.info":
    "En Amazon, utilizarás tu nombre de usuario para iniciar sesión en tu ordenador y acceder a las herramientas y los recursos de Amazon. Es la parte de tu dirección de correo electrónico de Amazon que va antes de la @. Tu dirección de correo electrónico es {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Tu nombre de usuario de Amazon es: {username}",
  "registerSecurityKey.expander.title": "Registrar la clave de seguridad",
  "registerSecurityKey.expander.body.intro":
    "En Amazon, utilizamos tu clave de seguridad como paso de la autenticación multifactor para asegurarnos de que eres tú. Se te pedirá que presiones tu tecla de seguridad para acceder a la red de Amazon todos los días. Cada vez que pulse la tecla, se generará una cadena aleatoria de caracteres.",
  "registerSecurityKey.expander.body.securityKeyTitle":
    "¿Qué es una llave de seguridad?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    " dispositivo de plástico utilizado para acceder de forma segura a los recursos de Amazon. Cuándo te enviaron tu portátil Amazon, también te enviamos por correo dos llaves de seguridad USB. La segunda clave es la clave de respaldo; guárdela en un lugar seguro en caso de que la clave principal se pierda o se dañe.",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "En Amazon, utilizamos tu clave de seguridad como paso de la autenticación multifactor para asegurarnos de que eres tú. Para usar muchas de las herramientas y recursos internos de Amazon todos los días, debes introducir tu contraseña junto con tu clave de seguridad para acceder a la red de Amazon. Elige un PIN de clave de seguridad que sea fácil de recordar. Lo escribirás todos los días cuando inicies sesión en los servicios internos de Amazon y en la VPN. No caduca.",
  "registerSecurityKey.expander.body.midwayTitle": "¿Qué es Midway?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway es el servicio que administra las claves de seguridad y los PIN de las claves de seguridad.",
  "registerSecurityKey.expander.body.howTitle":
    "¿Cómo registro mi clave de seguridad?",
  "registerSecurityKey.expander.body.step1":
    "Envía un PIN temporal a tu correo electrónico personal para acceder a Midway. Abre tu correo electrónico personal para acceder a este PIN temporal.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "Hemos enviado un código de verificación a tu correo electrónico personal. Su PIN caducará en 4 horas. ¿No has recibido el código? {resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Una vez que tengas el PIN temporal, registra tu clave en Midway. Se te pedirá que introduzcas tu nombre de usuario de Amazon y tu PIN temporal.",
  "registerSecurityKey.expander.body.step3":
    "Conecta cualquiera de tus llaves de seguridad a un puerto USB de este portátil. Es posible que tengas que usar 1 de los adaptadores que has recibido. (Solo necesitas registrar una de tus claves de seguridad. La segunda clave es una copia de seguridad.) Inserte con cuidado esta llave con el orificio hacia afuera. Verás una luz verde cuando la clave de seguridad esté insertada correctamente.",
  "registerSecurityKey.expander.body.step4":
    "En la página de inicio de sesión, introduce lo siguiente:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Nombre de usuario de Amazon:</bold> el nombre de usuario mostrado en el paso anterior.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>PIN (o PIN de clave de seguridad):</bold> el PIN temporal enviado a tu dirección de correo electrónico personal.",
  "registerSecurityKey.expander.body.step5":
    "Después de introducir tu nombre de usuario y el PIN de la clave de seguridad temporal, haz clic <bold>en Iniciar sesión</bold>:",
  "registerSecurityKey.expander.body.step6":
    'Verás un mensaje que dice "¡Bienvenido {username}!"',
  "registerSecurityKey.expander.body.step7":
    "Haga clic en el enlace del <bold>portal de registro de claves de seguridad</bold> del mensaje de bienvenida para continuar.",
  "registerSecurityKey.expander.body.step8":
    'En la página que dice "Bienvenido al panel de claves de seguridad", haz clic en <bold>Registrar clave de seguridad</bold>.',
  "registerSecurityKey.expander.body.step9":
    'En la página que dice "Comenzar a usar la clave de seguridad", haz clic en <bold>Comenzar el registro</bold>. Si ve dos opciones aquí, elija Comenzar el registro de la clave de seguridad del hardware.',
  "registerSecurityKey.expander.body.step10":
    "Selecciona la imagen que coincida con la clave de seguridad que has recibido.",
  "registerSecurityKey.expander.body.step11":
    'A continuación, ingresarás la contraseña de un solo uso (OTP) generada por tu clave de seguridad. Haz clic en el cuadro de texto vacío que dice "Tu OTP aparecerá aquí, no escribas en este cuadro".',
  "registerSecurityKey.expander.body.step12.1":
    "Presiona la tecla de seguridad que está conectada al ordenador. Aplica una ligera presión durante 3 a 5 segundos hasta que aparezca una cadena de texto en el cuadro de texto vacío. Esta es la OTP. Según el tipo de llave de seguridad que tengas, tendrás que aplicar una ligera presión en los <bold>laterales</bold> o en la <bold>parte superior</bold>.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>Si presionas la llave de seguridad pero no se introduce ninguna OTP</bold>, retira el dedo de la llave de seguridad, haz clic en el cuadro de texto y vuelve a tocar la tecla de seguridad.",
  "registerSecurityKey.expander.body.step13":
    "Escribe el PIN de la clave de seguridad permanente que quieres usar en los campos <bold>Nuevo PIN</bold> y <bold>Confirmar nuevo PIN</bold> y, a continuación, haz clic en <bold>Continuar</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Escribe el PIN de la clave de seguridad permanente que quieres usar en los campos <bold>Nuevo PIN</bold> y <bold>Confirmar nuevo PIN</bold> y, a continuación, haz clic en <bold>Continuar</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Importante</bold>: Elige un PIN de clave de seguridad que sea fácil de recordar. Lo escribirás todos los días cuando inicies sesión en los servicios internos de Amazon y en la VPN. No caduca.",
  "registerSecurityKey.expander.body.step14":
    'En la página que dice "Lea todas las instrucciones a continuación..." presiona <bold>Continuar</bold>.',
  "registerSecurityKey.expander.body.step15":
    "En la página siguiente, vuelve a mantener presionada la clave de seguridad. Es posible que aparezca una ventana emergente en la que se solicita información sobre la clave de seguridad. Haz clic en <bold>Permitir</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "A continuación, verás 1 de las 2 pantallas:",
  "registerSecurityKey.expander.body.screenPossibilityList.1":
    'Si ves "<bold>Has registrado correctamente tu clave de seguridad</bold>", has terminado con este paso. Mantén segura tu segunda llave de seguridad. Asegúrate de registrarlo más tarde como respaldo si pierdes el que acabas de registrar.',
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "Si se te pide que introduzcas un PIN, todavía no has terminado. Continúe con los pasos siguientes:",
  "registerSecurityKey.expander.body.setUpNewPin.1":
    "Escribe el PIN <bold>de la clave de seguridad</bold> en los <bold>campos <bold>PIN</bold> y Confirmar PIN</bold> y, a continuación, haz clic en <bold>Siguiente</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "En el siguiente mensaje, vuelve a mantener presionada la clave de seguridad. Es posible que aparezca una ventana emergente en la que se solicita información sobre la clave de seguridad. Haz clic en <bold>Permitir</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3":
    'Si el registro se realiza correctamente, verás el mensaje "<bold>Has registrado correctamente tu clave de seguridad</bold>." Si no ves este mensaje, vuelve a intentar el proceso de registro en otro navegador.',
  "registerSecurityKey.expander.body.setUpNewPin.3.1":
    'Si el registro se realiza correctamente, verás el <bold>mensaje "Has registrado correctamente tu clave de seguridad.</bold>"',
  "registerSecurityKey.expander.body.setUpNewPin.3.2":
    'Si no ves este mensaje, tendrás que volver a intentar el paso de registro. Cierre la ventana de Midway a la derecha y vuelva a intentar este paso haciendo clic en el botón "Registrar la clave de seguridad en Midway" para volver a intentarlo.',
  "registerSecurityKey.expander.body.secondKeySafe":
    "Mantén segura tu segunda llave de seguridad. Puedes registrarlo más adelante como respaldo si pierdes el que acabas de registrar.",
  "registerSecurityKey.expander.body.note":
    '<bold>Nota</bold>: Si encuentras un error relacionado con "Conformidad del dispositivo" o "AEA", tendrás que reiniciar Easy Onboarding cerrando sesión en "tokenadmin" y volviendo a iniciarla para que se completen las actualizaciones del dispositivo. Siga las instrucciones que se le proporcionan en su dirección de correo electrónico personal para reiniciar este proceso. Si esto no resuelve el error, utiliza el enlace "Contactar con el servicio de asistencia" para obtener ayuda.',
  "registerSecurityKey.checkbox.confirmation":
    "Confirmo que he registrado mi clave de seguridad.",
  "registerSecurityKey.button.sendTempPin": "Enviar PIN temporal",
  "registerSecurityKey.button.sendTempPin.loadingText": "Enviando...",
  "registerSecurityKey.button.sendTempPin.error":
    "Se ha producido un error al enviar el PIN. Vuelva a intentarlo en unos minutos.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "No hemos podido enviarte un PIN temporal. Ponte en contacto con el servicio de asistencia.",
  "registerSecurityKey.button.registerSecurityKey":
    "Registrar la clave de seguridad en Midway",
  "registerSecurityKey.link.resend": "Reenviar.",
  "registerSecurityKey.image.securityKeyGrid.altText":
    'Una cuadrícula de seis imágenes que muestran los tipos de claves de seguridad. Los tipos son "YubiKey 4", "YubiKey 4 Nano", "USB-C YubiKey", "USB-C Nano", "Zukey" y "USB-C Zukey".',
  "registerSecurityKey.image.tempPinLogin.altText":
    'Captura de pantalla de la página de inicio de sesión de Amazon Midway. La página dice "Iniciar sesión", incluye campos para introducir un nombre de usuario y un PIN de Amazon, y tiene un botón que dice "Iniciar sesión".',
  "registerSecurityKey.image.midwayWelcomeMessage.altText":
    "Captura de pantalla de una página de ejemplo con un mensaje de bienvenida. La página de bienvenida de ejemplo dice ¡Bienvenido jeffbezos!. Para registrar o administrar tus claves de seguridad, visita el portal de registro de claves de seguridad.",
  "registerSecurityKey.image.securityKeyDashboard.altText":
    'Captura de pantalla de la página de bienvenida del panel de claves de seguridad de Amazon Enterprise Access. La página dice "Bienvenido al panel de claves de seguridad. Amazon Enterprise Access y tiene un botón que dice "Registrar clave de seguridad".',
  "registerSecurityKey.image.securityKeyGetStarted.altText":
    'Captura de pantalla de la página Comenzar con la clave de seguridad. La página dice "Empieza a usar tu clave de seguridad. Recoja una llave de seguridad en la oficina de soporte de IT o en la máquina expendedora de IT más cercana. Después de recoger una clave de seguridad, haz clic en Comenzar registro. Importante: Debe llevar consigo su nueva clave de seguridad para registrarla. Debes introducir tu nueva clave de seguridad en este momento. "y tiene un botón que dice "Comenzar el registro".',
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText":
    'Captura de pantalla de la página Comenzar con la clave de seguridad. La página dice "Empieza a usar tu clave de seguridad. ¿Qué tipo de llave de seguridad has recogido? Selecciona la clave de seguridad que coincida con la que tienes. "y tiene una cuadrícula de seis tipos de claves de seguridad. Los tipos de claves de seguridad listados son "YubiKey 4", "YubiKey 4 Nano", "USB-C YubiKey", "USB-C Nano", "Zukey" y "USB-C Zukey".',
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText":
    'Captura de pantalla de la página Comenzar con la clave de seguridad. La página dice "Empieza a usar tu clave de seguridad. En primer lugar, introduce la clave de seguridad en un puerto USB del ordenador. En segundo lugar, mantén presionada la tecla de seguridad durante 3 a 5 segundos hasta que aparezca el texto en el cuadro de abajo. y tiene un campo de entrada de texto con texto de relleno que dice Tu OTP aparecerá aquí, no escribas en este cuadro.',
  "registerSecurityKey.image.pressingSecurityKeySide.altText":
    "Una imagen animada (gif) de una persona pulsando el lateral de una llave de seguridad que está conectada a una computadora portátil",
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "Un gif de imagen animada de una persona presionando la parte superior de una tecla de seguridad que está conectada a una computadora portátil",
  "registerSecurityKey.image.securityKeyCreatePin.altText":
    'Captura de pantalla de la página de creación del PIN de la clave de seguridad. La página dice: "Debe configurar una nueva clave de seguridad PIN para registrar esta clave de seguridad. NO olvides el PIN de tu clave de seguridad. Su PIN se utilizará para permitirle iniciar sesión en los recursos de Amazon y conectarse a la VPN con su clave de seguridad". La página también incluye un campo de entrada de texto con la etiqueta "Nuevo PIN (8-64 caracteres ASCII)", otro campo de entrada de texto con la etiqueta "Confirmar nuevo PIN" y un botón que dice "Continuar".',
  "registerSecurityKey.image.securityKeySuccess.altText":
    'Captura de pantalla de un mensaje de éxito. El mensaje dice "Has registrado correctamente tu clave de seguridad. Esta clave de seguridad puede tardar hasta 1 hora en funcionar en todas las herramientas y recursos de Amazon.',
  "registerSecurityKey.image.securityKeyCreateNewPin.altText":
    'Captura de pantalla de un formulario emergente para configurar un PIN nuevo. La ventana emergente dice "Se requiere PIN. Configura un nuevo PIN para tu clave de seguridad. La ventana emergente también tiene un campo de entrada de texto denominado "PIN" y otro campo de entrada de texto denominado "Confirmar PIN".',
  "connectVpn.expander.title": "Conéctese a la red de Amazon (VPN)",
  "connectVpn.expander.body.intro":
    "Deberás conectarte a la VPN para acceder a los recursos internos mientras trabajas in situ o de forma remota. Las sesiones de VPN se agotan cada 18 horas y luego requieren que te vuelvas a conectar.",
  "connectVpn.expander.body.mac.step1":
    "Abra el cliente <bold>Cisco AnyConnectSecure Mobility.</bold>",
  "connectVpn.expander.body.mac.step2":
    "Aparecerá el cliente Cisco AnyConnect. <bold>Haga clic en Conectar</bold>.",
  "connectVpn.expander.body.mac.step3":
    "Aparecerá una ventana emergente. En el campo <bold>Grupo</bold>, selecciona <bold>ORCA-Amazononboarding</bold> en la lista desplegable.",
  "connectVpn.expander.body.mac.step4":
    "Introduce tu nombre de usuario de Amazon en el campo <bold>Nombre de usuario</bold>.",
  "connectVpn.expander.body.mac.step5":
    "En el campo PIN de la clave de seguridad + Pulse la tecla de seguridad, escribe el <bold>PIN de la clave de seguridad</bold> y, a continuación, mantén presionada la clave de seguridad hasta que aparezca una cadena larga de caracteres en el campo. No hagas clic en Aceptar: si mantienes presionada la clave de seguridad, la conexión VPN comenzará a autenticarse automáticamente.",
  "connectVpn.expander.body.mac.step5.warning":
    "El <bold>campo PIN de la tecla de seguridad + Presione la tecla de seguridad</bold> podría decir <bold>Contraseña</bold> en su lugar. No introduzcas aquí tu contraseña de Amazon. Introduce el PIN de la clave de seguridad y, a continuación, mantén pulsada la clave de seguridad.",
  "connectVpn.expander.body.mac.step6":
    "Aparecerá una ventana emergente que confirmará que estás conectado a la red de Amazon a través de una VPN. Selecciona, <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Captura de pantalla de la aplicación Cisco AnyConnect Secure Mobility Client.",
  "connectVpn.image.mac.step3.altText":
    "Captura de pantalla de una ventana emergente con campos de grupo, nombre de usuario y PIN de clave de seguridad para conectarse a la VPN con la aplicación Cisco AnyConnect Secure Mobility Client con la incorporación de ORCA-Amazon seleccionada en la lista desplegable del campo Grupo.",
  "connectVpn.image.mac.step4.altText":
    "Captura de pantalla de una ventana emergente con campos de grupo, nombre de usuario y clave PIN de seguridad para conectarse a la VPN con la aplicación Cisco AnyConnect Secure Mobility Client, con un cuadro rojo que resalta el campo Nombre de usuario.",
  "connectVpn.image.mac.step5.altText":
    "Captura de pantalla de una ventana emergente con campos de grupo, nombre de usuario y PIN de clave de seguridad para conectarse a la VPN con la aplicación Cisco AnyConnect Secure Mobility Client, con un cuadro rojo que resalta el PIN de la clave de seguridad + Pulse el campo de la clave de seguridad y el botón OK tachado con una X roja.",
  "connectVpn.image.mac.step6.altText":
    "Captura de pantalla de una conexión exitosa a la VPN.",
  "connectVpn.expander.body.windows.step1":
    'Presione la <bold>tecla Windows</bold> en esta computadora portátil que le permitirá acceder al "Menú de inicio".',
  "connectVpn.expander.body.windows.step2":
    "En el menú Inicio, seleccione <bold>Cisco AnyConnect</bold> (es el software de Amazon para acceder a la VPN).",
  "connectVpn.expander.body.windows.step3":
    "Aparecerá el cliente Cisco AnyConnect. El software Cisco AnyConnect es lo que se utiliza para la VPN de Amazon. Haz clic en <bold>Conectar</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Haga clic para abrir el menú desplegable en el campo Grupo. Selecciona <bold>Orca-Amazon Onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Introduce tu nombre de usuario de Amazon en el campo <bold>Nombre de usuario</bold>.",
  "connectVpn.expander.body.windows.step6":
    "En el campo <bold>Contraseña</bold>, escribe el PIN de la clave de seguridad y, a continuación, mantén presionada la clave de seguridad hasta que aparezca una cadena larga de caracteres en el campo. No hagas clic en <bold>Aceptar</bold>: si mantienes presionada la clave de seguridad, la conexión VPN comenzará a autenticarse automáticamente.",
  "connectVpn.expander.body.windows.step7":
    "Cuando la VPN esté conectada, aparecerá una notificación de seguridad confirmando la conexión. Haga clic en <bold>Aceptar</bold> para cerrar el cuadro de diálogo.",
  "connectVpn.image.windows.step1.altText": "Presiona la tecla Windows.",
  "connectVpn.image.windows.step2.altText":
    "Seleccione Cisco AnyConnect en el menú de inicio.",
  "connectVpn.image.windows.step4.altText":
    "Selecciona Orca-Amazononboarding en la lista desplegable del campo Grupo.",
  "connectVpn.image.windows.step6.altText":
    "Escribe el PIN de la clave de seguridad y, a continuación, mantén presionada tu clave de seguridad.",
  "connectVpn.button.tooltip.vpnNotConnectedHint": "Conéctese a la VPN.",
  "amazonPassword.expander.title": "Crear contraseña de Amazon",
  "amazonPassword.expander.body.intro1":
    "A continuación, crearás la contraseña que utilizarás para iniciar sesión en tu portátil todos los días y acceder a las herramientas de Amazon.",
  "amazonPassword.expander.body.intro2":
    "Escribirás el PIN de la clave de seguridad y, a continuación, presionarás y mantendrás el dedo contra la llave de seguridad USB para establecer la contraseña por primera vez. <bold>Recuerde esta contraseña</bold>, ya que la necesitará para continuar con la configuración de su portátil.",
  "amazonPassword.expander.body.banner":
    'Al crear la contraseña, se te pedirá que presiones el dedo contra la llave de seguridad USB. Esto presionará "enter" por ti. No es necesario hacer clic en "entrar" o "enviar". Si recibe un error, ignórelo y continúe con la configuración de su portátil.',
  "amazonPassword.expander.body.confirmation":
    "Confirmo que he creado correctamente mi contraseña.",
  "amazonPassword.button.createPassword": "Crear contraseña de Amazon",
  "preCacheADMobile.expander.title":
    "Instalación de AD Mobile con almacenamiento previo a la caché",
  "preCacheADMobile.expander.body.intro":
    "En Amazon, utilizamos una herramienta llamada Precache AD para sincronizar la información de tu cuenta ubicada en la red de Amazon con el portátil que estás utilizando. Esto garantiza que puedas iniciar sesión en tu portátil Amazon con tu contraseña y nombre de usuario de Amazon todos los días.",
  "preCacheADMobile.expander.body.step1":
    "Abre la aplicación <bold>Self Service</bold> {logo} en tu escritorio.",
  "preCacheADMobile.expander.body.step2":
    "Introduce tu nombre de usuario de Amazon.",
  "preCacheADMobile.expander.body.step3":
    "Ingresa el PIN de tu clave de seguridad y presiona tu clave de seguridad.",
  "preCacheADMobile.expander.body.step4":
    'En el cuadro de búsqueda de la esquina superior izquierda, escribe "<bold>Pre-Cache</bold>".',
  "preCacheADMobile.expander.body.step5":
    "Cuando se carguen los resultados de la búsqueda, haz clic en <bold>Caché</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Introduce tu nombre de usuario de Amazon. Haga clic en <bold>Aceptar</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Introduce tu contraseña de Amazon. Haga clic en <bold>Aceptar</bold>.",
  "preCacheADMobile.expander.body.step8":
    'El script se ejecutará de forma silenciosa durante 2 a 3 minutos. Cuando esté completo, un "¡Éxito! Aparece la ventana emergente". Haga clic en <bold>Aceptar</bold>.',
  "preCacheADMobile.image.step3.altText":
    "Captura de pantalla de la vista para iniciar sesión con tu nombre de usuario de Amazon y el PIN de la clave de seguridad.",
  "preCacheADMobile.image.step4.altText":
    "Captura de pantalla de la vista para buscar Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Captura de pantalla de la vista para introducir tu nombre de usuario de Amazon.",
  "preCacheADMobile.image.step7.altText":
    "Captura de pantalla de la vista para introducir tu contraseña de Amazon.",
  "preCacheADMobile.image.step8.altText":
    "Captura de pantalla de la vista de la aplicación Pre-Cache AD Mobile instalada correctamente.",
  "preCacheADMobile.logo.altText":
    "Logotipo de la aplicación AD previa a la caché que se puede instalar mediante autoservicio",
  "banner.openViaWelcome":
    'Abra esta página haciendo clic en "Comenzar" en la página de bienvenida.',
  "allStepsCompleted.title": "👏 ¡Buen trabajo!",
  "allStepsCompleted.mac.nextTo": "A continuación, tendrá que:",
  "allStepsCompleted.win.nextTo":
    "A continuación, tendrá que iniciar sesión por primera vez:",
  "allStepsCompleted.win.step1": "Paso 1: Conéctese a la VPN",
  "allStepsCompleted.win.instructionSent":
    "Estas instrucciones también se le han enviado por correo electrónico",
  "allStepsCompleted.mac.postLoginStep1": "Cierre sesión en su portátil.",
  "allStepsCompleted.mac.postLoginStep2":
    "Consulta tu correo electrónico personal para ver las instrucciones para iniciar sesión en tu cuenta de Amazon.",
  "allStepsCompleted.win.step1.subStep1":
    'Reinicie este portátil pulsando la tecla Windows y seleccionando "<bold>Reiniciar</bold>" en el menú "<bold>Inicio</bold>".',
  "allStepsCompleted.win.step1.subStep2":
    "Haga clic en el icono de inicio de sesión de red para conectarse a la VPN de Amazon. Este icono se parece a 2 monitores de ordenador.",
  "allStepsCompleted.win.step1.subStep2.note":
    "<bold>Nota:</bold> La conexión a la VPN desde la pantalla de inicio de sesión solo es necesaria para configurar la cuenta por primera vez. No necesitará usar una VPN para iniciar sesión después de la primera vez.",
  "allStepsCompleted.windows.vpnIcon.altText":
    "Un icono con dos monitores de ordenador resaltados en amarillo.",
  "allStepsCompleted.win.step1.subStep3":
    "Aparecerá el cliente Cisco AnyConnect. Haz clic en <bold>Conectar</bold>.",
  "allStepsCompleted.win.step1.subStep4": `Haga clic para abrir el menú desplegable en el campo <bold>Grupo</bold>. Seleccione "Inicio de <bold>sesión previo</bold>"`,
  "allStepsCompleted.win.step1.preLogin.altText":
    "Una imagen del cliente VPN de Cisco con el grupo previo al inicio de sesión seleccionado.",
  "allStepsCompleted.win.step1.subStep5":
    "Introduce tu nombre de usuario de Amazon en el campo <bold>Nombre</bold> de usuario (tu nombre de usuario es {username}). ",
  "allStepsCompleted.win.step1.subStep6":
    "En el campo <bold>Contraseña</bold>, escribe el PIN de la clave de seguridad y, a continuación, mantén presionada la clave de seguridad hasta que aparezca una cadena larga de caracteres en el campo. No hagas clic en <bold>Aceptar</bold> cuando mantengas presionada la clave de seguridad, la conexión VPN comenzará a autenticarse automáticamente.",
  "allStepsCompleted.win.step1.securityKey.altText":
    "Una imagen de un dedo tocando la llave de seguridad que está conectada a un ordenador.",
  "allStepsCompleted.win.step1.subStep6.note":
    "<bold>Importante:</bold> Cuando te conectes a una VPN, no utilices tu contraseña de Amazon, aunque diga Contraseña. Usa el PIN de la llave de seguridad y, a continuación, aplica una ligera presión durante 3 a 5 segundos sobre la llave de seguridad que está conectada al ordenador.",
  "allStepsCompleted.win.step1.subStep7":
    "Cuando la VPN esté conectada, aparecerá una notificación de seguridad. Haga clic en <bold>Aceptar</bold> para cerrar el cuadro de diálogo. Volverás a la pantalla de inicio de sesión de Windows.",
  "allStepsCompleted.win.step2": "Paso 2: Inicia sesión en tu cuenta de Amazon",
  "allStepsCompleted.win.step2.intro":
    "Inicia sesión en tu cuenta de Amazon con tu nombre de usuario y la contraseña que creaste. ",
  "allStepsCompleted.win.step2.subStep1": `En el campo Nombre de usuario, escriba <bold>ANT\\{username}</bold>. Asegúrese de incluir "ANT\\" antes de su nombre de usuario.`,
  "allStepsCompleted.win.step2.subStep2": `En el campo Contraseña, escribe tu <bold>contraseña de Amazon</bold> y pulsa Entrar.`,
  "allStepsCompleted.win.step2.subStep2.note1":
    "<bold>Importante:</bold> Una vez que hayas iniciado sesión en tu cuenta de Amazon, tendrás que <bold>desconectarte de la VPN</bold> para acceder a los recursos de Amazon. Si no se desconecta, no podrá acceder a ningún recurso. Puede localizar el Cisco AnyConnect en el Menú de inicio para desconectarse de la VPN.",
  "allStepsCompleted.win.step2.subStep2.note2":
    "Si necesita una VPN para otros servicios específicos para su puesto de trabajo, necesitará usar <bold>Orca-Corp-VPN. </bold>",
  "allStepsCompleted.win.step3": "Paso 3: Guía de configuración de Open Laptop",
  "allStepsCompleted.win.step3.intro":
    "Una vez que hayas iniciado sesión, visita {newHirePortalLink} para terminar de configurar tu portátil con las herramientas más comunes que se utilizan en Amazon, como Chime, Slack y Outlook. ",

  failedToRender:
    "Algo salió mal. Visite {link} y póngase en contacto con el soporte de IT para obtener más ayuda.",
  "error.serviceNotAvailable.text1":
    "La aplicación Amazon Day 1 no está disponible actualmente.",
  "error.serviceNotAvailable.step1.title": "Cambiar la conexión de red",
  "error.serviceNotAvailable.step1.windows": "Usuarios de Windows",
  "error.serviceNotAvailable.step1.windows.subStep1":
    "Presiona la tecla <bold>Windows</bold> y selecciona el ícono <bold>Cuentas</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep2":
    "Selecciona <bold>«Cerrar sesión»</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep3":
    "En la pantalla de inicio de sesión de <bold>Windows</bold>, localiza el icono de Wi-Fi en la esquina inferior derecha.",
  "error.serviceNotAvailable.step1.windows.subStep4a":
    "Conectarse a una conexión de red diferente.",
  "error.serviceNotAvailable.step1.windows.subStep4b":
    "Si te encuentras en un edificio corporativo de Amazon y estás conectado a la red Wi-Fi A2Z, prueba a conectarte a la red Wi-Fi para invitados si está disponible para ti.",
  "error.serviceNotAvailable.step1.windows.subStep4c":
    "<bold>Importante</bold>: Debes conectarte a una red Wi-Fi antes de iniciar sesión con tu cuenta.",
  "error.serviceNotAvailable.step1.windows.subStep5":
    "Inicie sesión como usuario de <bold>tokenadmin</bold> con las credenciales proporcionadas en su correo electrónico.",
  "error.serviceNotAvailable.step1.mac": "Usuarios de macOS",
  "error.serviceNotAvailable.step1.mac.subStep1":
    "Selecciona una red Wi-Fi diferente en la selección Wi-Fi de la esquina superior derecha de la pantalla.",
  "error.serviceNotAvailable.step2.title": "Comuníquese con el soporte de TI",
  "error.serviceNotAvailable.text2":
    'Si eres un empleado nuevo, consulta la Guía de configuración de IT en firstaid.amazon-corp.com/vnho desde tu dispositivo Amazon o desde tu dispositivo personal conectado a Internet para completar la configuración del portátil. Necesitará que su gerente de contratación o el soporte de IT lo ayuden con el proceso de configuración. Si su gerente de contratación no está disponible, utilice el enlace "Obtenga ayuda" que se encuentra en la página de instrucciones de primeros auxilios.',
  "error.somethingWentWrong":
    "Algo salió mal. Vuelve a intentarlo o usa {chatWithITSupportLink} para obtener más ayuda.",
  "error.accountIssues":
    "Hay un problema con tu cuenta. Por favor {chatWithITSupportLink}.",
  "error.ineligibleError.text1":
    "Hay un problema con tu cuenta. Lea lo siguiente antes de ponerse en contacto con el servicio de asistencia.",
  "error.ineligibleError.text2":
    "Si eres un empleado nuevo, consulta la Guía de configuración de IT en firstaid.amazon-corp.com/vnho desde tu dispositivo personal conectado a Internet para completar la configuración del portátil.",
  "error.ineligibleError.text3":
    "Si eres un usuario de Amazon que está configurando tu portátil de reemplazo, sigue las instrucciones que se enviaron con el portátil.",
  "error.ineligibleError.text4":
    "Si sigues necesitando ayuda, visita firstaid.amazon-corp.com para obtener asistencia.",
  "error.notVerifiableError.text1":
    "Lo sentimos, la verificación de identidad no está disponible actualmente.",
  "error.notVerifiableError.text2":
    "Para verificar tu identidad, {chatWithITSupportLink}. Uno de nuestros agentes estará encantado de ayudarle durante el proceso de verificación. Lamentamos las molestias ocasionadas y agradecemos su comprensión.",
  "error.beforeStartDate":
    "La fecha de inicio y el primer día son {startDate}. Cierre su portátil hasta ese momento. ¿Necesitas ayuda? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "Tengo problemas para iniciar el proceso de contratación de nuevos empleados de Easy Onboarding y necesito ayuda para configurar mi portátil el primer día.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "Necesito ayuda para verificar mi identidad para la incorporación de un nuevo empleado a través de una videollamada de Chime.",
  "itHelpMsg.verificationIssueAndAssist":
    "Tengo problemas para verificar mi identidad a través del proceso de contratación de nuevos empleados de Easy Onboarding y necesito ayuda para configurar mi portátil el primer día.",
  "itHelpMsg.verificationIssue":
    "Tengo problemas para configurar mi portátil como parte del proceso de incorporación de nuevos empleados y necesito ayuda para verificar mi identidad.",
  "itHelpMsg.verificationAssist":
    "Necesito ayuda para verificar mi identidad como parte del proceso de contratación nueva de Easy Onboarding y necesito ayuda para incorporarme.",
  "itHelpMsg.settingUpLaptop":
    "Tengo problemas para configurar mi portátil como parte del proceso de incorporación de nuevos empleados.",
  "itHelpMsg.usernameIssue":
    "Tengo problemas con mi nombre de usuario como parte del proceso de incorporación de nuevos empleados.",
  "itHelpMsg.midwayAssist":
    "Necesito ayuda con Midway y el proceso de registro de la clave de seguridad como parte de la incorporación de mi nuevo empleado.",
  "itHelpMsg.midwayIssue":
    "Tengo problemas con el proceso de registro de claves de Midway como parte de la incorporación de mi nuevo empleado. Ya he solicitado el envío de un PIN temporal.",
  "itHelpMsg.vpnIssue":
    "Tengo un problema relacionado con la conexión VPN como parte de la incorporación de mi nuevo empleado.",
  "itHelpMsg.passwordIssue":
    "Tengo un problema con la herramienta de restablecimiento de contraseña como parte de la incorporación de mi nuevo empleado.",
  "itHelpMsg.preCacheADIssue":
    "Tengo un problema con el paso AD previo a la caché durante la incorporación de mi nuevo empleado para mi dispositivo Mac.",
  "mediacheck.failure.permission":
    "Para permitir el acceso, haz clic en el icono de la cámara {icon} en la barra de direcciones de tu navegador. Es posible que tengas que volver a cargar la página para que se apliquen los cambios.",
  "mediacheck.failure.nodevice":
    "No se encontró ningún dispositivo de cámara. Asegúrese de que la cámara esté conectada y funcionando y, a continuación, vuelva a cargar la página.",
  "mediacheck.failure.other":
    "Un problema ha impedido que la página detecte si tienes instalada una cámara utilizable.",
  "mediacheck.failure.title": "La cámara está bloqueada.",
  "mediacheck.failure.cameraalt": "Icono de cámara",
  "cameraOn.text": "Empieza a grabar y, a continuación, {text}",
  "getSupport.header": "Obtenga soporte",
  "getSupport.footer": "¿Aún necesitas ayuda?",
  "getSupport.chatSupport.clickToStart": "Iniciar chat",
  "getSupport.chatSupport.clickToStartNew": "Iniciar un nuevo chat",
  "getSupport.faq.password.header":
    "Preguntas frecuentes sobre contraseñas de Amazon",
  "getSupport.faq.password.section1.title": "¿Cuándo caduca mi contraseña?",
  "getSupport.faq.password.section1.contents": "",
  "getSupport.faq.vpn.header": "PREGUNTAS FRECUENTES SOBRE VPN",
  "getSupport.faq.vpn.section1.title": "Error de configuración de Cisco CSD",
  "getSupport.faq.vpn.section1.contents": "Marcador de posición",
  "getSupport.faq.general.header": "Preguntas frecuentes generales",
  "getSupport.faq.general.section1.title":
    "¿Qué hago si no he recibido un código de verificación?",
  "getSupport.faq.general.section1.contents": "código de verificación",
  "getSupport.faq.midway.header": "Preguntas frecuentes sobre Midway",
  "getSupport.faq.midway.section1.title": "¿Qué es una llave de seguridad?",
  "getSupport.faq.midway.section1.contents":
    "Una clave de seguridad es una memoria USB física que se insertará en el ordenador. Te hemos enviado un paquete con dos llaves de seguridad USB. Guarda la segunda clave de respaldo en un lugar seguro, en caso de que la clave principal se pierda o se dañe.",
  "getSupport.faq.username.header":
    "Preguntas frecuentes sobre los usuarios de Amazon",
  "getSupport.faq.username.section1.title":
    "¿Qué es un nombre de usuario de Amazon?",
  "getSupport.faq.username.section1.contents":
    "En Amazon, utilizarás tu nombre de usuario para iniciar sesión en tu ordenador y acceder a las herramientas y los recursos de Amazon. Tu nombre de usuario es la parte de tu dirección de correo electrónico de Amazon que va antes de la @.",
  "getSupport.idv.title":
    "Hable con el soporte de IT para verificar su identidad",
  "getSupport.idv.body.content1":
    "Si tiene problemas con el proceso anterior o tiene otras inquietudes, póngase en contacto con el equipo de soporte de IT.",
  "getSupport.idv.body.content2":
    "El equipo de soporte de IT puede ayudarte a verificar tu identidad mediante una videollamada.",
  "arialabels.clickToCloseChat":
    "Haga clic para cerrar el panel de soporte por chat",
  "arialabels.clickToStartChat": "Haga clic para iniciar el soporte por chat",
  "arialabels.clickToOpenChat":
    "Haga clic para abrir el panel de soporte por chat",
  "arialabels.clickToCloseGetSupport":
    "Haga clic para cerrar el panel de soporte",
  "arialabels.clickToOpenGetSupport":
    "Haga clic para abrir el panel de soporte",
};

export default spanish;
