import {
  type ConsentData,
  type ConsentEntry,
  type ConsentVersion,
  type LocaleCode,
  CountryConfig,
  ReferenceConfig,
} from "./consent";
import { EU_CONSENT } from "./eu";
import { GLOBAL_1_CONSENT } from "./global-1";
import { GLOBAL_2_CONSENT } from "./global-2";
import { KOR_CONSENT } from "./kor";
import { USA_CONSENT } from "./usa";

/**
 * Returns the IVV consent language based on the hiring country, the number of required
 * documents and the locale.
 *
 * If the country passed in does not exist in the consent data, the global language is
 * used. The required number of documents is used to select the correct version of the
 * consent language.
 *
 * @param hiringCountry The country the candidate is onboarding from
 * @param requiredDocuments The number of required documents for the hiring country
 * @param locale The locale to use for the consent content
 * @returns The IVV consent language to be shown to the user
 */
export const getConsent = (
  hiringCountry: string,
  requiredDocuments: number,
  locale?: string
): ConsentVersion => {
  if (hiringCountry in consentData) {
    return getConsentForLocale(hiringCountry as ConsentEntry, locale);
  }
  if (requiredDocuments == 1) {
    return getConsentForLocale("GLOBAL-1", locale);
  }
  return getConsentForLocale("GLOBAL-2", locale);
};

/**
 * This is a helper that will recursively follow region references
 * can return the appropriate consent language
 * @param entry The original ConsentEntry who's reference pointer will be followed to content
 * @param locale The locale of the language
 * @returns The IVV consent language for the given ConsentEntry
 */
const getConsentForLocale = (
  entry: ConsentEntry,
  locale?: string
): ConsentVersion => {
  if ("content" in consentData[entry]) {
    const consent = consentData[entry] as CountryConfig;
    if (!locale) {
      // If no locale is provided, return the default
      return consent.content.default;
    }
    const localeCode = locale as LocaleCode;
    if (consent.content[localeCode]) {
      return consent.content[localeCode] ?? consent.content.default;
    }
    // If there is no specific language for that locale return the default
    return consent.content.default;
  }
  // Recurse to resolve region reference
  const consentRef = consentData[entry] as ReferenceConfig;
  return getConsentForLocale(consentRef.region, locale);
};

/**
 * This is the IVV consent language data that should be shown to the user before
 * they proceed with IVV.
 */
export const consentData: ConsentData = {
  "GLOBAL-1": GLOBAL_1_CONSENT,
  "GLOBAL-2": GLOBAL_2_CONSENT,
  KOR: KOR_CONSENT,
  USA: USA_CONSENT,
  EU: EU_CONSENT,
};
