import { Step } from "@/components/constants";
import { LOCALES } from "@/i18n/locales";
import {
  Action,
  ActionType,
  AppState,
  INITIAL_STATE,
} from "@/stores/constants";

const STEP_ID_REQUIRED_ACTIONS = [
  ActionType.SET_NEXT_BTN_STATUS,
  ActionType.NEXT_STEP,
  ActionType.SET_HIDE_NEXT,
];

/**
 * Reducer which accepts the actions and update the global state accordingly.
 * @param state The global App state.
 * @param action The triggered action.
 */
export const AppStoreReducer = (state: AppState, action: Action): AppState => {
  const newState: AppState = { ...state };
  const actionType: ActionType = action.type;
  const newSteps: Step[] = [...state.steps];
  const targetStepIdx = newSteps.findIndex((step) => step.id === action.stepId);

  if (STEP_ID_REQUIRED_ACTIONS.includes(action.type) && targetStepIdx < 0) {
    console.error("Cannot find the task: ", action);
    return state;
  }

  const targetStep = newSteps[targetStepIdx];

  switch (actionType) {
    /**
     * Reset to the initial state.
     */
    case ActionType.RESET:
      return INITIAL_STATE;

    /**
     * Set language selection on page based on user selection in UI
     */
    case ActionType.SET_SELECTED_LANGUAGE: {
      newState.selectedLanguage = action.selectedLanguage ?? LOCALES.ENGLISH;
      localStorage.setItem("locale", action.selectedLanguage!);
      return newState;
    }
    /**
     * Enable/Disable the next button of the target step.
     */
    case ActionType.SET_NEXT_BTN_STATUS: {
      targetStep.nextDisabled = !action.enableNext;

      targetStep.nextButtonDisabledI18nId = targetStep.nextDisabled
        ? action.disabledBtnToolTipI18nId
        : undefined;
      return newState;
    }
    /**
     * Mark the target step as completed and increment the current step number by 1.
     */
    case ActionType.NEXT_STEP: {
      targetStep.completed = true;
      // Increment by 2 because targetStepIdx is 0-based but the step number is 1-based
      newState.currentStepNum = targetStepIdx + 2;
      newState.currentStep = newState.steps[newState.currentStepNum];
      return newState;
    }
    /**
     * Show/Hide the next button on each step.
     */
    case ActionType.SET_HIDE_NEXT: {
      targetStep.hideNext = action.hideNext;
      return newState;
    }
    /**
     * Update the status of network connection of the NHs laptops.
     */
    case ActionType.UPDATE_NETWORK: {
      if (action.isNetworkConnected) {
        newState.isNetworkConnected = action.isNetworkConnected;
      }
      return newState;
    }
    /**
     * Set the NH's username .
     */
    case ActionType.SET_USERNAME: {
      if (action.amazonUsername) {
        newState.amazonUsername = action.amazonUsername;
      }
      return newState;
    }

    /**
     * Add the new submitted document to the existing recordings list. Update the existing document if the document
     * is already in the documents list.
     */
    case ActionType.ADD_DOCUMENT: {
      const document = action.document;
      if (document) {
        const docIdx = newState.documents.findIndex(
          (doc) => doc.documentType === document.documentType
        );

        if (docIdx < 0) {
          newState.documents.push(document);
        } else {
          newState.documents[docIdx] = document;
        }
      }
      return newState;
    }
    /**
     * Remove the last document from the app state.
     */
    case ActionType.POP_DOCUMENT: {
      newState.documents.pop();
      return newState;
    }
    /**
     * Add the new submitted document to the existing recordings list. Update the existing document if the document
     * is already in the documents list.
     */
    case ActionType.SET_REQUIRED_DOCS_NUM: {
      if (
        action.requiredDocsWithGesturesCount &&
        action.requiredDocsWithGesturesCount > 0
      ) {
        newState.requiredDocsWithGesturesCount =
          action.requiredDocsWithGesturesCount;
      }

      return newState;
    }

    /**
     * Set the legally required number of documents for the NH's hiring country
     */
    case ActionType.SET_LEGALLY_REQUIRED_DOCS_NUM: {
      if (
        action.requiredDocsForHiringCountry &&
        action.requiredDocsForHiringCountry > 0
      ) {
        newState.requiredDocsForHiringCountry =
          action.requiredDocsForHiringCountry;
      }
      return newState;
    }

    /**
     * Set the verification status of the required documents submitted by the NHs.
     */
    case ActionType.SET_DOCS_VERIFICATION_STATUS: {
      if (action.docsVerificationStatus) {
        newState.docsVerificationStatus = action.docsVerificationStatus;
      }

      return newState;
    }
    /**
     * Mark the last recorded document as submitted. It is used for indicating that NHs have clicked the submit button
     * but not the actual upload since we don't do the actual upload until we have collected all required documents.
     */
    case ActionType.SET_LAST_DOC_SUBMIT_CLICKED: {
      if (newState.documents.length > 0) {
        newState.documents[newState.documents.length - 1].submitClicked = true;
      }
      return newState;
    }

    /**
     * Sets the primary ID issuing country and document type selected by NH for ID verification.
     */
    case ActionType.SET_PRIMARY_ID_SELECTION: {
      newState.primaryIdSelection = action.primaryIdSelection;
      return newState;
    }

    /**
     * Sets a string if the secondary ID was provided.
     */
    case ActionType.SET_SECONDARY_ID_PROVIDED: {
      newState.secondaryIdProvided = action.secondaryIdProvided;
      return newState;
    }

    /**
     * Sets a boolean if the secondary ID government-issued status was selected.
     */
    case ActionType.SET_SECONDARY_ID_GOV_ISSUED: {
      newState.secondaryIdGovIssued = action.secondaryIdGovIssued;
      return newState;
    }

    /**
     * Sets the hiring country of the NH for ID verification.
     */
    case ActionType.SET_HIRING_COUNTRY: {
      newState.hiringCountry = action.hiringCountry;
      return newState;
    }

    case ActionType.SET_AMPLIFY_CONFIGURED: {
      if (action.amplifyConfigured) {
        newState.amplifyConfigured = action.amplifyConfigured;
      }
      return newState;
    }

    case ActionType.SET_NON_DIRECTED_CHAT_SUPPORT_CONTEXT: {
      if (action.nonDirectedChatSupportContext) {
        newState.nonDirectedChatSupportContext =
          action.nonDirectedChatSupportContext;
      }
      return newState;
    }

    case ActionType.SET_API_ACTIVE_REGION: {
      newState.activeRegion = action.activeRegion;
      return newState;
    }

    case ActionType.SET_WIZARD_PATH: {
      if (newState.wizardPath.current !== action.wizardPath) {
        newState.wizardPath.previous = newState.wizardPath.current;
      }
      newState.wizardPath.current = action.wizardPath;
      return newState;
    }

    case ActionType.SET_CHAT_SUPPORT_FLYOUT_VISIBLE: {
      if (action.chatSupportFlyoutVisible !== undefined) {
        newState.chatSupportFlyoutVisible = action.chatSupportFlyoutVisible;
      }
      return newState;
    }

    case ActionType.SET_GET_SUPPORT_FLYOUT_VISIBLE: {
      if (action.getSupportFlyoutVisible !== undefined) {
        newState.getSupportFlyoutVisible = action.getSupportFlyoutVisible;
        // This is a temporary arrangement which causes the chat support panel to open every time that the GetSupport
        // panel is opened.
        if (action.getSupportFlyoutVisible) {
          newState.chatSupportFlyoutVisible = true;
        }
      }
      return newState;
    }

    case ActionType.SET_CHAT_SUPPORT_REF_PAGE: {
      if (action.chatSupportRefPage) {
        newState.chatSupportRefPage = action.chatSupportRefPage;
      }
      return newState;
    }

    case ActionType.SET_CHAT_SUPPORT_REF_LINK: {
      if (action.chatSupportRefLink) {
        newState.chatSupportRefLink = action.chatSupportRefLink;
      }
      return newState;
    }

    case ActionType.SET_GET_IDV_SUPPORT_FLYOUT_VISIBLE: {
      if (
        newState.getIdvSupportFlyoutVisible !==
        action.getIdvSupportFlyoutVisible
      ) {
        newState.getIdvSupportFlyoutVisible =
          action.getIdvSupportFlyoutVisible ?? false;
      }
      return newState;
    }

    /**
     * Unable to find the corresponding action. Return the given state.
     */
    default:
      return state;
  }
};
