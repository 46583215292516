import { languageObject } from "./locales";

/**
 * This locale file will be used by the application to change the language to Vietnamese
 * based on user selection.
 **/

const vietnamese: languageObject = {
  "page.title": "Amazon Ngày 1",
  "button.next": "Tiếp theo",
  "button.back": "Trở lại",
  "button.done": "Xong",
  "text.then": "Sau đó",
  "text.chatWithITSupport": "Trò chuyện với bộ phận hỗ trợ CNTT",
  "videoGuidance.title": "Hướng dẫn bằng video",
  "videoGuidance.example": "Ví dụ",
  "videoGuidance.button.altText": "Hiển thị hướng dẫn bằng video",
  "icon.title": "Đã hoàn thành",
  "error.notSupportVideoTag": "Trình duyệt của bạn không hỗ trợ thẻ video.",
  "welcome.expander.title": "Chào mừng bạn đến với Amazon 🎉",
  "welcome.expander.body.greeting":
    "Chúng tôi rất vui vì bạn ở đây. Chúng tôi sẽ hướng dẫn bạn cách thiết lập máy tính xách tay mới của bạn.",
  "welcome.expander.body.requirements.start": "Những gì bạn sẽ cần:",
  "welcome.expander.body.requirements.list1":
    "Chìa khóa bảo mật của bạn mà chúng tôi đã gửi cho bạn.",
  "welcome.expander.body.requirements.list2": "Một kết nối Wi-Fi.",
  "welcome.expander.body.requirements.list3":
    "Tên người dùng và mật khẩu của cổng thông tin trước khi lên máy bay Embark của bạn.",
  "welcome.expander.body.requirements.list4":
    "Truy cập vào email cá nhân của bạn để nhận thêm thông tin trong suốt quá trình.",
  "welcome.expander.body.firstStep.start": "Bước đầu tiên: Kết nối với Wi-Fi",
  "welcome.expander.body.firstStep.list1":
    "Nhấp vào biểu tượng Wi-Fi trên thanh tác vụ.",
  "welcome.expander.body.firstStep.list2": "Chọn kết nối Wi-Fi của bạn.",
  "welcome.expander.body.firstStep.list3":
    "Nhập mật khẩu Wi-Fi của bạn và nhấp vào “Kết nối”.",
  "welcome.button.getStarted": "Bắt đầu",
  "identityCheck.expander.title": "Xác minh danh tính",
  "identityCheck.expander.body.header": "Quay video",
  "identityCheck.expander.body.intro":
    "Quay video giữ tài liệu ID của bạn bên cạnh khuôn mặt của bạn và hoàn thành một loạt cử chỉ tay.",
  "identityCheck.expander.body.recordInfo.title": "Thông tin về “Quay video”",
  "identityCheck.expander.body.recordInfo.body":
    "Trước khi cấp thông tin đăng nhập truy cập hệ thống Amazon, Amazon phải xác minh danh tính của bạn. Để làm như vậy, Amazon sử dụng hệ thống xác minh danh tính (“Hệ thống”). Việc sử dụng Hệ thống là hoàn toàn tùy chọn và tự nguyện. Hệ thống hoạt động như sau: tải lên ảnh của giấy tờ tùy thân có ảnh do chính phủ cấp (“Govt. ID ảnh”) và ghi lại một video về bản thân đang cầm ID ảnh của bạn. Tên trên ID ảnh phải tương tự về cơ bản với tên mà Amazon có trong hồ sơ cho bạn. Một trong những kỹ sư hỗ trợ của chúng tôi sẽ xác minh tên và hình ảnh của bạn trên ID có ảnh của bạn với khuôn mặt của bạn trong bản ghi được gửi.",
  "identityCheck.expander.body.whatIsCollected.title":
    "Những thông tin được thu thập",
  "identityCheck.expander.body.whatIsCollected.intro":
    "Để xác minh danh tính của bạn, Amazon sẽ thu thập thông tin cá nhân sau đây từ bạn:",
  "identityCheck.expander.body.whatIsCollected.item1":
    "(Các) ảnh của giấy tờ tùy thân có ảnh Chính phủ của bạn",
  "identityCheck.expander.body.whatIsCollected.item2":
    "Một video selfie của bạn",
  "identityCheck.expander.body.whatIsCollected.purpose":
    "Thông tin cá nhân của bạn sẽ chỉ được sử dụng cho mục đích nhận dạng và xác minh. Amazon sẽ hủy tất cả thông tin cá nhân trong vòng 7 ngày kể từ khi hoàn thành quá trình xác minh, trừ khi luật pháp yêu cầu lưu giữ thông tin cá nhân của bạn trong một thời gian dài hơn. Để biết thêm thông tin, vui lòng tham khảo Thông báo Quyền riêng tư của Amazon HR, được cung cấp như một phần của gói giới thiệu của bạn hoặc thông qua cổng thông tin nội bộ của Amazon, AtoZ, sau khi hoàn thành quy trình thiết lập máy tính xách tay.",
  "identityCheck.expander.body.light.instruction":
    "Khi bạn bắt đầu thử thách cử chỉ ID, bạn sẽ có <b>10 phút</b> để ghi lại và gửi một <b>video 10 giây</b> duy nhất về bản thân bạn hoàn thành các cử chỉ.",
  "identityCheck.expander.body.helpLinkPre":
    "Không thể hoàn thành các hành động? Vui lòng {chatWithITSupportLink} để xác minh danh tính của bạn trong cuộc gọi điện video Chime.",
  "identityCheck.expander.body.helpLinkPost":
    "để xác minh danh tính của bạn trong cuộc gọi video Chime.",
  "identityCheck.challenges.title": "Hành động thách thức",
  "identityCheck.challenges.completed":
    "Hành động thử thách của bạn đã được ghi lại.",
  "identityCheck.challenges.instructions.completeByTime":
    "Vui lòng hoàn thành thử thách này trước {time}",
  "identityCheck.challenges.instructions":
    "Làm theo hướng dẫn để quay một video 10 giây thực hiện hai cử chỉ sau:",
  "identityCheck.challenges.expired":
    "Giới <b>hạn thời gian của bạn đã hết hạn. </b>Xin vui lòng {chatWithIt}. Bạn sẽ được yêu cầu tham gia cuộc gọi video và hiển thị ID của bạn cho Kỹ sư hỗ trợ CNTT.",
  "identityCheck.challenges.instructions.unhideButton":
    "Bắt đầu thử thách cử chỉ",
  "identityCheck.challenges.forSeconds": "trong {second} giây.",
  "identityCheck.button.recording": "Ghi âm: {countDownTimer}",
  "identityCheck.button.startRecord": "Bắt đầu ghi âm",
  "identityCheck.button.enableCamera": "Bật Máy ảnh",
  "identityCheck.button.retry": "Thử lại",
  "identityCheck.do.title": "Những việc cần làm",
  "identityCheck.do.hint1": "Nhìn thẳng vào máy ảnh.",
  "identityCheck.do.hint2": "Sử dụng tiếp xúc với ánh sáng thích hợp.",
  "identityCheck.dont.title": "Không nên",
  "identityCheck.dont.hint1":
    "Che ID của bạn bằng ngón tay hoặc bàn tay của bạn.",
  "identityCheck.dont.hint2": "Che mặt bằng mặt nạ.",
  "identityCheck.dont.hint3": "Đeo kính râm (kính theo toa là được).",
  "identityCheck.dont.hint4":
    "Đeo mũ hoặc mũ lưỡi trai (mũ tôn giáo không che mặt là được).",
  "identityCheck.full.steps.recordPrimaryId":
    "Ghi lại video chứa {requiredDocuments, plural, one {government} other {primary}} ID",
  "identityCheck.full.steps.recordSecondaryId": "Quay video giữ ID phụ",
  "identityCheck.full.steps.secondaryIdGovIssued":
    "ID thứ cấp của bạn có được chính phủ cấp không?",
  "identityCheck.secondaryIdGovIssued.instruction":
    "ID phụ có thể là một tài liệu phi chính phủ với tên và ảnh của bạn. Mang cái này vào ngày đầu tiên của bạn.",
  "identityCheck.full.steps.recordChallengeActions":
    "Ghi lại các hành động thử thách",
  "identityCheck.full.primaryId.expandAltText":
    "Nhấp để tải lên {requiredDocuments, plural, one {government} other {primary}} Id",
  "identityCheck.full.secondaryId.expandAltText": "Nhấp để tải lên Id phụ",
  "identityCheck.full.challengeActions.expandAltText":
    "Nhấp để tải lên các hành động thử thách",
  "identityCheck.primaryId.instruction":
    "Quay video dài 15 giây chứa {requiredDocuments, plural, one {government} other {primary}} ID bên cạnh khuôn mặt của bạn và sau đó lên máy ảnh. ID này phải là ID do chính phủ cấp chưa hết hạn từ danh sách các tài liệu được chấp nhận này. Nó phải hiển thị tên, ngày sinh và ảnh của bạn.",
  "identityCheck.secondaryId.instruction":
    "Ghi lại một đoạn video dài 15 giây để ID phụ vật lý của bạn bên cạnh khuôn mặt của bạn và sau đó lên máy ảnh. ID phụ có thể là giấy tờ tùy thân do chính phủ cấp khác (ví dụ: bằng lái xe) hoặc giấy tờ tùy thân có ảnh không do chính phủ cấp (ví dụ: thẻ Costco hoặc thẻ sinh viên).",
  "identityCheck.primaryIdCountry.select.label":
    "Quốc gia nào đã cấp ID của bạn?",
  "identityCheck.primaryId.select.label": "Loại ID",
  "identityCheck.primaryId.select.placeholder": "Chọn loại ID",
  "identityCheck.primaryId.country.select.placeholder":
    "Chọn quốc gia phát hành ID",
  "identityCheck.primaryId.video.instruction":
    "Giữ ID {requiredDocuments, plural, one {Government} other {Primary}} bên cạnh khuôn mặt của bạn, sau đó di chuyển nó về phía máy ảnh.",
  "identityCheck.primaryId.video.instruction2":
    "Giữ ID của bạn cách máy ảnh 3 inch cho đến khi nó lấy nét.",
  "identityCheck.primaryId.completed":
    "ID chính và khuôn mặt của bạn đã được ghi lại.",
  "identityCheck.governmentId.completed":
    "ID chính phủ và khuôn mặt của bạn đã được ghi lại.",
  "identityCheck.secondaryId.completed":
    "ID phụ và khuôn mặt của bạn đã được ghi lại.",
  "identityCheck.primary.title":
    "Quay video chứa ID {requiredDocuments, plural, one {government} other {primary}}",
  "identityCheck.secondaryID.governmentID.radio1": "ID do chính phủ cấp",
  "identityCheck.secondaryID.governmentID.radio2": "ID không do chính phủ cấp",
  "identityCheck.secondaryId.title": "Quay video chứa ID phụ của bạn",
  "identityCheck.secondaryId.video.instruction":
    "Giữ ID phụ của bạn bên cạnh khuôn mặt của bạn, sau đó di chuyển nó về phía máy ảnh.",
  "identityCheck.secondaryId.video.instruction2":
    "Giữ ID của bạn cách máy ảnh 3 inch cho đến khi nó lấy nét.",
  "identityCheck.button.goBackAltText": "Quay lại",
  "identityCheck.button.submitReview": "Gửi để xem xét",
  "identityCheck.button.loading": "Đang gửi...",
  "identityCheck.button.tooltip.disallowResubmit": "Bản ghi video đã được gửi.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Vui lòng chọn loại ID {requiredDocuments, plural, one {government} other {primary}} từ menu thả xuống chọn.",
  "identityCheck.button.tooltip.missingSecondaryIdGovIssued":
    "Vui lòng chọn loại ID phụ của bạn có được chính phủ cấp hay không bằng các nút radio.",
  "identityCheck.errors.unableToVerify":
    "Chúng tôi không thể xác minh danh tính của bạn. Xin vui lòng {link}. Bạn sẽ được yêu cầu tham gia cuộc gọi video và hiển thị ID của mình cho nhân viên Hỗ trợ CNTT của Amazon và bạn sẽ được cung cấp một đường dẫn khác để thiết lập máy tính xách tay của mình.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>Chúng tôi không thể xác minh danh tính của bạn bằng cách sử dụng Xác minh danh tính thủ công. </b>Vui lòng thử sử dụng Xác <a>minh danh tính sinh trắc học</a> thay thế.",
  "identityCheck.errors.failedToSubmit":
    "Chúng tôi không thể gửi video của bạn. Vui lòng thử lại sau vài giây hoặc {link} để được hỗ trợ thêm.",
  "identityCheck.errors.chatWithIT": "Trò chuyện với bộ phận hỗ trợ CNTT",
  "identityCheck.pending.pleaseWait":
    "Trong khi video của bạn đang được xác minh, vui lòng không điều hướng ra khỏi trang này.",
  "identityCheck.pending.estimatedWaitTime":
    "Thời gian chờ xác minh danh tính thông thường là {minTimeMin} đến {maxTimeMin} phút.",
  "identityCheck.verified.successMsg":
    "Chúng tôi đã xác minh danh tính của bạn.",
  "identityCheck.ivv.body.intro":
    "Thiết lập tên người dùng và mật khẩu Amazon của bạn yêu cầu một lớp bảo mật bổ sung để chứng minh bạn là bạn chứ không phải ai đó giả vờ là bạn.",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Vui lòng chọn cả hai ô ở trên và nhấp vào {agree} để xác nhận rằng bạn đã đọc và hiểu, hoặc nhấp vào Hủy để xác minh danh tính của bạn bằng quy trình thủ công.",
  "identityCheck.ivv.failedBody":
    "<b>Chúng tôi không thể xác minh danh tính của bạn bằng cách sử dụng tính năng Quét khuôn mặt tự động. </b>Vui lòng <a>ghi lại video</a> giữ tài liệu ID của bạn bên cạnh khuôn mặt của bạn và hoàn thành một loạt cử chỉ tay.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>Chúng tôi không thể xác minh danh tính của bạn bằng cách sử dụng tính năng Quét khuôn mặt tự động. </b>{chatWithITSupportLink} để được hỗ trợ thêm.",
  "identityCheck.ivv.inFlight":
    "Bạn đã xác minh trên chuyến bay và không thể bắt đầu xác minh mới. Vui lòng làm mới trang định kỳ để kiểm tra trạng thái.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Hoàn tất kiểm tra danh tính của bạn</b>",
  "identityCheck.ivv.pendingBackendAgreement":
    "Điều này có thể mất vài phút. Nếu bạn đã chờ đợi hơn 10 phút, hãy sử dụng nút “Nhận hỗ trợ” để được hỗ trợ.",
  "identityCheck.ivv.scanAbortHeader":
    "Gặp sự cố với quá trình quét tự động của bạn?",
  "identityCheck.ivv.scanAbortBody":
    "Không có vấn đề gì. Thay vào đó, bạn có thể sử dụng quy <openModal>trình xác minh thay thế</openModal> của chúng tôi.",
  "identityCheck.ivv.scanAbortModalHeader":
    "Bạn có chắc chắn muốn sử dụng quy trình xác minh thay thế không?",
  "identityCheck.ivv.scanAbortModalBody":
    " <p>Nếu bạn đã quyết định bỏ qua quá trình quét sinh trắc học tự động. Chỉ để cho bạn biết, bạn sẽ không thể quay lại và sử dụng tùy chọn quét khuôn mặt sau này. </p><p>Quá trình xác minh thay thế có thể mất nhiều thời gian hơn một chút, nhưng nhóm của chúng tôi sẽ hướng dẫn bạn mọi thứ bạn cần để thiết lập máy tính xách tay của mình vào ngày đầu tiên. </p>",
  "identityCheck.ivv.scanAbortModalCancel": "Hủy",
  "identityCheck.ivv.scanAbortModalConfirm": "Xác nhận",
  "identityCheck.pathchooser.title":
    "Chọn tùy chọn xác minh của bạn từ một trong các phương pháp sau.",
  "identityCheck.pathchooser.card.recommended": "Khuyến nghị",
  "identityCheck.pathchooser.card.estCompletionAutomated":
    "Thời gian hoàn thành: <b>Ít hơn {count} {unit}</b>",
  "identityCheck.pathchooser.card.estCompletionManual":
    "Thời gian hoàn thành: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "phút",
  "identityCheck.pathchooser.card.unitMinutes": "phút",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Loại xác minh: Tự <b>động</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Loại xác minh: Thủ <b>công</b>",
  "identityCheck.pathchooser.card.oneId":
    "Yêu cầu ID: <b>1 ID do chính phủ cấp</b>",
  "identityCheck.pathchooser.card.twoIdsAutomated":
    "Yêu cầu ID: <b>2 ID do Chính phủ cấp</b>",
  "identityCheck.pathchooser.card.twoIdsManual":
    "Yêu cầu ID: <b>1 ID do Chính phủ cấp, 1 ID phụ</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "Một loại xác minh phải được chọn",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "Xác minh đã được thực hiện trên chuyến bay",
  "identityCheck.pathchooser.card.ivv.title": "Quét khuôn mặt tự động",
  "identityCheck.pathchooser.card.ivv.body":
    "Quét khuôn mặt của bạn vào ngày đầu tiên của bạn và chúng tôi sẽ so sánh nó với ảnh trên giấy tờ tùy thân do chính phủ cấp của bạn. Danh tính của bạn sẽ được xác nhận ngay lập tức và bạn sẽ sẵn sàng để đi.",
  "identityCheck.ivv.countDownTimer":
    " còn lại cho đến khi bắt đầu quá trình xác minh thay thế (“đánh giá được xác minh bởi con người”)",
  "identityCheck.pathchooser.card.manual.title": "Ghi lại video",
  "identityCheck.pathchooser.card.manual.body":
    "Quay video giữ tài liệu ID của bạn bên cạnh khuôn mặt của bạn và hoàn thành một loạt cử chỉ tay. Gửi những video này đến người xác minh con người của Amazon để xem xét.",
  "identityCheck.pathchooser.card.contactsupport.title":
    "Liên hệ với bộ phận hỗ trợ",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Trò chuyện với kỹ sư hỗ trợ CNTT trong thời gian thực và xác minh danh tính của bạn thông qua cuộc gọi hội nghị video trên Amazon Chime, người sẽ hướng dẫn bạn quy trình thiết lập thủ công.",
  "identityCheck.pathchooser.additionalConcerns":
    "Nếu bạn gặp bất kỳ vấn đề nào hoặc có thêm mối quan tâm, vui lòng liên hệ với nhóm hỗ trợ CNTT của chúng tôi.",
  "identityCheck.pathchooser.additionalConcerns.contactIt":
    "Họ có thể hỗ trợ bạn thông qua một cuộc gọi video an toàn để xác minh danh tính của bạn. {chatWithITSupportLink}.",
  "identityCheck.pathchooser.additionalConcerns.chimeVerificationType":
    "Loại xác minh: Cuộc <b>gọi hội nghị video</b>",
  "identityCheck.pathchooser.submitBtn": "Nộp",
  "identityCheck.pathchooser.continueBtn": "Tiếp tục",
  "amazonUsername.expander.title": "Tên người dùng Amazon",
  "amazonUsername.expander.body.info":
    "Tại Amazon, bạn sẽ sử dụng tên người dùng của mình để đăng nhập vào máy tính của mình và truy cập các công cụ và tài nguyên của Amazon. Đó là một phần trong địa chỉ email Amazon của bạn đi trước @. Địa chỉ email của bạn là {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Tên người dùng Amazon của bạn là: {username}",
  "registerSecurityKey.expander.title": "Đăng ký khóa bảo mật",
  "registerSecurityKey.expander.body.intro":
    "Tại Amazon, chúng tôi sử dụng khóa bảo mật của bạn như một bước trong xác thực đa yếu tố để đảm bảo đó là bạn. Bạn sẽ được nhắc nhấn phím bảo mật của mình để truy cập Mạng Amazon, hàng ngày. Mỗi khi bạn nhấn phím, nó sẽ tạo ra một chuỗi ký tự ngẫu nhiên.",
  "registerSecurityKey.expander.body.securityKeyTitle": "Khóa bảo mật là gì?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    " thiết bị nhựa được sử dụng để truy cập an toàn các tài nguyên Amazon. Khi máy tính xách tay Amazon của bạn được giao cho bạn, Chúng tôi cũng gửi cho bạn hai khóa bảo mật USB. Khóa thứ hai là khóa dự phòng của bạn - lưu trữ nó ở một nơi an toàn trong trường hợp khóa chính của bạn bị mất hoặc bị hỏng. “",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "Tại Amazon, chúng tôi sử dụng khóa bảo mật của bạn như một bước trong xác thực đa yếu tố để đảm bảo bạn là chính bạn. Để sử dụng nhiều công cụ và tài nguyên nội bộ của Amazon mỗi ngày, bạn sẽ nhập mật khẩu cùng với khóa bảo mật để truy cập mạng Amazon. Chọn mã PIN khóa bảo mật dễ nhớ. Bạn sẽ nhập nó mỗi ngày khi đăng nhập vào các dịch vụ nội bộ của Amazon và VPN. Nó không hết hạn.",
  "registerSecurityKey.expander.body.midwayTitle": "Midway là gì?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway là dịch vụ quản lý các khóa bảo mật và mã PIN khóa bảo mật.",
  "registerSecurityKey.expander.body.howTitle":
    "Làm cách nào để đăng ký khóa bảo mật của mình?",
  "registerSecurityKey.expander.body.step1":
    "Gửi mã PIN tạm thời đến email cá nhân của bạn để truy cập Midway. Mở email cá nhân của bạn để truy cập mã PIN tạm thời này.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "Chúng tôi đã gửi mã xác minh đến email cá nhân của bạn. Mã PIN của bạn sẽ hết hạn sau 4 giờ. Không nhận được mã? {resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Khi bạn có mã PIN tạm thời, hãy đăng ký khóa của bạn trên Midway. Bạn sẽ được yêu cầu nhập tên người dùng Amazon và mã PIN tạm thời của bạn.",
  "registerSecurityKey.expander.body.step3":
    "Cắm một trong các khóa bảo mật của bạn vào cổng USB trên máy tính xách tay này. Bạn có thể cần sử dụng 1 trong các bộ điều hợp mà bạn nhận được. (Bạn chỉ cần đăng ký 1 khóa bảo mật của bạn. Chìa khóa thứ hai là một bản sao lưu.) Cẩn thận chèn chìa khóa này với lỗ kim hướng ra ngoài. Bạn sẽ thấy đèn xanh khi khóa bảo mật này được chèn chính xác.",
  "registerSecurityKey.expander.body.step4":
    "Tại trang Đăng nhập, nhập nội dung sau:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Tên người dùng Amazon:</bold> tên người dùng được hiển thị ở bước trước.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>Mã PIN (hoặc mã PIN khóa bảo mật): mã</bold> PIN tạm thời được gửi đến địa chỉ email cá nhân của bạn.",
  "registerSecurityKey.expander.body.step5":
    "Sau khi nhập tên người dùng và mã PIN khóa bảo mật tạm thời, hãy nhấp <bold>vào Đăng</bold> nhập:",
  "registerSecurityKey.expander.body.step6":
    "Bạn sẽ thấy một thông báo có nội dung “Welcome {username}! “",
  "registerSecurityKey.expander.body.step7":
    "Nhấp vào liên kết <bold>cổng thông tin đăng ký khóa bảo mật</bold> trong tin nhắn chào mừng để tiếp tục.",
  "registerSecurityKey.expander.body.step8":
    "Trên trang có nội dung “Chào mừng đến với bảng điều khiển khóa bảo mật”, nhấp vào <bold>Đăng ký khóa bảo mật</bold>.",
  "registerSecurityKey.expander.body.step9":
    "Trên trang có nội dung “Bắt đầu với khóa bảo mật của bạn”, nhấp vào <bold>Bắt đầu đăng ký</bold>. Nếu bạn thấy hai tùy chọn ở đây, hãy chọn Bắt đầu đăng ký khóa bảo mật phần cứng.",
  "registerSecurityKey.expander.body.step10":
    "Chọn hình ảnh phù hợp với khóa bảo mật mà bạn nhận được.",
  "registerSecurityKey.expander.body.step11":
    "Tiếp theo, bạn sẽ nhập mật khẩu một lần (OTP) được tạo bởi khóa bảo mật của bạn. Nhấp vào hộp văn bản trống có nội dung “OTP của bạn sẽ xuất hiện ở đây, không nhập vào hộp này”.",
  "registerSecurityKey.expander.body.step12.1":
    "Nhấn phím bảo mật được cắm vào máy tính của bạn. Áp dụng áp lực nhẹ trong 3-5 giây cho đến khi một chuỗi văn bản xuất hiện trong hộp văn bản trống. Đây là OTP. Tùy thuộc vào loại khóa bảo mật bạn có, bạn sẽ cần phải áp dụng áp lực nhẹ lên hai <bold>bên hoặc <bold>trên</bold> cùng</bold> của nó.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>Nếu bạn đang nhấn khóa bảo mật nhưng nó không nhập OTP, hãy</bold> tháo ngón tay ra khỏi khóa bảo mật, bấm vào hộp văn bản, sau đó chạm vào khóa bảo mật một lần nữa.",
  "registerSecurityKey.expander.body.step13":
    "Nhập mã PIN khóa bảo mật vĩnh viễn mà bạn muốn sử dụng trong các trường <bold>Mã PIN mới</bold> và <bold>Xác nhận mã PIN mới</bold>, sau đó nhấp vào <bold>Tiếp tục</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Nhập mã PIN khóa bảo mật vĩnh viễn mà bạn muốn sử dụng trong các trường <bold>Mã PIN mới</bold> và <bold>Xác nhận mã PIN mới</bold>, sau đó nhấp vào <bold>Tiếp tục</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Quan trọng</bold>: Chọn mã PIN khóa bảo mật dễ nhớ. Bạn sẽ nhập nó mỗi ngày khi đăng nhập vào các dịch vụ nội bộ của Amazon và VPN. Nó không hết hạn.",
  "registerSecurityKey.expander.body.step14":
    "Trên trang có nội dung “Vui lòng đọc tất cả các hướng dẫn bên dưới... “nhấn Tiếp <bold>tục</bold>.",
  "registerSecurityKey.expander.body.step15":
    "Trên trang tiếp theo, nhấn và giữ khóa bảo mật của bạn một lần nữa. Bạn có thể nhận được một cửa sổ bật lên yêu cầu thông tin về khóa bảo mật của bạn. Nhấp vào <bold>Cho phép</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "Tiếp theo, bạn sẽ thấy 1 trong 2 màn hình:",
  "registerSecurityKey.expander.body.screenPossibilityList.1":
    'Nếu bạn thấy "<bold>Bạn đã đăng ký thành công khóa bảo mật của mình</bold> “, bạn đã hoàn tất bước này. Giữ khóa bảo mật thứ hai của bạn an toàn. Hãy chắc chắn rằng bạn đăng ký nó sau này làm bản sao lưu nếu bạn mất cái bạn vừa đăng ký.',
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "Nếu bạn được nhắc nhập mã PIN, bạn vẫn chưa hoàn tất. Tiến hành với các bước sau:",
  "registerSecurityKey.expander.body.setUpNewPin.1":
    "Nhập mã <bold>PIN khóa bảo mật</bold> của bạn vào các <bold>trường PIN</bold> và <bold>Xác nhận mã PIN</bold>, sau đó nhấp vào <bold>Tiếp theo</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "Trong lời nhắc tiếp theo, nhấn và giữ lại khóa bảo mật của bạn. Bạn có thể nhận được một cửa sổ bật lên yêu cầu thông tin về khóa bảo mật của bạn. Nhấp vào <bold>Cho phép</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3":
    'Nếu đăng ký thành công, bạn sẽ thấy "<bold>Bạn đã đăng ký thành công khóa bảo mật của mình</bold>. “ Nếu bạn không thấy thông báo này, hãy thử lại quy trình đăng ký trong một trình duyệt khác.',
  "registerSecurityKey.expander.body.setUpNewPin.3.1":
    'Nếu đăng ký thành công, bạn sẽ thấy <bold>“Bạn đã đăng ký thành công khóa bảo mật của mình. </bold>"',
  "registerSecurityKey.expander.body.setUpNewPin.3.2":
    "Nếu bạn không thấy thông báo này, bạn sẽ cần thử lại bước đăng ký. Đóng cửa sổ Midway ở bên phải và thử lại bước này bằng cách nhấp vào nút “Đăng ký khóa bảo mật trên Midway” để thử lại bước này.",
  "registerSecurityKey.expander.body.secondKeySafe":
    "Giữ khóa bảo mật thứ hai của bạn an toàn. Bạn có thể đăng ký nó sau này như một bản sao lưu nếu bạn mất cái bạn vừa đăng ký.",
  "registerSecurityKey.expander.body.note":
    "<bold>Lưu ý</bold>: Nếu bạn gặp lỗi liên quan đến “Tuân thủ thiết bị” hoặc “AEA”, bạn sẽ cần khởi động lại Easy Onboarding bằng cách đăng xuất khỏi “tokenadmin” và đăng nhập lại để cho phép cập nhật thiết bị hoàn tất. Vui lòng làm theo hướng dẫn được cung cấp cho bạn trong địa chỉ email cá nhân của bạn để khởi động lại quá trình này. Nếu điều này không giải quyết được lỗi, vui lòng sử dụng liên kết “Liên hệ với bộ phận hỗ trợ” để được hỗ trợ.",
  "registerSecurityKey.checkbox.confirmation":
    "Tôi xác nhận rằng tôi đã đăng ký khóa bảo mật của mình.",
  "registerSecurityKey.button.sendTempPin": "Gửi mã PIN tạm thời",
  "registerSecurityKey.button.sendTempPin.loadingText": "Đang gửi...",
  "registerSecurityKey.button.sendTempPin.error":
    "Đã xảy ra lỗi khi gửi mã PIN. Vui lòng thử lại sau vài phút.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "Chúng tôi không thể gửi cho bạn mã PIN tạm thời. Vui lòng liên hệ với bộ phận hỗ trợ.",
  "registerSecurityKey.button.registerSecurityKey":
    "Đăng ký khóa bảo mật trên Midway",
  "registerSecurityKey.link.resend": "Gửi lại.",
  "registerSecurityKey.image.securityKeyGrid.altText":
    'Một lưới gồm sáu hình ảnh hiển thị các loại khóa bảo mật. Các loại là “YubiKey 4", “YubiKey 4 Nano”, “USB-C YubiKey”, “USB-C Nano”, “Zukey” và “USB-C Zukey”.',
  "registerSecurityKey.image.tempPinLogin.altText":
    "Ảnh chụp màn hình của trang Đăng nhập Amazon Midway. Trang có nội dung “Đăng nhập”, bao gồm các trường để nhập tên người dùng Amazon và mã PIN Amazon và có nút “Đăng nhập”.",
  "registerSecurityKey.image.midwayWelcomeMessage.altText":
    "Ảnh chụp màn hình của một trang ví dụ với một tin nhắn chào mừng. Trang chào mừng ví dụ có nội dung “Chào mừng jeffbezos!. Để đăng ký hoặc quản lý khóa bảo mật của bạn, hãy truy cập cổng đăng ký khóa bảo mật. “",
  "registerSecurityKey.image.securityKeyDashboard.altText":
    "Ảnh chụp màn hình trang chào mừng bảng điều khiển khóa bảo mật Amazon Enterprise Access. Trang có nội dung “Chào mừng bạn đến với bảng điều khiển khóa bảo mật. Amazon Enterprise Access” và có một nút có nội dung “Đăng ký khóa bảo mật”.",
  "registerSecurityKey.image.securityKeyGetStarted.altText":
    "Ảnh chụp màn hình của trang Bắt đầu với khóa bảo mật của bạn. Trang có nội dung “Bắt đầu với khóa bảo mật của bạn. Nhận chìa khóa bảo mật tại văn phòng Hỗ trợ CNTT gần nhất hoặc máy bán hàng tự động CNTT. Sau khi bạn nhận khóa bảo mật, hãy nhấp vào Bắt đầu đăng ký. Quan trọng: Bạn phải có khóa bảo mật mới bên mình để đăng ký nó. Bạn phải cắm khóa bảo mật mới của bạn vào thời điểm này. “và có một nút cho biết “Bắt đầu đăng ký”.",
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText":
    'Ảnh chụp màn hình của trang Bắt đầu với khóa bảo mật của bạn. Trang có nội dung “Bắt đầu với khóa bảo mật của bạn. Bạn đã nhặt loại khóa bảo mật nào? Chọn khóa bảo mật phù hợp với khóa bạn có. “và có lưới gồm sáu loại khóa bảo mật. Các loại khóa bảo mật được liệt kê là “YubiKey 4", “YubiKey 4 Nano”, “USB-C YubiKey”, “USB-C Nano”, “Zukey” và “USB-C Zukey”.',
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText":
    "Ảnh chụp màn hình của trang Bắt đầu với khóa bảo mật của bạn. Trang có nội dung “Bắt đầu với khóa bảo mật của bạn. Đầu tiên, chèn khóa bảo mật của bạn vào cổng USB trên máy tính của bạn. Thứ hai, nhấn và giữ phím bảo mật trong 3-5 giây cho đến khi văn bản được hiển thị trong hộp bên dưới. “và có một trường nhập văn bản với văn bản điền cho biết “OTP của bạn sẽ xuất hiện ở đây, không nhập vào hộp này”.",
  "registerSecurityKey.image.pressingSecurityKeySide.altText":
    "Gif hình ảnh động của một người nhấn bên cạnh khóa bảo mật được cắm vào máy tính xách tay",
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "Gif hình ảnh động của một người nhấn đầu khóa bảo mật được cắm vào nắp",
  "registerSecurityKey.image.securityKeyCreatePin.altText":
    "Ảnh chụp màn hình của trang tạo mã PIN khóa bảo mật. Trang có nội dung “Bạn được yêu cầu đặt mã PIN khóa bảo mật mới để đăng ký khóa bảo mật này. Đừng quên mã PIN khóa bảo mật của bạn. Mã PIN của bạn sẽ được sử dụng để cho phép bạn đăng nhập vào tài nguyên Amazon và kết nối với VPN bằng khóa bảo mật của bạn”. Trang này cũng bao gồm một trường nhập văn bản có nhãn “Mã PIN mới (8-64 ký tự ASCII)”, một trường nhập văn bản khác có nhãn “Xác nhận mã PIN mới” và một nút có nội dung “Tiếp tục”.",
  "registerSecurityKey.image.securityKeySuccess.altText":
    "Ảnh chụp màn hình của một tin nhắn thành công. Thông báo cho biết “Bạn đã đăng ký thành công khóa bảo mật của mình. Có thể mất đến 1 giờ để khóa bảo mật này hoạt động trên tất cả các công cụ và tài nguyên của Amazon. “",
  "registerSecurityKey.image.securityKeyCreateNewPin.altText":
    "Ảnh chụp màn hình của biểu mẫu bật lên để thiết lập mã PIN mới. Cửa sổ bật lên có nội dung “Yêu cầu mã PIN. Thiết lập mã PIN mới cho khóa bảo mật của bạn”. Cửa sổ bật lên cũng có một trường nhập văn bản có nhãn “PIN” và một trường nhập văn bản khác có nhãn “Xác nhận mã PIN”.",
  "connectVpn.expander.title": "Kết nối với mạng Amazon (VPN)",
  "connectVpn.expander.body.intro":
    "Bạn sẽ cần kết nối với VPN để truy cập tài nguyên nội bộ trong khi bạn đang làm việc tại chỗ hoặc từ xa. Các phiên VPN hết hạn sau mỗi 18 giờ và sau đó yêu cầu bạn kết nối lại.",
  "connectVpn.expander.body.mac.step1":
    "Mở <bold>Cisco AnyConnectSecure</bold> Mobility Client.",
  "connectVpn.expander.body.mac.step2":
    "Máy khách Cisco AnyConnect sẽ xuất hiện. <bold>Nhấp vào Kết nối</bold>.",
  "connectVpn.expander.body.mac.step3":
    "Một cửa sổ bật lên sẽ xuất hiện. Trong trường <bold>Nhóm</bold>, chọn <bold>Orca-Amazon-Onboarding</bold> từ danh sách thả xuống.",
  "connectVpn.expander.body.mac.step4":
    "Nhập tên người dùng Amazon của bạn vào trường <bold>Tên</bold> người dùng.",
  "connectVpn.expander.body.mac.step5":
    "Trong trường Mã PIN khóa bảo mật+Nhấn khóa bảo mật, nhập mã <bold>PIN khóa bảo mật</bold> của bạn và sau đó nhấn và giữ khóa bảo mật của bạn cho đến khi một chuỗi ký tự dài xuất hiện trong trường. Đừng bấm OK - khi bạn nhấn và giữ khóa bảo mật của mình, kết nối VPN sẽ tự động bắt đầu xác thực.",
  "connectVpn.expander.body.mac.step5.warning":
    "Thay vào đó, <bold>trường Mã PIN của khóa bảo mật+Nhấn khóa bảo mật</bold> có thể nói <bold>Mật khẩu</bold>. Đừng nhập mật khẩu Amazon của bạn ở đây. Nhập mã PIN khóa bảo mật của bạn, sau đó nhấn và giữ khóa bảo mật của bạn.",
  "connectVpn.expander.body.mac.step6":
    "Một cửa sổ bật lên sẽ xuất hiện, xác nhận bạn đã kết nối với mạng Amazon qua VPN. Chọn, <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Ảnh chụp màn hình của ứng dụng Cisco AnyConnect Secure Mobility Client.",
  "connectVpn.image.mac.step3.altText":
    "Ảnh chụp màn hình cửa sổ bật lên với các trường Nhóm, Tên người dùng và Mã PIN khóa bảo mật để kết nối với VPN bằng ứng dụng Cisco AnyConnect Secure Mobility Client với ORCA-Amazon-Onboarding được chọn từ danh sách thả xuống trong trường Nhóm.",
  "connectVpn.image.mac.step4.altText":
    "Ảnh chụp màn hình cửa sổ bật lên với các trường Nhóm, Tên người dùng và mã PIN khóa bảo mật để kết nối với VPN bằng ứng dụng Cisco AnyConnect Secure Mobility Client với hộp màu đỏ làm nổi bật trường Tên người dùng.",
  "connectVpn.image.mac.step5.altText":
    "Ảnh chụp màn hình cửa sổ bật lên với các trường Mã PIN Nhóm, Tên người dùng và khóa bảo mật để kết nối với VPN bằng ứng dụng Cisco AnyConnect Secure Mobility Client với hộp màu đỏ làm nổi bật mã PIN khóa bảo mật+Nhấn trường khóa bảo mật và nút OK được gạch ngang bằng chữ X màu đỏ.",
  "connectVpn.image.mac.step6.altText":
    "Ảnh chụp màn hình kết nối thành công với VPN.",
  "connectVpn.expander.body.windows.step1":
    "Nhấn <bold>phím Windows</bold> trên máy tính xách tay này sẽ cho phép truy cập vào “Menu Bắt đầu”.",
  "connectVpn.expander.body.windows.step2":
    "Từ menu Bắt đầu, chọn <bold>Cisco AnyConnect</bold> (đây là phần mềm của Amazon để truy cập VPN).",
  "connectVpn.expander.body.windows.step3":
    "Máy khách Cisco AnyConnect sẽ xuất hiện. Phần mềm Cisco AnyConnect là những gì được sử dụng cho VPN của Amazon. Nhấp vào <bold>Kết nối</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Nhấp để mở menu thả xuống trong trường Nhóm. Chọn <bold>Orca-Amazon-Onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Nhập tên người dùng Amazon của bạn vào trường <bold>Tên</bold> người dùng.",
  "connectVpn.expander.body.windows.step6":
    "Trong trường M <bold>ật khẩu</bold>, nhập mã PIN khóa bảo mật của bạn, sau đó nhấn và giữ khóa bảo mật của bạn cho đến khi một chuỗi ký tự dài xuất hiện trong trường. Đừng bấm <bold>OK</bold> - khi bạn nhấn và giữ khóa bảo mật của mình, kết nối VPN sẽ tự động bắt đầu xác thực.",
  "connectVpn.expander.body.windows.step7":
    "Khi VPN được kết nối, một thông báo bảo mật sẽ xuất hiện xác nhận kết nối. Bấm <bold>OK</bold> để loại bỏ hộp thoại.",
  "connectVpn.image.windows.step1.altText": "Nhấn phím Windows.",
  "connectVpn.image.windows.step2.altText":
    "Chọn Cisco AnyConnect từ menu bắt đầu.",
  "connectVpn.image.windows.step4.altText":
    "Chọn Orca-Amazon-Onboarding từ danh sách thả xuống trong trường Nhóm.",
  "connectVpn.image.windows.step6.altText":
    "Nhập mã PIN khóa bảo mật của bạn, sau đó nhấn và giữ bảo mật của bạn.",
  "connectVpn.button.tooltip.vpnNotConnectedHint": "Kết nối với VPN.",
  "amazonPassword.expander.title": "Tạo mật khẩu Amazon",
  "amazonPassword.expander.body.intro1":
    "Tiếp theo, bạn sẽ tạo mật khẩu mà bạn sẽ sử dụng để đăng nhập vào máy tính xách tay của mình mỗi ngày và truy cập các công cụ của Amazon.",
  "amazonPassword.expander.body.intro2":
    "Bạn sẽ nhập mã PIN khóa bảo mật của mình, sau đó nhấn và giữ ngón tay của bạn vào khóa bảo mật USB của bạn, để đặt mật khẩu của bạn lần đầu tiên. Hãy <bold>nhớ mật khẩu này</bold>, vì bạn sẽ cần nó để tiếp tục thiết lập máy tính xách tay của bạn.",
  "amazonPassword.expander.body.banner":
    "Khi tạo mật khẩu, bạn sẽ được yêu cầu nhấn ngón tay vào khóa bảo mật USB. Điều này sẽ nhấn “enter” cho bạn. Không cần phải nhấp vào “Enter” hoặc “Submit”. Nếu bạn nhận được lỗi, vui lòng bỏ qua nó và tiếp tục thiết lập máy tính xách tay của bạn.",
  "amazonPassword.expander.body.confirmation":
    "Tôi xác nhận rằng tôi đã tạo thành công mật khẩu của mình.",
  "amazonPassword.button.createPassword": "Tạo mật khẩu Amazon",
  "preCacheADMobile.expander.title": "Cài đặt Pre-Cache AD Mobile",
  "preCacheADMobile.expander.body.intro":
    "Tại Amazon, chúng tôi sử dụng một công cụ gọi là Precache AD để đồng bộ hóa thông tin tài khoản của bạn nằm trên mạng của Amazon với máy tính xách tay bạn đang sử dụng. Điều này đảm bảo rằng bạn có thể đăng nhập vào máy tính xách tay Amazon của mình bằng mật khẩu và tên người dùng Amazon mỗi ngày.",
  "preCacheADMobile.expander.body.step1":
    "Mở ứng dụng <bold>Self Service</bold> {logo} trên máy tính để bàn của bạn.",
  "preCacheADMobile.expander.body.step2": "Nhập tên người dùng Amazon của bạn.",
  "preCacheADMobile.expander.body.step3":
    "Nhập mã PIN khóa bảo mật của bạn và nhấn khóa bảo mật của bạn.",
  "preCacheADMobile.expander.body.step4":
    'Trong hộp tìm kiếm ở trên cùng bên trái, nhập "<bold>Pre-Cache</bold>”.',
  "preCacheADMobile.expander.body.step5":
    "Khi kết quả tìm kiếm tải, nhấp vào Bộ <bold>nhớ cache</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Nhập tên người dùng Amazon của bạn. Nhấp vào <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Nhập mật khẩu Amazon của bạn. Nhấp vào <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step8":
    "Tập lệnh sẽ chạy âm thầm trong 2-3 phút. Khi hoàn thành, một “Thành công! “pop-up xuất hiện. Nhấp vào <bold>OK</bold>.",
  "preCacheADMobile.image.step3.altText":
    "Ảnh chụp màn hình chế độ xem để đăng nhập bằng tên người dùng Amazon và mã PIN khóa bảo mật của bạn.",
  "preCacheADMobile.image.step4.altText":
    "Ảnh chụp màn hình của chế độ xem để tìm kiếm Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Ảnh chụp màn hình chế độ xem để nhập tên người dùng Amazon của bạn.",
  "preCacheADMobile.image.step7.altText":
    "Ảnh chụp màn hình chế độ xem để nhập mật khẩu Amazon của bạn.",
  "preCacheADMobile.image.step8.altText":
    "Ảnh chụp màn hình chế độ xem của ứng dụng Pre-Cache AD Mobile được cài đặt thành công.",
  "preCacheADMobile.logo.altText":
    "Logo của ứng dụng AD trước bộ nhớ cache có thể cài đặt thông qua dịch vụ tự phục vụ",
  "banner.openViaWelcome":
    "Vui lòng mở trang này bằng cách nhấp vào “Bắt đầu” trong trang chào mừng.",
  "allStepsCompleted.title": "👏 Công việc tuyệt vời!",
  "allStepsCompleted.mac.nextTo": "Tiếp theo, bạn sẽ cần:",
  "allStepsCompleted.win.nextTo":
    "Tiếp theo, bạn sẽ cần đăng nhập lần đầu tiên:",
  "allStepsCompleted.win.step1": "Bước 1: Kết nối với VPN",
  "allStepsCompleted.win.instructionSent":
    "Những hướng dẫn này cũng đã được gửi qua email cho bạn",
  "allStepsCompleted.mac.postLoginStep1":
    "Đăng xuất khỏi máy tính xách tay của bạn.",
  "allStepsCompleted.mac.postLoginStep2":
    "Kiểm tra email cá nhân của bạn để được hướng dẫn đăng nhập vào tài khoản Amazon của bạn.",
  "allStepsCompleted.win.step1.subStep1":
    'Khởi động lại máy tính xách tay này bằng cách nhấn phím Windows và chọn "<bold>Khởi động lại</bold>" từ menu "<bold>Bắt đầu</bold>.',
  "allStepsCompleted.win.step1.subStep2":
    "Nhấp vào biểu tượng Đăng nhập mạng để kết nối với Amazon VPN. Biểu tượng này trông giống như 2 màn hình máy tính. ",
  "allStepsCompleted.win.step1.subStep2.note":
    "<bold>Lưu ý:</bold> Kết nối với VPN từ màn hình đăng nhập chỉ cần thiết cho lần thiết lập tài khoản lần đầu tiên. Bạn sẽ không cần phải sử dụng VPN để đăng nhập sau lần đầu tiên.",
  "allStepsCompleted.windows.vpnIcon.altText":
    "Một biểu tượng với 2 màn hình máy tính được đánh dấu màu vàng.",
  "allStepsCompleted.win.step1.subStep3":
    "Ứng dụng khách Cisco AnyConnect sẽ xuất hiện. Nhấp vào <bold>Kết nối</bold>.",
  "allStepsCompleted.win.step1.subStep4":
    "Nhấp để mở menu thả xuống trong trường <bold>Nhóm</bold>. Chọn Đăng <bold>nhập trước</bold>.",
  "allStepsCompleted.win.step1.preLogin.altText":
    "Hình ảnh của Cisco VPN client với nhóm Pre-Login được chọn.",
  "allStepsCompleted.win.step1.subStep5":
    "Nhập tên người dùng Amazon của bạn vào <bold>trường</bold> Tên người dùng (tên người dùng của bạn là {username}).",
  "allStepsCompleted.win.step1.subStep6":
    "Trong trường Mật <bold>khẩu</bold>, nhập mã PIN khóa bảo mật của bạn và sau đó nhấn và giữ khóa bảo mật của bạn cho đến khi một chuỗi ký tự dài xuất hiện trong trường. Đừng bấm <bold>OK</bold> khi bạn nhấn và giữ khóa bảo mật, kết nối VPN sẽ tự động bắt đầu xác thực.",
  "allStepsCompleted.win.step1.securityKey.altText":
    "Hình ảnh một ngón tay chạm vào khóa bảo mật được cắm vào máy tính.",
  "allStepsCompleted.win.step1.subStep6.note":
    "Quan <bold>trọng:</bold> Khi kết nối với VPN, không sử dụng mật khẩu Amazon của bạn, ngay cả khi nó có nội dung ”Mật khẩu”. Sử dụng mã PIN của khóa bảo mật của bạn và sau đó áp dụng áp lực nhẹ trong 3-5 giây trên khóa bảo mật được cắm vào máy tính của bạn.",
  "allStepsCompleted.win.step1.subStep7":
    "Khi VPN được kết nối, một thông báo bảo mật sẽ xuất hiện. Bấm <bold>OK</bold> để loại bỏ hộp thoại. Bạn sẽ được đưa trở lại màn hình đăng nhập Windows. ",
  "allStepsCompleted.win.step2":
    "Bước 2: Đăng nhập vào tài khoản Amazon của bạn",
  "allStepsCompleted.win.step2.intro":
    "Đăng nhập vào tài khoản Amazon của bạn bằng tên người dùng và mật khẩu bạn đã tạo. ",
  "allStepsCompleted.win.step2.subStep1":
    "Trong trường Tên người dùng, nhập <bold>ANT\\{username}</bold>. Đảm bảo bao gồm “ANT\\” trước tên người dùng của bạn.",
  "allStepsCompleted.win.step2.subStep2":
    "Trong trường Mật khẩu, nhập mật <bold>khẩu Amazon</bold> của bạn và nhấn Enter.",
  "allStepsCompleted.win.step2.subStep2.note1":
    "Quan <bold>trọng: Sau</bold> khi đăng nhập vào tài khoản Amazon của bạn, bạn sẽ cần <bold>ngắt kết nối khỏi VPN</bold> để truy cập tài nguyên Amazon. Nếu bạn không ngắt kết nối, bạn sẽ không thể truy cập bất kỳ tài nguyên nào. Bạn có thể định vị Cisco 'AnyConnect từ “Menu Bắt đầu” để ngắt kết nối khỏi VPN.",
  "allStepsCompleted.win.step2.subStep2.note2":
    "Nếu cần VPN cho các dịch vụ khác dành riêng cho vai trò công việc của bạn, bạn sẽ cần sử dụng <bold>Orca-Corp-VPN. </bold>",
  "allStepsCompleted.win.step3":
    "Bước 3: Mở Hướng dẫn cài đặt máy tính xách tay",
  "allStepsCompleted.win.step3.intro":
    "Khi bạn đã đăng nhập, hãy truy cập {newHirePortalLink} để hoàn tất thiết lập máy tính xách tay của bạn cho các công cụ phổ biến được sử dụng bên trong Amazon như Chime, Slack và Outlook.",
  failedToRender:
    "Có gì đó không ổn. Vui lòng truy cập {link} và liên hệ với Bộ phận Hỗ trợ CNTT để được hỗ trợ thêm.",
  "error.serviceNotAvailable.text1":
    "Ứng dụng Amazon Day 1 hiện không khả dụng.",
  "error.serviceNotAvailable.step1.title": "Thay đổi kết nối mạng",
  "error.serviceNotAvailable.step1.windows": "Người dùng Windows",
  "error.serviceNotAvailable.step1.windows.subStep1":
    "Nhấn phím <bold>Windows</bold> và chọn biểu tượng <bold>Tài khoản</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep2":
    'Chọn <bold>"Đăng xuất"</bold>.',
  "error.serviceNotAvailable.step1.windows.subStep3":
    "Trên màn hình đăng nhập <bold>Windows</bold>, tìm biểu tượng Wi-Fi ở góc dưới bên phải.",
  "error.serviceNotAvailable.step1.windows.subStep4a":
    "Kết nối với một kết nối mạng khác.",
  "error.serviceNotAvailable.step1.windows.subStep4b":
    "Nếu bạn đang ở tại một tòa nhà của công ty Amazon và được kết nối với mạng Wi-Fi A2Z, hãy thử kết nối với Wi-Fi cho khách nếu nó có sẵn cho bạn.",
  "error.serviceNotAvailable.step1.windows.subStep4c":
    "<bold>Quan trọng</bold>: Bạn phải kết nối với Wi-Fi trước khi đăng nhập bằng tài khoản của mình.",
  "error.serviceNotAvailable.step1.windows.subStep5":
    "Đăng nhập với tư cách người dùng <bold>tokenadmin</bold> bằng thông tin đăng nhập được cung cấp trong email của bạn.",
  "error.serviceNotAvailable.step1.mac": "Người dùng macOS",
  "error.serviceNotAvailable.step1.mac.subStep1":
    "Chọn một mạng Wi-Fi khác từ lựa chọn Wi-Fi ở góc trên bên phải màn hình.",
  "error.serviceNotAvailable.step2.title": "Liên hệ với bộ phận hỗ trợ CNTT",
  "error.serviceNotAvailable.text2":
    "Nếu bạn là người mới thuê, hãy truy cập Hướng dẫn thiết lập CNTT tại firstaid.amazon-corp.com/vnho từ thiết bị Amazon hoặc thiết bị kết nối internet cá nhân của bạn để hoàn thành thiết lập máy tính xách tay. Bạn sẽ cần người quản lý tuyển dụng hoặc Hỗ trợ CNTT để hỗ trợ quá trình thiết lập. Nếu người quản lý tuyển dụng của bạn không có mặt, vui lòng sử dụng liên kết “Nhận hỗ trợ” nằm trên trang hướng dẫn sơ cứu.",
  "error.somethingWentWrong":
    "Có gì đó không ổn. Vui lòng thử lại hoặc {chatWithITSupportLink} để được hỗ trợ thêm.",
  "error.accountIssues":
    "Có vấn đề với tài khoản của bạn. Xin vui lòng {chatWithITSupportLink}.",
  "error.ineligibleError.text1":
    "Có vấn đề với tài khoản của bạn. Vui lòng đọc bên dưới trước khi liên hệ với bộ phận hỗ trợ.",
  "error.ineligibleError.text2":
    "Nếu bạn là người mới thuê, hãy truy cập Hướng dẫn thiết lập CNTT tại firstaid.amazon-corp.com/vnho từ thiết bị kết nối internet cá nhân của bạn để hoàn thành thiết lập máy tính xách tay.",
  "error.ineligibleError.text3":
    "Nếu bạn là người Amazon đang thiết lập máy tính xách tay thay thế của mình, hãy làm theo hướng dẫn được đi kèm với máy tính xách tay.",
  "error.ineligibleError.text4":
    "Nếu bạn vẫn cần trợ giúp, hãy truy cập firstaid.amazon-corp.com để được hỗ trợ.",
  "error.notVerifiableError.text1":
    "Chúng tôi rất tiếc, xác minh danh tính hiện không khả dụng.",
  "error.notVerifiableError.text2":
    "Để xác minh danh tính của bạn, vui lòng {chatWithITSupportLink}. Một trong những đại lý của chúng tôi sẽ sẵn lòng hỗ trợ bạn thông qua quá trình xác minh. Chúng tôi xin lỗi vì sự bất tiện này và đánh giá cao sự hiểu biết của bạn.",
  "error.beforeStartDate":
    "Ngày bắt đầu và ngày đầu tiên của bạn là {startDate}. Vui lòng đóng máy tính xách tay của bạn cho đến thời điểm đó. Cần giúp đỡ? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "Tôi đang gặp sự cố khi bắt đầu quy trình thuê mới Easy Onboarding và cần hỗ trợ với việc thiết lập máy tính xách tay trong ngày đầu tiên của tôi.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "Tôi cần hỗ trợ xác minh danh tính của mình để gia nhập nhân viên mới thông qua cuộc gọi video Chime.",
  "itHelpMsg.verificationIssueAndAssist":
    "Tôi đang gặp vấn đề với việc xác minh danh tính của mình thông qua quy trình tuyển dụng mới Easy Onboarding và cần hỗ trợ với việc thiết lập máy tính xách tay trong ngày đầu tiên của tôi.",
  "itHelpMsg.verificationIssue":
    "Tôi đang gặp sự cố với việc thiết lập máy tính xách tay của mình như một phần của quy trình tuyển dụng mới và cần trợ giúp trong việc xác minh danh tính của mình.",
  "itHelpMsg.verificationAssist":
    "Tôi cần hỗ trợ xác minh danh tính của mình như một phần của quy trình tuyển dụng mới Easy Onboarding và cần trợ giúp về việc giới thiệu.",
  "itHelpMsg.settingUpLaptop":
    "Tôi đang gặp vấn đề với việc thiết lập máy tính xách tay của mình như một phần của quy trình tuyển dụng mới.",
  "itHelpMsg.usernameIssue":
    "Tôi đang gặp sự cố với tên người dùng của mình như một phần của quy trình tuyển dụng mới của mình.",
  "itHelpMsg.midwayAssist":
    "Tôi cần hỗ trợ về Midway và quy trình đăng ký khóa bảo mật như một phần trong quá trình tuyển dụng mới của tôi.",
  "itHelpMsg.midwayIssue":
    "Tôi đang gặp vấn đề với quy trình đăng ký khóa Midway như một phần của quá trình tuyển dụng mới của tôi. Tôi đã yêu cầu gửi mã PIN tạm thời.",
  "itHelpMsg.vpnIssue":
    "Tôi đang gặp sự cố liên quan đến kết nối VPN như một phần trong quá trình tuyển dụng mới của tôi.",
  "itHelpMsg.passwordIssue":
    "Tôi đang gặp sự cố với công cụ Đặt lại mật khẩu như một phần trong quá trình tuyển dụng mới của tôi.",
  "itHelpMsg.preCacheADIssue":
    "Tôi đang gặp sự cố với bước quảng cáo trước bộ nhớ cache trong quá trình giới thiệu thuê mới cho thiết bị Mac của mình.",
  "mediacheck.failure.permission":
    "Để cho phép truy cập, hãy nhấp vào biểu tượng máy ảnh {icon} trong thanh địa chỉ của trình duyệt của bạn. Bạn có thể cần tải lại trang để áp dụng các thay đổi.",
  "mediacheck.failure.nodevice":
    "Không có thiết bị camera nào được tìm thấy. Vui lòng đảm bảo máy ảnh được cắm vào và hoạt động sau đó tải lại trang.",
  "mediacheck.failure.other":
    "Một sự cố đã ngăn trang phát hiện ra nếu bạn đã cài đặt máy ảnh có thể sử dụng được.",
  "mediacheck.failure.title": "Camera bị chặn",
  "mediacheck.failure.cameraalt": "Biểu tượng máy ảnh",
  "cameraOn.text": "Bắt đầu ghi âm, sau đó {text}",
  "getSupport.header": "Nhận hỗ trợ",
  "getSupport.footer": "Vẫn cần hỗ trợ?",
  "getSupport.chatSupport.clickToStart": "Bắt đầu trò chuyện",
  "getSupport.chatSupport.clickToStartNew": "Bắt đầu trò chuyện mới",
  "getSupport.faq.password.header": "Câu hỏi thường gặp về mật khẩu Amazon",
  "getSupport.faq.password.section1.title": "Khi nào mật khẩu của tôi hết hạn?",
  "getSupport.faq.password.section1.contents": "",
  "getSupport.faq.vpn.header": "CÂU HỎI THƯỜNG GẶP VỀ VPN",
  "getSupport.faq.vpn.section1.title": "Lỗi cấu hình CSD của Cisco",
  "getSupport.faq.vpn.section1.contents": "Chỗ giữ chỗ",
  "getSupport.faq.general.header": "Câu hỏi thường gặp chung",
  "getSupport.faq.general.section1.title":
    "Tôi phải làm gì nếu tôi chưa nhận được mã xác minh?",
  "getSupport.faq.general.section1.contents": "mã xác minh",
  "getSupport.faq.midway.header": "Midway Câu hỏi thường gặp",
  "getSupport.faq.midway.section1.title": "Khóa bảo mật là gì?",
  "getSupport.faq.midway.section1.contents":
    "Khóa bảo mật là một thanh USB vật lý sẽ được lắp vào máy tính của bạn. Chúng tôi đã gửi cho bạn một gói với hai khóa bảo mật USB. Lưu khóa dự phòng thứ hai ở nơi an toàn, trong trường hợp khóa chính của bạn bị mất hoặc bị hỏng.",
  "getSupport.faq.username.header":
    "Câu hỏi thường gặp về tên người dùng Amazon",
  "getSupport.faq.username.section1.title": "Tên người dùng Amazon là gì?",
  "getSupport.faq.username.section1.contents":
    "Tại Amazon, bạn sẽ sử dụng tên người dùng của mình để đăng nhập vào máy tính của mình và truy cập các công cụ và tài nguyên của Amazon. Tên người dùng của bạn là phần trước ký hiệu @ trong địa chỉ email Amazon của bạn.",
  "getSupport.idv.title":
    "Trò chuyện với Bộ phận Hỗ trợ CNTT để xác minh danh tính của bạn",
  "getSupport.idv.body.content1":
    "Nếu bạn đang gặp vấn đề với quy trình trên hoặc có mối quan tâm khác, vui lòng liên hệ với nhóm hỗ trợ CNTT.",
  "getSupport.idv.body.content2":
    "Nhóm hỗ trợ CNTT có thể giúp bạn xác minh danh tính của bạn qua cuộc gọi video.",
  "arialabels.clickToCloseChat": "Nhấp để đóng bảng hỗ trợ trò chuyện",
  "arialabels.clickToStartChat": "Nhấp để bắt đầu hỗ trợ trò chuyện",
  "arialabels.clickToOpenChat": "Nhấp để mở bảng hỗ trợ trò chuyện",
  "arialabels.clickToCloseGetSupport": "Nhấp để đóng bảng nhận hỗ trợ",
  "arialabels.clickToOpenGetSupport": "Nhấp để mở bảng nhận hỗ trợ",
};

export default vietnamese;
