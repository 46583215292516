import { RegionHealthCheckResponseSchema } from "@amzn/client-workbench-api-model";
import { Auth } from "@aws-amplify/auth";
import { Amplify } from "@aws-amplify/core";
import { trackStructEvent } from "@snowplow/browser-tracker";
import axios, { AxiosResponse } from "axios";
import { useContext } from "react";
import { useQuery, UseQueryResult } from "react-query";

import { EmbeddedMetric, Logger } from "@/apis/Logger";
import { getRetryDelayMs, shouldRetry } from "@/helpers/react-query-helper";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import { Day1EventAction, Day1EventCategory } from "@/types/snowplow-events";

import { getAmplifyConfig, getStageConfig } from "../amplifyConfig";
import { ActivatedRegions, DEFAULT_REGION } from "../amplifyStageConfig";

/**
 * Emit a remote logging message when an API active region transition occurs
 * e.g. us-west-2 --> us-east-1 is a transition to a failover region
 */
const logActiveRegionTransition = (
  currentActiveRegion: ActivatedRegions | undefined,
  nextActiveRegion: ActivatedRegions
): void => {
  const isFailingOver =
    currentActiveRegion &&
    currentActiveRegion !== nextActiveRegion &&
    nextActiveRegion !== DEFAULT_REGION;
  const isRecoveringBack =
    currentActiveRegion &&
    currentActiveRegion !== nextActiveRegion &&
    nextActiveRegion === DEFAULT_REGION;
  const isInitializingToDefault =
    !currentActiveRegion && nextActiveRegion === DEFAULT_REGION;
  const isInitializingToFailover =
    !currentActiveRegion && nextActiveRegion !== DEFAULT_REGION;
  if (isFailingOver) {
    void Logger.warn(
      `Failing over API active region from ${currentActiveRegion} to ${nextActiveRegion}`
    );
  } else if (isRecoveringBack) {
    void Logger.info(
      `Default API active region is up, recovering back from ${currentActiveRegion} to ${nextActiveRegion}`
    );
  } else if (isInitializingToDefault) {
    void Logger.info(`Initializing API active region to: ${nextActiveRegion}`);
  } else if (isInitializingToFailover) {
    void Logger.warn(`Initializing API active region to: ${nextActiveRegion}`);
  }
};

export interface AmplifyInitProps {
  /**
   * Execute this function if Auth.currentAuthenticatedUser() returns a logged in user indicating there was no login redirect.
   */
  loggedInCallback?(): void;
  /**
   * Execute this callback if Auth.currentAuthenticatedUser should redirect. Executes asynchronously before redirect.
   */
  loginRedirectedCallback?(): void;
}

/**
 * Custom hook to get the healthy region and configure Amplify based on the available regions.
 *
 * @param loggedInCallback Callback function to invoke when user is logged in successfully.
 * @param loginRedirectedCallback Callback function to invoke when the user has failed to login.
 */
export const useLoadRegion = ({
  loggedInCallback,
  loginRedirectedCallback,
}: AmplifyInitProps): UseQueryResult => {
  const { state, dispatch } = useContext(AppContext);

  /**
   * Get the healthy region.
   */
  const loadRegion = async (): Promise<
    AxiosResponse<RegionHealthCheckResponseSchema>
  > => {
    const healthCheckUrl = `${
      getStageConfig()[DEFAULT_REGION].UNREGIONALIZED_API_ENDPOINT
    }/healthcheck`;
    // Call unregionalized endpoint since it should automatically route to an endpoint. It will return the region to use.
    // Call via axios.get since Cognito API interface isn't configured until after we know the region to use.
    return await axios.get(healthCheckUrl);
  };

  return useQuery("loadRegion", loadRegion, {
    onSuccess: (response: AxiosResponse<RegionHealthCheckResponseSchema>) => {
      const currentActiveRegion = state.activeRegion;
      const nextActiveRegion = response.data.region as ActivatedRegions;
      // Get regionalized configuration.
      const config = getAmplifyConfig(nextActiveRegion);

      logActiveRegionTransition(currentActiveRegion, nextActiveRegion);

      dispatch({
        type: ActionType.SET_API_ACTIVE_REGION,
        activeRegion: nextActiveRegion,
      });
      Amplify.configure(config);

      const { domain, redirectSignIn, responseType } = config.Auth.oauth;

      const clientId = config.Auth.userPoolWebClientId;

      const cognitoLoginUrl =
        "https://" +
        domain +
        "/authorize?redirect_uri=" +
        redirectSignIn +
        "&response_type=" +
        responseType +
        "&client_id=" +
        clientId;

      Auth.currentAuthenticatedUser()
        .then(() => {
          trackStructEvent({
            category: Day1EventCategory.PageLevel,
            action: Day1EventAction.WizardLoad,
          });
          dispatch({
            type: ActionType.SET_AMPLIFY_CONFIGURED,
            amplifyConfigured: true,
          });
          loggedInCallback?.();
          return;
        })
        .catch((err: unknown) => {
          Logger.errorErrorLike(
            "Authentication error - failed to retrieve current authenticated user",
            err
          );
          loginRedirectedCallback?.();
          window.location.href = cognitoLoginUrl;
        });
    },
    onError: (errorLike: unknown) => {
      Logger.publishFatalMetric(
        new EmbeddedMetric("HealthcheckFailure", "Count", 1),
        "Service error - failed to initialize API active region",
        errorLike
      );
    },
    retry: (failureCount, err) => shouldRetry(failureCount, err),
    retryDelay: (retryCount) => getRetryDelayMs(retryCount),
  });
};
