import { languageObject } from "./locales";

/**
 * This locale file will be used by the application to change the language to French
 * based on user selection.
 **/

const french: languageObject = {
  "page.title": "Premier jour d'Amazon",
  "button.next": "Suivant",
  "button.back": "Retour",
  "button.done": "Terminé",
  "text.then": "Puis",
  "text.chatWithITSupport": "Discutez avec le support informatique",
  "videoGuidance.title": "Guidage vidéo",
  "videoGuidance.example": "Exemple",
  "videoGuidance.button.altText": "Afficher les conseils vidéo",
  "icon.title": "Terminé",
  "error.notSupportVideoTag":
    "Votre navigateur ne prend pas en charge le tag vidéo.",
  "welcome.expander.title": "Bienvenue sur Amazon 🎉",
  "welcome.expander.body.greeting":
    "Nous sommes contents que tu sois là. Nous vous guiderons dans la configuration de votre nouvel ordinateur portable.",
  "welcome.expander.body.requirements.start": "Ce dont vous aurez besoin:",
  "welcome.expander.body.requirements.list1":
    "Votre clé de sécurité nous vous avons envoyée par la poste.",
  "welcome.expander.body.requirements.list2": "Une connexion Wi-Fi.",
  "welcome.expander.body.requirements.list3":
    "Votre nom d'utilisateur et votre mot de passe sur le portail de pré-embarquement Embark.",
  "welcome.expander.body.requirements.list4":
    "Accédez à votre adresse e-mail personnelle pour recevoir des informations supplémentaires tout au long du processus.",
  "welcome.expander.body.firstStep.start":
    "Première étape: Connectez-vous au Wi-Fi",
  "welcome.expander.body.firstStep.list1":
    "Cliquez sur l'icône Wi-Fi dans la barre des tâches.",
  "welcome.expander.body.firstStep.list2":
    "Sélectionnez votre connexion Wi-Fi.",
  "welcome.expander.body.firstStep.list3":
    'Entrez votre mot de passe Wi-Fi, puis cliquez sur "Connecter".',
  "welcome.button.getStarted": "Mise en route",
  "identityCheck.expander.title": "Vérification de l'identité",
  "identityCheck.expander.body.header": "Enregistrer des vidéos",
  "identityCheck.expander.body.intro":
    "Enregistrez des vidéos en tenant vos documents d'identité à côté de votre visage et effectuez une série de gestes de la main.",
  "identityCheck.expander.body.recordInfo.title":
    'Informations sur "Enregistrer des vidéos"',
  "identityCheck.expander.body.recordInfo.body": `Avant d'émettre des informations d'accès au système Amazon, Amazon doit vérifier votre identité. Pour ce faire, Amazon utilise un système de vérification d'identité ("Système"). L'utilisation du système est totalement facultative et volontaire. Le système fonctionne comme suit: téléchargez la photo d'une pièce d'identité avec photo émise par le gouvernement ("Govt. Photo d'identité") et enregistrez une vidéo de vous avec votre pièce d'identité avec photo. Le nom figurant sur la pièce d'identité avec photo doit être sensiblement similaire au nom qu'Amazon a enregistré pour vous. L'un de nos ingénieurs d'assistance vérifiera votre nom et votre photo sur votre pièce d'identité avec photo en tenant compte de votre visage dans l'enregistrement soumis.`,
  "identityCheck.expander.body.whatIsCollected.title":
    "Quelles informations sont collectées",
  "identityCheck.expander.body.whatIsCollected.intro":
    "Pour vérifier votre identité, Amazon collectera les informations personnelles suivantes auprès de vous:",
  "identityCheck.expander.body.whatIsCollected.item1":
    "Photo (s) de votre pièce d'identité gouvernementale avec photo",
  "identityCheck.expander.body.whatIsCollected.item2": "Un selfie vidéo de toi",
  "identityCheck.expander.body.whatIsCollected.purpose":
    "Vos informations personnelles ne seront utilisées qu'à des fins d'identification et de vérification. Amazon détruira toutes les informations personnelles dans les 7 jours suivant la fin du processus de vérification, à moins que la loi ne l'oblige à conserver vos informations personnelles pendant une période plus longue. Pour plus d'informations, veuillez consulter la Politique de confidentialité d'Amazon HR, fournie dans le cadre de votre package d'intégration, ou via le portail Amazon interne, AtoZ, une fois le processus de configuration de l'ordinateur portable terminé.",
  "identityCheck.expander.body.light.instruction":
    "Une fois que vous aurez lancé le défi des gestes d'identification, vous aurez <b>10 minutes</b> pour enregistrer et soumettre une seule <b>vidéo de 10 secondes</b> de vous-même effectuant les gestes.",
  "identityCheck.expander.body.helpLinkPre":
    "Impossible de terminer les actions? Veuillez {chatWithITSupportLink} pour vérifier votre identité lors d'un appel vidéo Chime.",
  "identityCheck.expander.body.helpLinkPost":
    "pour vérifier votre identité lors d'un appel vidéo Chime.",
  "identityCheck.challenges.title": "Actions de défi",
  "identityCheck.challenges.completed":
    "Vos actions de défi ont été enregistrées.",
  "identityCheck.challenges.instructions.completeByTime":
    "Veuillez terminer ce défi avant le {time}",
  "identityCheck.challenges.instructions":
    "Suivez les instructions pour enregistrer une seule vidéo de 10 secondes en effectuant ces deux gestes:",
  "identityCheck.challenges.expired":
    "<b>Votre délai est expiré. </b>S'il vous plaît {chatWithIt}. Il vous sera demandé de participer à un appel vidéo et de montrer votre identifiant à un ingénieur du support informatique.",
  "identityCheck.challenges.instructions.unhideButton": "Défi gestuel Lancer",
  "identityCheck.challenges.forSeconds": "pendant {second} secondes.",
  "identityCheck.button.recording": "Enregistrement: {countDownTimer}",
  "identityCheck.button.startRecord": "Commencer l'enregistrement",
  "identityCheck.button.enableCamera": "Activer la caméra",
  "identityCheck.button.retry": "Réessayez",
  "identityCheck.do.title": "À faire",
  "identityCheck.do.hint1": "Regardez directement dans la caméra.",
  "identityCheck.do.hint2": "Utilisez une exposition à la lumière appropriée.",
  "identityCheck.dont.title": "À ne pas faire",
  "identityCheck.dont.hint1":
    "Couvrez votre carte d'identité avec vos doigts ou votre main.",
  "identityCheck.dont.hint2": "Couvrez-vous le visage avec un masque.",
  "identityCheck.dont.hint3":
    "Portez des lunettes de soleil (les lunettes correctrices sont acceptables).",
  "identityCheck.dont.hint4":
    "Portez des chapeaux ou des casquettes (un couvre-chef religieux qui ne couvre pas votre visage est acceptable).",
  "identityCheck.full.steps.recordPrimaryId":
    "Enregistrer une vidéo contenant identifiant {requiredDocuments, plural, one {gouvernemental} other {principal}}",
  "identityCheck.full.steps.recordSecondaryId":
    "Enregistrer une vidéo contenant un identifiant secondaire",
  "identityCheck.full.steps.secondaryIdGovIssued":
    "Votre carte d'identité secondaire est-elle délivrée par le gouvernement?",
  "identityCheck.secondaryIdGovIssued.instruction":
    "La pièce d'identité secondaire peut être un document non gouvernemental avec votre nom et votre photo. Apportez-le le premier jour.",
  "identityCheck.full.steps.recordChallengeActions":
    "Enregistrez les actions du défi",
  "identityCheck.full.primaryId.expandAltText":
    "Cliquez pour télécharger identifiant {requiredDocuments, plural, one {gouvernemental} other {principal}}",
  "identityCheck.full.secondaryId.expandAltText":
    "Cliquez pour télécharger l'identifiant secondaire",
  "identityCheck.full.challengeActions.expandAltText":
    "Cliquez pour télécharger les actions du défi",
  "identityCheck.primaryId.instruction":
    "Enregistrez une vidéo de 15 secondes avec votre identifiant physique identifiant {requiredDocuments, plural, one {gouvernemental} other {principal}} à côté de votre visage, puis devant la caméra. Cette pièce d'identité doit être une pièce d'identité officielle non expirée figurant sur cette liste de documents acceptés. Il doit indiquer votre nom, votre date de naissance et votre photo.",
  "identityCheck.secondaryId.instruction":
    "Enregistrez une vidéo de 15 secondes avec votre carte d'identité secondaire physique à côté de votre visage, puis devant la caméra. La pièce d'identité secondaire peut être une autre pièce d'identité émise par le gouvernement (par exemple, un permis de conduire) ou une pièce d'identité avec photo non émise par le gouvernement (par exemple, une carte Costco ou une carte d'étudiant).",
  "identityCheck.primaryIdCountry.select.label":
    "Quel pays a délivré votre carte d'identité?",
  "identityCheck.primaryId.select.label": "Type d'identifiant",
  "identityCheck.primaryId.select.placeholder":
    "Sélectionnez le type d'identification",
  "identityCheck.primaryId.country.select.placeholder":
    "Sélectionnez le pays émetteur de la carte d'identité",
  "identityCheck.primaryId.video.instruction":
    "Placez votre identifiant {requiredDocuments, plural, one {gouvernemental} other {principal}} à côté de votre visage, puis déplacez-le vers la caméra.",
  "identityCheck.primaryId.video.instruction2":
    "Tenez votre ID à 3 pouces de l'appareil photo jusqu'à ce que la mise au point soit nette.",
  "identityCheck.primaryId.completed":
    "Votre identifiant principal et votre visage ont été enregistrés.",
  "identityCheck.governmentId.completed":
    "Votre carte d'identité officielle et votre visage ont été enregistrés.",
  "identityCheck.secondaryId.completed":
    "Votre identifiant secondaire et votre visage ont été enregistrés.",
  "identityCheck.primary.title":
    "Enregistrez une vidéo contenant votre identifiant {requiredDocuments, plural, one {gouvernemental} other {principal}}",
  "identityCheck.secondaryID.governmentID.radio1":
    "Carte d'identité émise par le gouvernement",
  "identityCheck.secondaryID.governmentID.radio2":
    "Carte d'identité non émise par le gouvernement",
  "identityCheck.secondaryId.title":
    "Enregistrez une vidéo contenant votre identifiant secondaire",
  "identityCheck.secondaryId.video.instruction":
    "Placez votre identifiant secondaire à côté de votre visage, puis déplacez-le vers la caméra.",
  "identityCheck.secondaryId.video.instruction2":
    "Tenez votre ID à 3 pouces de l'appareil photo jusqu'à ce que la mise au point soit nette.",
  "identityCheck.button.goBackAltText": "Retournez",
  "identityCheck.button.submitReview": "Soumettre pour révision",
  "identityCheck.button.loading": "Soumettre...",
  "identityCheck.button.tooltip.disallowResubmit":
    "L'enregistrement vidéo a déjà été envoyé.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Sélectionnez un type d'identifiant {requiredDocuments, plural, one {gouvernemental} other {primary}} dans la liste déroulante de sélection.",
  "identityCheck.button.tooltip.missingSecondaryIdGovIssued":
    "Veuillez indiquer si votre type d'identification secondaire est émis par le gouvernement à l'aide des boutons radio.",
  "identityCheck.errors.unableToVerify":
    "Nous n'avons pas pu vérifier votre identité. S'il vous plaît {link}. Il vous sera demandé de participer à un appel vidéo et de montrer votre identifiant à un agent du support informatique Amazon. Un autre chemin vous sera proposé pour configurer votre ordinateur portable.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>Nous n'avons pas pu vérifier votre identité à l'aide de la vérification manuelle de l'identité. </b>Essayez plutôt d'utiliser la <a>vérification d'identité biométrique</a>.",
  "identityCheck.errors.failedToSubmit":
    "Nous n'avons pas pu soumettre votre vidéo. Veuillez réessayer dans quelques secondes ou {link} pour obtenir de l'aide.",
  "identityCheck.errors.chatWithIT": "Discutez avec le support informatique",
  "identityCheck.pending.pleaseWait":
    "Pendant la vérification de vos vidéos, veuillez ne pas quitter cette page.",
  "identityCheck.pending.estimatedWaitTime":
    "Le temps d'attente typique pour la vérification d'identité est compris entre {minTimeMin} et {maxTimeMin} minutes.",
  "identityCheck.verified.successMsg": "Nous avons vérifié votre identité.",
  "identityCheck.ivv.body.intro":
    "La configuration de votre nom d'utilisateur et de votre mot de passe Amazon nécessite un niveau de sécurité supplémentaire pour prouver que vous êtes bien vous et non quelqu'un qui se fait passer pour vous.",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Veuillez cocher les deux cases ci-dessus et cliquer sur {agree} pour confirmer que vous avez lu et compris, ou cliquez sur Annuler pour vérifier votre identité à l'aide d'une procédure manuelle.",
  "identityCheck.ivv.failedBody":
    "<b>Nous n'avons pas pu vérifier votre identité à l'aide d'un scan automatique du visage. </b><a>Enregistrez des vidéos en</a> tenant vos documents d'identité à côté de votre visage et effectuez une série de gestes de la main.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>Nous n'avons pas pu vérifier votre identité à l'aide d'un scan automatique du visage. </b>{chatWithITSupportLink} pour obtenir de l'aide supplémentaire.",
  "identityCheck.ivv.inFlight":
    "Vous avez déjà effectué une vérification en vol et vous ne pouvez pas en démarrer une nouvelle. Veuillez actualiser la page régulièrement pour vérifier le statut.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Finalisation de votre contrôle d'identité</b>",
  "identityCheck.ivv.pendingBackendAgreement": `Cela peut prendre quelques minutes. Si vous attendez plus de 10 minutes, utilisez le bouton "Obtenir de l'aide" pour obtenir de l'aide.`,
  "identityCheck.ivv.scanAbortHeader":
    "Vous rencontrez des problèmes avec votre scan automatique?",
  "identityCheck.ivv.scanAbortBody":
    "Pas de problème. Vous pouvez utiliser notre <openModal>autre processus de vérification</openModal> à la place.",
  "identityCheck.ivv.scanAbortModalHeader":
    "Êtes-vous sûr de vouloir utiliser un autre processus de vérification?",
  "identityCheck.ivv.scanAbortModalBody":
    " <p>Si vous avez décidé d'ignorer le scan biométrique automatique. Juste pour vous informer, vous ne pourrez plus revenir en arrière et utiliser l'option de numérisation du visage par la suite. </p><p>L'autre processus de vérification peut prendre un peu plus de temps, mais notre équipe vous expliquera tout ce dont vous avez besoin pour configurer votre ordinateur portable le premier jour. </p>",
  "identityCheck.ivv.scanAbortModalCancel": "Annuler",
  "identityCheck.ivv.scanAbortModalConfirm": "Confirmer",
  "identityCheck.pathchooser.title":
    "Sélectionnez votre préférence de vérification parmi l'une des méthodes suivantes.",
  "identityCheck.pathchooser.card.recommended": "Recommandé",
  "identityCheck.pathchooser.card.estCompletionAutomated":
    "Temps nécessaire pour terminer: <b>inférieur à {count} {unit}</b>",
  "identityCheck.pathchooser.card.estCompletionManual":
    "Temps nécessaire pour terminer: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "minute",
  "identityCheck.pathchooser.card.unitMinutes": "minutes",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Type de vérification: <b>Automatisé</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Type de vérification: <b>Manuel</b>",
  "identityCheck.pathchooser.card.oneId":
    "Pièce d'identité requise: <b>1 pièce d'identité émise par le gouvernement</b>",
  "identityCheck.pathchooser.card.twoIdsAutomated":
    "Pièces d'identité requises: <b>2 pièces d'identité émises par le gouvernement</b>",
  "identityCheck.pathchooser.card.twoIdsManual":
    "Pièces d'identité requises: <b>1 pièce d'identité émise par le gouvernement, 1 carte d'identité secondaire</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "Un type de vérification doit être sélectionné",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "Une vérification est déjà en cours",
  "identityCheck.pathchooser.card.ivv.title": "Scan automatique du visage",
  "identityCheck.pathchooser.card.ivv.body":
    "Scannez votre visage le premier jour et nous le comparerons à la photo figurant sur votre pièce d'identité officielle. Votre identité sera confirmée immédiatement et vous serez prêt à partir.",
  "identityCheck.ivv.countDownTimer": ` restant jusqu'à ce que le processus de vérification alternatif soit lancé ("évaluation vérifiée par des humains")`,
  "identityCheck.pathchooser.card.manual.title": "Enregistrer des vidéos",
  "identityCheck.pathchooser.card.manual.body":
    "Enregistrez des vidéos en tenant vos documents d'identité à côté de votre visage et effectuez une série de gestes de la main. Envoyez ces vidéos à un vérificateur humain Amazon pour qu'il les examine.",
  "identityCheck.pathchooser.card.contactsupport.title": "Contacter le support",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Discutez avec un ingénieur du support informatique en temps réel et vérifiez votre identité par le biais d'une visioconférence sur Amazon Chime, qui vous guidera tout au long du processus de configuration manuelle.",
  "identityCheck.pathchooser.additionalConcerns":
    "Si vous rencontrez des problèmes ou si vous avez d'autres préoccupations, veuillez contacter notre équipe d'assistance informatique.",
  "identityCheck.pathchooser.additionalConcerns.contactIt":
    "Ils peuvent vous aider par le biais d'un appel vidéo sécurisé pour vérifier votre identité. {chatWithITSupportLink}.",
  "identityCheck.pathchooser.additionalConcerns.chimeVerificationType":
    "Type de vérification: <b>visioconférence téléphonique</b>",
  "identityCheck.pathchooser.submitBtn": "Envoyer",
  "identityCheck.pathchooser.continueBtn": "Continuer",
  "amazonUsername.expander.title": "Nom d'utilisateur Amazon",
  "amazonUsername.expander.body.info":
    "Sur Amazon, vous utiliserez votre nom d'utilisateur pour vous connecter à votre ordinateur et accéder aux outils et ressources Amazon. Il s'agit de la partie de votre adresse e-mail Amazon qui précède le @. Votre adresse e-mail est {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Votre nom d'utilisateur Amazon est: {username}",
  "registerSecurityKey.expander.title": "Enregistrer une clé de sécurité",
  "registerSecurityKey.expander.body.intro":
    "Chez Amazon, nous utilisons votre clé de sécurité comme étape de l'authentification multifactorielle afin de nous assurer qu'il s'agit bien de vous. Vous serez invité à appuyer sur votre clé de sécurité pour accéder au réseau Amazon tous les jours. Chaque fois que vous appuyez sur votre touche, une chaîne de caractères aléatoire est générée.",
  "registerSecurityKey.expander.body.securityKeyTitle":
    "Qu'est-ce qu'une clé de sécurité?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    "Appareil en plastique utilisé pour accéder en toute sécurité aux ressources Amazon. Date à laquelle votre ordinateur portable Amazon vous a été expédié, nous vous avons également envoyé par la poste deux clés de sécurité USB. La deuxième clé est votre clé de sauvegarde. Conservez-la dans un endroit sûr au cas où votre clé principale serait perdue ou endommagée.",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "Chez Amazon, nous utilisons votre clé de sécurité comme étape de l'authentification multifactorielle afin de nous assurer que vous êtes bien vous. Pour utiliser chaque jour de nombreux outils et ressources internes d'Amazon, vous devez saisir votre mot de passe ainsi que votre clé de sécurité pour accéder au réseau Amazon. Choisissez un code PIN de clé de sécurité facile à mémoriser. Vous le saisirez tous les jours lorsque vous vous connecterez aux services Amazon internes et au VPN. Il n'expire pas.",
  "registerSecurityKey.expander.body.midwayTitle": "Qu'est-ce que Midway?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway est le service qui gère les clés de sécurité et les codes PIN des clés de sécurité.",
  "registerSecurityKey.expander.body.howTitle":
    "Comment enregistrer ma clé de sécurité?",
  "registerSecurityKey.expander.body.step1":
    "Envoyez un code PIN temporaire à votre adresse e-mail personnelle pour accéder à Midway. Ouvrez votre adresse e-mail personnelle pour accéder à ce code PIN temporaire.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "Nous avons envoyé un code de vérification sur votre adresse e-mail personnelle. Votre code PIN expirera dans 4 heures. Vous n'avez pas reçu de code? {resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Une fois que vous avez le code PIN temporaire, enregistrez votre clé sur Midway. Il vous sera demandé de saisir votre nom d'utilisateur Amazon et votre code PIN temporaire.",
  "registerSecurityKey.expander.body.step3":
    "Branchez l'une de vos clés de sécurité sur un port USB de cet ordinateur portable. Vous devrez peut-être utiliser l’un des adaptateurs que vous avez reçus. (Vous n’avez besoin d’enregistrer qu’une seule de vos clés de sécurité. La deuxième clé est une sauvegarde.) Insérez soigneusement cette clé avec le trou d’épingle tourné vers l’extérieur. Une lumière verte s'allume lorsque cette clé de sécurité est correctement insérée.",
  "registerSecurityKey.expander.body.step4":
    "Sur la page de connexion, saisissez les informations suivantes:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Nom d'utilisateur Amazon:</bold> nom d'utilisateur affiché à l'étape précédente.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>Code PIN (ou code PIN de la clé de sécurité):</bold> le code PIN temporaire envoyé à votre adresse e-mail personnelle.",
  "registerSecurityKey.expander.body.step5":
    "Après avoir saisi votre nom d'utilisateur et le code PIN de votre clé de sécurité temporaire, cliquez sur <bold>Se connecter</bold>:",
  "registerSecurityKey.expander.body.step6": `Vous verrez un message indiquant "Bienvenue {username}!"`,
  "registerSecurityKey.expander.body.step7":
    "Cliquez sur le lien du <bold>portail d'enregistrement des clés de sécurité</bold> dans le message de bienvenue pour continuer.",
  "registerSecurityKey.expander.body.step8": `Sur la page intitulée "Bienvenue dans le tableau de bord des clés de sécurité", cliquez sur <bold>Enregistrer la clé de sécurité</bold>.`,
  "registerSecurityKey.expander.body.step9": `Sur la page intitulée "Commencez à utiliser votre clé de sécurité", cliquez sur <bold>Commencer l'enregistrement</bold>. Si deux options s'affichent ici, choisissez Commencer l'enregistrement des clés de sécurité matérielles.`,
  "registerSecurityKey.expander.body.step10":
    "Sélectionnez l'image qui correspond à la clé de sécurité que vous avez reçue.",
  "registerSecurityKey.expander.body.step11": `Ensuite, saisissez le mot de passe à usage unique (OTP) généré par votre clé de sécurité. Cliquez dans la zone de texte vide qui indique "Votre OTP apparaîtra ici, ne tapez pas dans cette zone".`,
  "registerSecurityKey.expander.body.step12.1":
    "Appuyez sur la clé de sécurité branchée sur votre ordinateur. Appliquez une légère pression pendant 3 à 5 secondes jusqu'à ce qu'une chaîne de texte apparaisse dans la zone de texte vide. C'est l'OTP. Selon le type de clé de sécurité dont vous disposez, vous devrez appliquer une légère pression sur le <bold>côté ou sur</bold> <bold>le dessus</bold>.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>Si vous appuyez sur votre clé de sécurité mais que celle-ci ne saisit aucun OTP</bold>, retirez votre doigt de la clé de sécurité, cliquez sur la zone de texte, puis touchez à nouveau la clé de sécurité.",
  "registerSecurityKey.expander.body.step13":
    "Saisissez le code PIN de la clé de sécurité permanente que vous souhaitez utiliser dans les champs <bold>Nouveau code PIN</bold> et <bold>Confirmer le nouveau code PIN</bold>, puis cliquez sur <bold>Continuer</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Saisissez le code PIN de la clé de sécurité permanente que vous souhaitez utiliser dans les champs <bold>Nouveau code PIN</bold> et <bold>Confirmer le nouveau code PIN</bold>, puis cliquez sur <bold>Continuer</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Important</bold>: Choisissez un code PIN de clé de sécurité facile à mémoriser. Vous le saisirez tous les jours lorsque vous vous connecterez aux services Amazon internes et au VPN. Il n'expire pas.",
  "registerSecurityKey.expander.body.step14":
    'Sur la page qui indique "Veuillez lire toutes les instructions ci-dessous..." appuyez sur <bold>Continuer</bold>.',
  "registerSecurityKey.expander.body.step15":
    "Sur la page suivante, appuyez de nouveau sur votre clé de sécurité et maintenez-la enfoncée. Il est possible qu'une fenêtre contextuelle s'ouvre, vous demandant des informations sur votre clé de sécurité. Cliquez sur <bold>Autoriser</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "Ensuite, vous verrez l'un des deux écrans suivants:",
  "registerSecurityKey.expander.body.screenPossibilityList.1": `Si le message "<bold>Vous avez correctement enregistré votre clé de sécurité</bold>" s'affiche, cela signifie que vous avez terminé cette étape. Conservez votre deuxième clé de sécurité en lieu sûr. Assurez-vous de l'enregistrer ultérieurement en tant que sauvegarde si vous perdez celui que vous venez d'enregistrer.`,
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "Si vous êtes invité à saisir un code PIN, vous n'avez pas encore terminé. Procédez comme suit:",
  "registerSecurityKey.expander.body.setUpNewPin.1":
    "Saisissez le <bold>code PIN de votre clé de sécurité</bold> dans les <bold>champs <bold>PIN</bold> et Confirmez le code PIN</bold>, puis cliquez sur <bold>Suivant</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "À l'invite suivante, appuyez de nouveau sur votre clé de sécurité et maintenez-la enfoncée. Il est possible qu'une fenêtre contextuelle s'ouvre, vous demandant des informations sur votre clé de sécurité. Cliquez sur <bold>Autoriser</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3": `Si l'enregistrement est réussi, le message "<bold>Vous avez correctement enregistré votre clé de sécurité" s'affichera</bold>. " Si ce message ne s'affiche pas, recommencez le processus d'enregistrement dans un autre navigateur.`,
  "registerSecurityKey.expander.body.setUpNewPin.3.1": `Si l'enregistrement est réussi, le message <bold>"Vous avez correctement enregistré votre clé de sécurité" s'affiche.</bold>"`,
  "registerSecurityKey.expander.body.setUpNewPin.3.2": `Si ce message ne s'affiche pas, vous devrez recommencer l'étape d'enregistrement. Fermez la fenêtre Midway sur la droite et réessayez cette étape en cliquant sur le bouton "Enregistrer la clé de sécurité sur Midway" pour recommencer cette étape.`,
  "registerSecurityKey.expander.body.secondKeySafe":
    "Conservez votre deuxième clé de sécurité en lieu sûr. Vous pourrez l'enregistrer ultérieurement en tant que sauvegarde si vous perdez celui que vous venez d'enregistrer.",
  "registerSecurityKey.expander.body.note": `<bold>Remarque</bold>: Si vous rencontrez une erreur liée à "Device Compliance" ou à "AEA", vous devrez redémarrer Easy Onboarding en vous déconnectant de "tokenadmin" puis en vous reconnectant pour permettre la fin des mises à jour de l'appareil. Veuillez suivre les instructions fournies dans votre adresse e-mail personnelle pour relancer ce processus. Si cela ne résout pas l'erreur, veuillez utiliser le lien "Contacter le support" pour obtenir de l'aide.`,
  "registerSecurityKey.checkbox.confirmation":
    "Je confirme que j'ai enregistré ma clé de sécurité.",
  "registerSecurityKey.button.sendTempPin": "Envoyer un code PIN temporaire",
  "registerSecurityKey.button.sendTempPin.loadingText": "Envoi...",
  "registerSecurityKey.button.sendTempPin.error":
    "Une erreur s'est produite lors de l'envoi du code PIN. Veuillez réessayer dans quelques minutes.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "Nous n'avons pas pu vous envoyer de code PIN temporaire. Veuillez contacter le support.",
  "registerSecurityKey.button.registerSecurityKey":
    "Enregistrez la clé de sécurité sur Midway",
  "registerSecurityKey.link.resend": "Renvoyer.",
  "registerSecurityKey.image.securityKeyGrid.altText": `Une grille de six images montrant les types de clés de sécurité. Les types sont YubiKey 4, YubiKey 4 Nano, USB-C YubiKey, USB-C Nano", "Zukey" et "USB-C Zukey".`,
  "registerSecurityKey.image.tempPinLogin.altText": `Capture d'écran de la page de connexion à Amazon Midway. La page indique "Connexion", comprend des champs permettant de saisir un nom d'utilisateur Amazon et un code PIN Amazon, ainsi qu'un bouton indiquant "Se connecter".`,
  "registerSecurityKey.image.midwayWelcomeMessage.altText": `Capture d'écran d'une page d'exemple avec un message de bienvenue. L'exemple de page d'accueil indique "Bienvenue jeffbezos!". Pour enregistrer ou gérer vos clés de sécurité, rendez-vous sur le portail d'enregistrement des clés de sécurité.`,
  "registerSecurityKey.image.securityKeyDashboard.altText": `Capture d'écran de la page d'accueil du tableau de bord des clés de sécurité Amazon Enterprise Access. La page indique "Bienvenue sur le tableau de bord des clés de sécurité. Amazon Enterprise Access" et comporte un bouton indiquant "Enregistrer la clé de sécurité".`,
  "registerSecurityKey.image.securityKeyGetStarted.altText": `Capture d'écran de la page Commencez à utiliser votre clé de sécurité. La page indique "Commencez avec votre clé de sécurité. Procurez-vous une clé de sécurité au bureau d'assistance informatique ou au distributeur automatique informatique le plus proche. Après avoir récupéré une clé de sécurité, cliquez sur Commencer l'enregistrement. Important: Vous devez vous munir de votre nouvelle clé de sécurité pour l'enregistrer. Vous devez saisir votre nouvelle clé de sécurité à ce stade. "et comporte un bouton qui dit "Commencer l'enregistrement".`,
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText": `Capture d'écran de la page Commencez à utiliser votre clé de sécurité. La page indique "Commencez avec votre clé de sécurité. Quel type de clé de sécurité avez-vous récupéré? Sélectionnez la clé de sécurité qui correspond à celle que vous possédez. "et dispose d'une grille de six types de clés de sécurité. Les types de clés de sécurité répertoriés sont "YubiKey 4", "YubiKey 4 Nano", "USB-C YubiKey", "USB-C Nano", "Zukey" et "USB-C Zukey".`,
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText": `Capture d'écran de la page Commencez à utiliser votre clé de sécurité. La page indique "Commencez avec votre clé de sécurité. Tout d'abord, insérez votre clé de sécurité dans un port USB de votre ordinateur. Ensuite, maintenez la touche de sécurité enfoncée pendant 3 à 5 secondes jusqu'à ce que le texte s'affiche dans la zone ci-dessous. "et comporte un champ de saisie de texte avec un texte de remplissage qui dit "Votre OTP apparaîtra ici, ne tapez pas dans cette zone".`,
  "registerSecurityKey.image.pressingSecurityKeySide.altText":
    "Une image GIF animée d'une personne appuyant sur le côté d'une clé de sécurité branchée sur un ordinateur portable",
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "Une image GIF animée d'une personne appuyant sur le dessus d'une clé de sécurité branchée sur un ordinateur portable",
  "registerSecurityKey.image.securityKeyCreatePin.altText": `Capture d'écran de la page de création du code PIN de la clé de sécurité. La page indique "Vous devez définir un nouveau code PIN pour enregistrer cette clé de sécurité. N'oubliez PAS le code PIN de votre clé de sécurité. Votre code PIN sera utilisé pour vous permettre de vous connecter aux ressources Amazon et de vous connecter au VPN à l'aide de votre clé de sécurité". La page comprend également un champ de saisie de texte intitulé "Nouveau code PIN (8 à 64 caractères ASCII)", un autre champ de saisie de texte intitulé "Confirmer le nouveau code PIN" et un bouton indiquant "Continuer".`,
  "registerSecurityKey.image.securityKeySuccess.altText": `Capture d'écran d'un message de réussite. Le message indique Vous avez enregistré votre clé de sécurité avec succès. Cela peut prendre jusqu'à 1 heure pour que cette clé de sécurité fonctionne sur tous les outils et ressources d'Amazon."`,
  "registerSecurityKey.image.securityKeyCreateNewPin.altText": `Capture d'écran d'un formulaire contextuel permettant de configurer un nouveau code PIN. La fenêtre contextuelle indique "Code PIN requis. Configurez un nouveau code PIN pour votre clé de sécurité". La fenêtre contextuelle comporte également un champ de saisie de texte intitulé "PIN" et un autre champ de saisie de texte intitulé "Confirmer le code PIN".`,
  "connectVpn.expander.title": "Connectez-vous au réseau Amazon (VPN)",
  "connectVpn.expander.body.intro":
    "Vous devrez vous connecter à un VPN pour accéder aux ressources internes lorsque vous travaillez sur site ou à distance. Les sessions VPN s'éteignent toutes les 18 heures et vous obligent ensuite à vous reconnecter.",
  "connectVpn.expander.body.mac.step1":
    "Ouvrez le <bold>client de mobilité Cisco AnyConnectSecure</bold>.",
  "connectVpn.expander.body.mac.step2":
    "Le client Cisco AnyConnect apparaîtra. <bold>Cliquez sur Connecter</bold>.",
  "connectVpn.expander.body.mac.step3":
    "Une fenêtre contextuelle apparaîtra. Dans le champ <bold>Groupe</bold>, sélectionnez <bold>Orca-Amazon-Onboarding</bold> dans la liste déroulante.",
  "connectVpn.expander.body.mac.step4":
    "Entrez votre nom d'utilisateur Amazon dans le champ <bold>Nom d'utilisateur</bold>.",
  "connectVpn.expander.body.mac.step5":
    "Dans le champ Code PIN de la clé de sécurité + Appuyez sur la touche de <bold>sécurité, saisissez le code PIN de votre clé</bold> de sécurité, puis maintenez votre clé de sécurité enfoncée jusqu'à ce qu'une longue chaîne de caractères apparaisse dans le champ. Ne cliquez pas sur OK: lorsque vous maintenez votre clé de sécurité enfoncée, la connexion VPN commence automatiquement à s'authentifier.",
  "connectVpn.expander.body.mac.step5.warning":
    "Le <bold>champ Code PIN de la clé de sécurité + Appuyez sur la touche de sécurité</bold> peut indiquer <bold>Mot de passe</bold> à la place. Ne saisissez pas votre mot de passe Amazon ici. Entrez le code PIN de votre clé de sécurité, puis appuyez longuement sur votre clé de sécurité.",
  "connectVpn.expander.body.mac.step6":
    "Une fenêtre contextuelle s'affichera pour confirmer que vous êtes connecté au réseau Amazon via un VPN. Sélectionnez <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Capture d'écran de l'application Cisco AnyConnect Secure Mobility Client.",
  "connectVpn.image.mac.step3.altText":
    "Capture d'écran d'une fenêtre contextuelle contenant les champs Groupe, Nom d'utilisateur et Code PIN de la clé de sécurité permettant de se connecter au VPN avec l'application Cisco AnyConnect Secure Mobility Client avec Orca-Amazon-Onboarding sélectionnée dans la liste déroulante du champ Groupe.",
  "connectVpn.image.mac.step4.altText":
    "Capture d'écran d'une fenêtre contextuelle contenant les champs Code PIN du groupe, du nom d'utilisateur et de la clé de sécurité permettant de se connecter au VPN avec l'application Cisco AnyConnect Secure Mobility Client, avec un encadré rouge surlignant le champ Nom d'utilisateur.",
  "connectVpn.image.mac.step5.altText":
    "Capture d'écran d'une fenêtre contextuelle contenant les champs Groupe, Nom d'utilisateur et Code PIN de la clé de sécurité pour se connecter au VPN avec l'application Cisco AnyConnect Secure Mobility Client avec une boîte rouge mettant en évidence le code PIN de la clé de sécurité + Appuyez sur le champ de la clé de sécurité et le bouton OK barré d'un X rouge",
  "connectVpn.image.mac.step6.altText":
    "Capture d'écran d'une connexion réussie au VPN.",
  "connectVpn.expander.body.windows.step1":
    "Appuyez sur la <bold>touche Windows</bold> de cet ordinateur portable pour accéder au Menu Démarrer.",
  "connectVpn.expander.body.windows.step2":
    "Dans le menu Démarrer, sélectionnez <bold>Cisco AnyConnect</bold> (il s'agit du logiciel d'Amazon pour accéder au VPN).",
  "connectVpn.expander.body.windows.step3":
    "Le client Cisco AnyConnect s'affiche. Le logiciel Cisco AnyConnect est utilisé pour le VPN d'Amazon. Cliquez sur <bold>Connecter</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Cliquez pour ouvrir le menu déroulant dans le champ Groupe. Sélectionnez <bold>Orca-Amazon-Onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Entrez votre nom d'utilisateur Amazon dans le champ <bold>Nom d'utilisateur</bold>.",
  "connectVpn.expander.body.windows.step6":
    "Dans le champ <bold>Mot de passe</bold>, saisissez le code PIN de votre clé de sécurité, puis maintenez votre clé de sécurité enfoncée jusqu'à ce qu'une longue chaîne de caractères apparaisse dans le champ. Ne cliquez pas sur <bold>OK</bold>: lorsque vous maintenez votre clé de sécurité enfoncée, la connexion VPN commence automatiquement à s'authentifier.",
  "connectVpn.expander.body.windows.step7":
    "Lorsque le VPN est connecté, une notification de sécurité apparaît pour confirmer la connexion. Cliquez sur <bold>OK</bold> pour fermer la boîte de dialogue.",
  "connectVpn.image.windows.step1.altText": "Appuyez sur la touche Windows.",
  "connectVpn.image.windows.step2.altText":
    "Sélectionnez Cisco AnyConnect dans le menu Démarrer.",
  "connectVpn.image.windows.step4.altText":
    "Sélectionnez Orca-Amazon-Onboarding dans la liste déroulante du champ Groupe.",
  "connectVpn.image.windows.step6.altText":
    "Tapez le code PIN de votre clé de sécurité, puis appuyez longuement sur votre sécurité.",
  "connectVpn.button.tooltip.vpnNotConnectedHint": "Connectez-vous au VPN.",
  "amazonPassword.expander.title": "Créer un mot de passe Amazon",
  "amazonPassword.expander.body.intro1":
    "Vous allez ensuite créer le mot de passe que vous utiliserez pour vous connecter quotidiennement à votre ordinateur portable et accéder aux outils Amazon.",
  "amazonPassword.expander.body.intro2":
    "Tapez le code PIN de votre clé de sécurité, puis maintenez votre doigt appuyé sur votre clé de sécurité USB pour définir votre mot de passe pour la première fois. <bold>N'oubliez pas ce mot de passe</bold>, car vous en aurez besoin pour continuer à configurer votre ordinateur portable.",
  "amazonPassword.expander.body.banner": `Lors de la création de votre mot de passe, il vous sera demandé d'appuyer avec votre doigt sur votre clé de sécurité USB. Cela appuiera sur "Entrée" pour vous. Il n'est pas nécessaire de cliquer sur "Entrer" ou "Soumettre". Si vous recevez un message d'erreur, ignorez-le et poursuivez la configuration de votre ordinateur portable.`,
  "amazonPassword.expander.body.confirmation":
    "Je confirme que j'ai créé mon mot de passe avec succès.",
  "amazonPassword.button.createPassword": "Créer un mot de passe Amazon",
  "preCacheADMobile.expander.title": "Installez Pre-Cache AD Mobile",
  "preCacheADMobile.expander.body.intro":
    "Chez Amazon, nous utilisons un outil appelé Precache AD pour synchroniser les informations de votre compte situées sur le réseau Amazon avec l'ordinateur portable que vous utilisez. Cela garantit que vous pouvez vous connecter à votre ordinateur portable Amazon à l'aide de votre mot de passe et de votre nom d'utilisateur Amazon chaque jour.",
  "preCacheADMobile.expander.body.step1":
    "Ouvrez l'application <bold>Self Service</bold> {logo} sur votre bureau.",
  "preCacheADMobile.expander.body.step2":
    "Saisissez votre nom d'utilisateur Amazon.",
  "preCacheADMobile.expander.body.step3":
    "Entrez le code PIN de votre clé de sécurité et appuyez sur votre clé de sécurité.",
  "preCacheADMobile.expander.body.step4":
    "Dans le champ de recherche en haut à gauche, saisissez <bold>Pre-Cache</bold>.",
  "preCacheADMobile.expander.body.step5":
    "Lorsque les résultats de la recherche sont chargés, cliquez sur <bold>Cache</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Saisissez votre nom d'utilisateur Amazon. Cliquez sur <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Saisissez votre mot de passe Amazon. Cliquez sur <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step8":
    "Le script s'exécutera silencieusement pendant 2 à 3 minutes. Une fois terminé, un message Succès! Une fenêtre contextuelle apparaît. Cliquez sur <bold>OK</bold>.",
  "preCacheADMobile.image.step3.altText":
    "Capture d'écran de l'affichage permettant de se connecter à l'aide de votre nom d'utilisateur Amazon et du code PIN de votre clé de sécurité.",
  "preCacheADMobile.image.step4.altText":
    "Capture d'écran de la vue permettant de rechercher Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Capture d'écran de la vue permettant de saisir votre nom d'utilisateur Amazon.",
  "preCacheADMobile.image.step7.altText":
    "Capture d'écran de la vue permettant de saisir votre mot de passe Amazon.",
  "preCacheADMobile.image.step8.altText":
    "Capture d'écran de l'application Pre-Cache AD Mobile installée avec succès.",
  "preCacheADMobile.logo.altText":
    "Logo de l'application AD pré-cache installable en libre-service",
  "banner.openViaWelcome":
    "Ouvrez cette page en cliquant sur Commencer dans la page d'accueil.",
  "allStepsCompleted.title": "👏 Excellent travail!",
  "allStepsCompleted.mac.nextTo": "Ensuite, vous devrez:",
  "allStepsCompleted.win.nextTo":
    "Ensuite, vous devrez vous connecter pour la première fois:",
  "allStepsCompleted.win.step1": "Étape 1: Connectez-vous au VPN",
  "allStepsCompleted.win.instructionSent":
    "Ces instructions vous ont également été envoyées par e-mail",
  "allStepsCompleted.mac.postLoginStep1":
    "Déconnectez-vous de votre ordinateur portable.",
  "allStepsCompleted.mac.postLoginStep2":
    "Consultez votre e-mail personnel pour savoir comment vous connecter à votre compte Amazon.",
  "allStepsCompleted.win.step1.subStep1":
    "Redémarrez cet ordinateur portable en appuyant sur la touche Windows et en sélectionnant <bold>Redémarrer</bold> dans le menu <bold>Démarrer</bold>.",
  "allStepsCompleted.win.step1.subStep2":
    "Cliquez sur l'icône de connexion réseau pour vous connecter au VPN Amazon. Cette icône ressemble à deux écrans d'ordinateur.",
  "allStepsCompleted.win.step1.subStep2.note":
    "<bold>Remarque:</bold> la connexion au VPN depuis l'écran de connexion n'est nécessaire que pour la première configuration du compte. Vous n'aurez pas besoin d'utiliser un VPN pour vous connecter après la première fois.",
  "allStepsCompleted.windows.vpnIcon.altText":
    "Une icône avec deux écrans d'ordinateur surlignés en jaune.",
  "allStepsCompleted.win.step1.subStep3":
    "Le client Cisco AnyConnect va apparaître. Cliquez sur <bold>Connecter</bold>.",
  "allStepsCompleted.win.step1.subStep4":
    "Cliquez pour ouvrir le menu déroulant dans le champ <bold>Groupe</bold>. Sélectionnez <bold>Pré-connexion</bold>.",
  "allStepsCompleted.win.step1.preLogin.altText":
    "`Une image du client VPN Cisco avec le groupe de pré-connexion sélectionné. `",
  "allStepsCompleted.win.step1.subStep5":
    "Entrez votre nom d'utilisateur Amazon dans le champ <bold>Nom d'utilisateur</bold> (votre nom d'utilisateur est {username}).",
  "allStepsCompleted.win.step1.subStep6":
    "Dans le champ <bold>Mot de passe</bold>, saisissez le code PIN de votre clé de sécurité, puis maintenez votre clé de sécurité enfoncée jusqu'à ce qu'une longue chaîne de caractères apparaisse dans le champ. Ne cliquez pas sur <bold>OK</bold> lorsque vous maintenez votre clé de sécurité enfoncée, la connexion VPN commencera automatiquement à s'authentifier.",
  "allStepsCompleted.win.step1.securityKey.altText":
    "`Photo d'un doigt touchant la clé de sécurité branchée sur un ordinateur. `",
  "allStepsCompleted.win.step1.subStep6.note":
    "<bold>Important:</bold> lorsque vous vous connectez à un VPN, n'utilisez pas votre mot de passe Amazon, même s'il indique Mot de passe. Utilisez le code PIN de votre clé de sécurité, puis appuyez légèrement pendant 3 à 5 secondes sur la clé de sécurité connectée à votre ordinateur.",
  "allStepsCompleted.win.step1.subStep7":
    "Lorsque le VPN est connecté, une notification de sécurité apparaît. Cliquez sur <bold>OK</bold> pour fermer la boîte de dialogue. Vous serez redirigé vers l'écran de connexion Windows.",
  "allStepsCompleted.win.step2":
    "Étape 2: Connectez-vous à votre compte Amazon",
  "allStepsCompleted.win.step2.intro":
    "Connectez-vous à votre compte Amazon avec votre nom d'utilisateur et le mot de passe que vous avez créé.",
  "allStepsCompleted.win.step2.subStep1": `Dans le champ Nom d'utilisateur, tapez <bold>ANT\\{username}</bold>. Assurez-vous d'inclure le ANT\\ avant votre nom d'utilisateur.`,
  "allStepsCompleted.win.step2.subStep2":
    "Dans le champ Mot de passe, saisissez votre mot de <bold>passe Amazon</bold> et appuyez sur Entrée.,",
  "allStepsCompleted.win.step2.subStep2.note1":
    "<bold>Important:</bold> une fois connecté à votre compte Amazon, vous devrez vous <bold>déconnecter du VPN</bold> pour accéder aux ressources Amazon. Si vous ne vous déconnectez pas, vous ne pourrez accéder à aucune ressource. Vous pouvez localiser le Cisco AnyConnect dans le Menu Démarrer pour vous déconnecter du VPN.",
  "allStepsCompleted.win.step2.subStep2.note2":
    "Si un VPN est nécessaire pour d'autres services spécifiques à votre poste, vous devrez utiliser <bold>Orca-Corp-VPN. </bold>",
  "allStepsCompleted.win.step3":
    "Étape 3: Ouvrez le guide de configuration de l'ordinateur portable",
  "allStepsCompleted.win.step3.intro":
    "Une fois connecté, rendez-vous sur {newHirePortalLink} pour terminer la configuration de votre ordinateur portable pour les outils courants utilisés sur Amazon, tels que Chime, Slack et Outlook.",
  failedToRender:
    "Quelque chose s'est mal passé. Rendez-vous sur {link} et contactez le support informatique pour obtenir de l'aide supplémentaire.",
  "error.serviceNotAvailable.text1":
    "L'application Amazon Day 1 n'est pas disponible pour le moment.",
  "error.serviceNotAvailable.step1.title": "Modifier la connexion réseau",
  "error.serviceNotAvailable.step1.windows": "Utilisateurs Windows",
  "error.serviceNotAvailable.step1.windows.subStep1":
    "Appuyez sur la touche <bold>Windows</bold> et sélectionnez l'icône <bold>Comptes</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep2":
    "Sélectionnez <bold>« Déconnexion »</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep3":
    "Sur l'écran de connexion <bold>Windows</bold>, localisez l'icône Wi-Fi dans le coin inférieur droit.",
  "error.serviceNotAvailable.step1.windows.subStep4a":
    "Connectez-vous à une autre connexion réseau.",
  "error.serviceNotAvailable.step1.windows.subStep4b":
    "Si vous vous trouvez dans un bâtiment d'Amazon et que vous êtes connecté au réseau Wi-Fi A2Z, essayez de vous connecter au Wi-Fi invité s'il est disponible pour vous.",
  "error.serviceNotAvailable.step1.windows.subStep4c":
    "<bold>Important</bold> : vous devez vous connecter au Wi-Fi avant de vous connecter avec votre compte.",
  "error.serviceNotAvailable.step1.windows.subStep5":
    "Connectez-vous en tant qu'utilisateur <bold>tokenadmin</bold> à l'aide des informations d'identification fournies dans votre e-mail.",
  "error.serviceNotAvailable.step1.mac": "Utilisateurs de macOS",
  "error.serviceNotAvailable.step1.mac.subStep1":
    "Sélectionnez un autre réseau Wi-Fi dans la section Wi-Fi située dans le coin supérieur droit de votre écran.",
  "error.serviceNotAvailable.step2.title": "Contactez le support informatique",
  "error.serviceNotAvailable.text2": `Si vous êtes un nouvel employé, consultez le guide de configuration informatique à l'adresse firstaid.amazon-corp.com/vnho depuis votre appareil Amazon ou votre appareil personnel connecté à Internet pour terminer la configuration de votre ordinateur portable. Vous aurez besoin de votre responsable du recrutement ou du support informatique pour vous aider dans le processus de configuration. Si votre responsable du recrutement n'est pas disponible, veuillez utiliser le lien "Obtenir de l'aide" situé sur la page des instructions de premiers secours.`,
  "error.somethingWentWrong":
    "Quelque chose s'est mal passé. Veuillez réessayer ou {chatWithItSupportLink} pour obtenir de l'aide.",
  "error.accountIssues":
    "Il y a un problème avec votre compte. Veuillez {chatWithItSupportLink}.",
  "error.ineligibleError.text1":
    "Il y a un problème avec votre compte. Veuillez lire ce qui suit avant de contacter le support.",
  "error.ineligibleError.text2":
    "Si vous êtes un nouvel employé, consultez le guide de configuration informatique à l'adresse firstaid.amazon-corp.com/vnho depuis votre appareil personnel connecté à Internet pour terminer la configuration de votre ordinateur portable.",
  "error.ineligibleError.text3":
    "Si vous êtes un Amazonien et que vous configurez votre ordinateur portable de remplacement, suivez les instructions fournies avec l'ordinateur portable.",
  "error.ineligibleError.text4":
    "Si vous avez toujours besoin d'aide, rendez-vous sur firstaid.amazon-corp.com pour obtenir de l'aide.",
  "error.notVerifiableError.text1":
    "Nous sommes désolés, la vérification d'identité n'est pas disponible actuellement.",
  "error.notVerifiableError.text2":
    "Pour vérifier votre identité, veuillez {chatWithItSupportLink}. L'un de nos agents se fera un plaisir de vous aider tout au long du processus de vérification. Nous nous excusons pour la gêne occasionnée et vous remercions de votre compréhension.",
  "error.beforeStartDate":
    "Votre date de début et votre premier jour sont {startDate}. Veuillez fermer votre ordinateur portable jusqu'à ce moment. Vous avez besoin d'aide? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "Je ne parviens pas à démarrer le processus de recrutement d'Easy Onboarding pour les nouveaux employés et j'ai besoin d'aide pour configurer mon ordinateur portable le premier jour.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "J'ai besoin d'aide pour vérifier mon identité lors de l'intégration de nouveaux employés par le biais d'un appel vidéo Chime.",
  "itHelpMsg.verificationIssueAndAssist":
    "Je ne parviens pas à vérifier mon identité dans le cadre du processus d'embauche Easy Onboarding pour les nouvelles recrues et j'ai besoin d'aide pour configurer mon ordinateur portable le premier jour.",
  "itHelpMsg.verificationIssue":
    "Je rencontre des problèmes lors de la configuration de mon ordinateur portable dans le cadre du processus d'intégration des nouveaux employés et j'ai besoin d'aide pour vérifier mon identité.",
  "itHelpMsg.verificationAssist":
    "J'ai besoin d'aide pour vérifier mon identité dans le cadre du processus Easy Onboarding pour les nouvelles recrues et j'ai besoin d'aide pour m'intégrer.",
  "itHelpMsg.settingUpLaptop":
    "Je rencontre des problèmes lors de la configuration de mon ordinateur portable dans le cadre du processus d'intégration des nouveaux employés.",
  "itHelpMsg.usernameIssue":
    "Je rencontre des problèmes avec mon nom d'utilisateur dans le cadre du processus d'intégration de ma nouvelle recrue.",
  "itHelpMsg.midwayAssist":
    "J'ai besoin d'aide concernant Midway et le processus d'enregistrement des clés de sécurité dans le cadre de l'intégration de ma nouvelle recrue.",
  "itHelpMsg.midwayIssue":
    "Je rencontre des problèmes avec le processus d'inscription de Midway Key dans le cadre de l'intégration de mes nouveaux employés. J'ai déjà demandé l'envoi d'un code PIN temporaire.",
  "itHelpMsg.vpnIssue":
    "Je rencontre un problème lié à la connexion VPN dans le cadre de l'intégration de ma nouvelle recrue.",
  "itHelpMsg.passwordIssue":
    "Je rencontre un problème avec l'outil de réinitialisation du mot de passe dans le cadre de l'intégration de ma nouvelle recrue.",
  "itHelpMsg.preCacheADIssue":
    "Je rencontre un problème avec l'étape Pre-Cache AD lors de l'intégration de mon nouvel employé sur mon appareil Mac.",
  "mediacheck.failure.permission":
    "Pour autoriser l'accès, cliquez sur l'icône de l'appareil photo {icon} dans la barre d'adresse de votre navigateur. Il se peut que vous deviez recharger la page pour que les modifications soient appliquées.",
  "mediacheck.failure.nodevice":
    "Aucun appareil photo n'a été trouvé. Assurez-vous qu'une caméra est branchée et qu'elle fonctionne, puis rechargez la page.",
  "mediacheck.failure.other":
    "Un problème a empêché la page de détecter si une caméra utilisable est installée.",
  "mediacheck.failure.title": "La caméra est bloquée.",
  "mediacheck.failure.cameraalt": "Icône de la caméra",
  "cameraOn.text": "Démarrez l'enregistrement, puis {text}",
  "getSupport.header": "Obtenir une assistance",
  "getSupport.footer": "Vous avez toujours besoin d'aide?",
  "getSupport.chatSupport.clickToStart": "Démarrer le chat",
  "getSupport.chatSupport.clickToStartNew": "Commencer un nouveau chat",
  "getSupport.faq.password.header": "FAQ sur les mots de passe Amazon",
  "getSupport.faq.password.section1.title":
    "Quand est-ce que mon mot de passe expire?",
  "getSupport.faq.password.section1.contents": "",
  "getSupport.faq.vpn.header": "FAQ SUR LE VPN",
  "getSupport.faq.vpn.section1.title": "Erreur de configuration Cisco CSD",
  "getSupport.faq.vpn.section1.contents": "Espace réservé",
  "getSupport.faq.general.header": "FAQ générales",
  "getSupport.faq.general.section1.title":
    "Que dois-je faire si je n'ai pas reçu de code de vérification?",
  "getSupport.faq.general.section1.contents": "code de vérification",
  "getSupport.faq.midway.header": "FAQ sur Midway",
  "getSupport.faq.midway.section1.title": "Qu'est-ce qu'une clé de sécurité?",
  "getSupport.faq.midway.section1.contents":
    "Une clé de sécurité est une clé USB physique qui sera insérée dans votre ordinateur. Nous vous avons envoyé un colis contenant deux clés de sécurité USB. Enregistrez la deuxième clé de sauvegarde dans un endroit sûr, au cas où votre clé principale serait perdue ou endommagée.",
  "getSupport.faq.username.header": "FAQ sur les noms d'utilisateur Amazon",
  "getSupport.faq.username.section1.title":
    "Qu'est-ce qu'un nom d'utilisateur Amazon?",
  "getSupport.faq.username.section1.contents":
    "Sur Amazon, vous utiliserez votre nom d'utilisateur pour vous connecter à votre ordinateur et accéder aux outils et ressources Amazon. Votre nom d'utilisateur est la partie de votre adresse e-mail Amazon qui précède le @.",
  "getSupport.idv.title":
    "Discutez avec le support informatique pour vérifier votre identité",
  "getSupport.idv.body.content1":
    "Si vous rencontrez des problèmes avec le processus ci-dessus ou si vous avez d'autres préoccupations, veuillez contacter l'équipe d'assistance informatique.",
  "getSupport.idv.body.content2":
    "L'équipe d'assistance informatique peut vous aider à vérifier votre identité par le biais d'un appel vidéo.",
  "arialabels.clickToCloseChat":
    "Cliquez pour fermer le panneau d'assistance par chat",
  "arialabels.clickToStartChat": "Cliquez pour démarrer le support par chat",
  "arialabels.clickToOpenChat":
    "Cliquez pour ouvrir le panneau d'assistance par chat",
  "arialabels.clickToCloseGetSupport":
    "Cliquez pour fermer le panneau d'assistance",
  "arialabels.clickToOpenGetSupport":
    "Cliquez pour ouvrir le panneau Obtenir de l'aide",
};

export default french;
