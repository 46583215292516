import { IconGlobe } from "@amzn/stencil-react-components/icons";
import {
  PageHeaderDropdown,
  PageHeaderDropdownButton,
} from "@amzn/stencil-react-components/page";
import { trackStructEvent } from "@snowplow/browser-tracker";
import React, { FC, useContext, useState } from "react";

import { useSelectedLanguage } from "@/hooks/useSelectedLanguage";
import { AppContext } from "@/stores/appStore";
import { ActionType } from "@/stores/constants";
import { Day1EventAction, Day1EventCategory } from "@/types/snowplow-events";

import { DEFAULT_LANGUAGE, SUPPORTED_LANGUAGES } from "../../i18n/locales";

export const LanguageSelector: FC = () => {
  const initialLanguage = useSelectedLanguage();
  const { dispatch } = useContext(AppContext);
  const [selectedLanguage, setSelectedLanguage] = useState<string>(
    initialLanguage ? initialLanguage : DEFAULT_LANGUAGE.locale
  );

  const handleLangueSelect = (locale: string) => {
    trackStructEvent({
      category: Day1EventCategory.Header,
      action: Day1EventAction.SelectLanguage,
      label: locale,
    });
    setSelectedLanguage(locale);
    dispatch({
      type: ActionType.SET_SELECTED_LANGUAGE,
      selectedLanguage: locale,
    });
  };

  const items = SUPPORTED_LANGUAGES.map((language) => (
    <PageHeaderDropdownButton
      dataTestId={`languageSelector-${language.locale}`}
      key={language.locale}
      onClick={() => {
        handleLangueSelect(language.locale);
      }}
    >
      {language.label}
    </PageHeaderDropdownButton>
  ));

  return (
    <>
      <PageHeaderDropdown
        id="languageSelectorLink"
        dataTestId={"languageSelectorLink"}
        icon={<IconGlobe aria-hidden={true} />}
        items={items}
        paddingHorizontal={0}
      >
        {selectedLanguage.substring(0, 2)}
      </PageHeaderDropdown>
    </>
  );
};

export default LanguageSelector;
