export type Language = { label: string; locale: string };

export type languageObject = {
  [key: string]: string;
};

export const LOCALES: Record<string, string> = {
  ENGLISH: "en-US",
  DUTCH: "nl-NL",
  FRENCH: "fr-FR",
  INDONESIAN: "id-ID",
  SPANISH: "es-ES",
  VIETNAMESE: "vi-VN",
};

export const SUPPORTED_LANGUAGES: Language[] = [
  { label: "English - en", locale: "en-US" },
  { label: "Bahasa Indonesia - id", locale: "id-ID" },
  { label: "Español - es", locale: "es-ES" },
  { label: "Français - fr", locale: "fr-FR" },
  { label: "Nederlands - nl", locale: "nl-NL" },
  { label: "Tiếng Việt - vi", locale: "vi-VN" },
];

export const DEFAULT_LANGUAGE = SUPPORTED_LANGUAGES[0];
