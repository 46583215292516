import { languageObject } from "./locales";

/**
 * This locale file will be used by the application to change the language to Indonesian
 * based on user selection.
 **/

const indonesian: languageObject = {
  "page.title": "Amazon Hari 1",
  "button.next": "Berikutnya",
  "button.back": "Kembali",
  "button.done": "Selesai",
  "text.then": "Kemudian",
  "text.chatWithITSupport": "Mengobrol dengan Dukungan TI",
  "videoGuidance.title": "Bimbingan video",
  "videoGuidance.example": "Contoh",
  "videoGuidance.button.altText": "Tampilkan panduan video",
  "icon.title": "Selesai",
  "error.notSupportVideoTag": "Browser Anda tidak mendukung tag video.",
  "welcome.expander.title": "Selamat datang di Amazon 🎉",
  "welcome.expander.body.greeting":
    "Kami senang kau ada di sini. Kami akan memandu Anda melalui pengaturan laptop baru Anda.",
  "welcome.expander.body.requirements.start": "Apa yang Anda butuhkan:",
  "welcome.expander.body.requirements.list1":
    "Kunci keamanan Anda yang kami kirimkan kepada Anda.",
  "welcome.expander.body.requirements.list2": "Koneksi Wi-Fi.",
  "welcome.expander.body.requirements.list3":
    "Nama pengguna dan kata sandi portal Pre-Boarding Embark Anda.",
  "welcome.expander.body.requirements.list4":
    "Akses ke email pribadi Anda untuk menerima informasi tambahan selama proses berlangsung.",
  "welcome.expander.body.firstStep.start":
    "Langkah pertama: Sambungkan ke Wi-Fi",
  "welcome.expander.body.firstStep.list1": "Klik ikon Wi-Fi di bilah tugas.",
  "welcome.expander.body.firstStep.list2": "Pilih koneksi Wi-Fi Anda.",
  "welcome.expander.body.firstStep.list3":
    "Masukkan kata sandi Wi-Fi Anda, dan klik “Hubungkan”.",
  "welcome.button.getStarted": "Memulai",
  "identityCheck.expander.title": "Verifikasi identitas",
  "identityCheck.expander.body.header": "Rekam video",
  "identityCheck.expander.body.intro":
    "Rekam video yang memegang dokumen ID Anda di sebelah wajah Anda, dan selesaikan serangkaian gerakan tangan.",
  "identityCheck.expander.body.recordInfo.title":
    "Informasi tentang “Rekam Video”",
  "identityCheck.expander.body.recordInfo.body":
    "Sebelum mengeluarkan kredentif akses sistem Amazon, Amazon harus memverifikasi identitas Anda. Untuk melakukannya, Amazon menggunakan sistem verifikasi identitas (“Sistem”). Penggunaan Sistem sepenuhnya opsional dan sukarela. Sistem bekerja sebagai berikut: unggah foto identifikasi foto yang dikeluarkan pemerintah (“Govt. ID Foto”) dan rekam video diri Anda memegang ID Foto Anda. Nama pada ID Foto harus secara substansial mirip dengan nama yang dimiliki Amazon di file untuk Anda. Salah satu teknisi dukungan kami akan memverifikasi nama dan gambar Anda pada ID foto Anda dengan wajah Anda dalam rekaman yang dikirimkan.",
  "identityCheck.expander.body.whatIsCollected.title":
    "Informasi apa yang dikumpulkan",
  "identityCheck.expander.body.whatIsCollected.intro":
    "Untuk memverifikasi identitas Anda, Amazon akan mengumpulkan informasi pribadi berikut dari Anda:",
  "identityCheck.expander.body.whatIsCollected.item1":
    "Foto ID Foto Pemerintah Anda",
  "identityCheck.expander.body.whatIsCollected.item2":
    "Sebuah video selfie dari Anda",
  "identityCheck.expander.body.whatIsCollected.purpose":
    "Informasi pribadi Anda akan digunakan semata-mata untuk tujuan identifikasi dan verifikasi. Amazon akan menghancurkan semua informasi pribadi dalam waktu 7 hari setelah menyelesaikan proses verifikasi, kecuali diwajibkan oleh hukum untuk menyimpan informasi pribadi Anda untuk jangka waktu yang lebih lama. Untuk informasi lebih lanjut, silakan baca Pemberitahuan Privasi Amazon HR, yang disediakan sebagai bagian dari paket orientasi Anda, atau melalui portal Amazon internal, AtoZ, setelah menyelesaikan proses penyiapan laptop.",
  "identityCheck.expander.body.light.instruction":
    "Setelah Anda memulai tantangan gerakan ID, Anda akan memiliki <b>10 menit</b> untuk merekam dan mengirimkan satu <b>video 10 detik</b> tentang diri Anda menyelesaikan gerakan.",
  "identityCheck.expander.body.helpLinkPre":
    "Tidak dapat menyelesaikan tindakan? Silakan {chatWithITSupportLink} untuk memverifikasi identitas Anda pada panggilan video Chime.",
  "identityCheck.expander.body.helpLinkPost":
    "untuk memverifikasi identitas Anda pada panggilan video Chime.",
  "identityCheck.challenges.title": "Tindakan Tantangan",
  "identityCheck.challenges.completed":
    "Tindakan tantangan Anda telah dicatat.",
  "identityCheck.challenges.instructions.completeByTime":
    "Silakan selesaikan tantangan ini pada {time}",
  "identityCheck.challenges.instructions":
    "Ikuti instruksi untuk merekam video 10 detik yang melakukan dua gerakan ini:",
  "identityCheck.challenges.expired":
    "<b>Batas waktu Anda telah kedaluwarsa. </b>Silakan {chatWithIt}. Anda akan diminta untuk bergabung dengan panggilan video dan menunjukkan ID Anda kepada Insinyur Dukungan TI.",
  "identityCheck.challenges.instructions.unhideButton":
    "Mulai tantangan gerakan",
  "identityCheck.challenges.forSeconds": "selama {second} detik.",
  "identityCheck.button.recording": "Rekaman: {countDownTimer}",
  "identityCheck.button.startRecord": "Mulai Merekam",
  "identityCheck.button.enableCamera": "Aktifkan Kamera",
  "identityCheck.button.retry": "Coba lagi",
  "identityCheck.do.title": "Lakukan",
  "identityCheck.do.hint1": "Lihat langsung ke kamera.",
  "identityCheck.do.hint2": "Gunakan paparan cahaya yang tepat.",
  "identityCheck.dont.title": "Jangan",
  "identityCheck.dont.hint1": "Tutupi ID Anda dengan jari atau tangan Anda.",
  "identityCheck.dont.hint2": "Tutupi wajah Anda dengan topeng.",
  "identityCheck.dont.hint3":
    "Kenakan kacamata hitam (kacamata resep tidak apa-apa).",
  "identityCheck.dont.hint4":
    "Kenakan topi atau topi (penutup kepala agama yang tidak menutupi wajah Anda tidak apa-apa).",
  "identityCheck.full.steps.recordPrimaryId":
    "Rekam video yang menyimpan {requiredDocuments, plural, one {pemerintah} other {primer}} ID",
  "identityCheck.full.steps.recordSecondaryId":
    "Rekam video memegang ID sekunder",
  "identityCheck.full.steps.secondaryIdGovIssued":
    "Apakah ID sekunder Anda dikeluarkan oleh pemerintah?",
  "identityCheck.secondaryIdGovIssued.instruction":
    "ID sekunder dapat berupa dokumen non-pemerintah dengan nama dan foto Anda. Bawalah ini pada hari pertama Anda.",
  "identityCheck.full.steps.recordChallengeActions": "Rekam tindakan tantangan",
  "identityCheck.full.primaryId.expandAltText":
    "Klik untuk mengunggah {requiredDocuments, plural, one {government} other {primary}} Id",
  "identityCheck.full.secondaryId.expandAltText":
    "Klik untuk mengunggah Id sekunder",
  "identityCheck.full.challengeActions.expandAltText":
    "Klik untuk mengunggah tindakan tantangan",
  "identityCheck.primaryId.instruction":
    "Rekam video 15 detik yang menyimpan {requiredDocuments, plural, one {government} other {primary}} ID Anda di samping wajah Anda dan kemudian ke kamera. ID ini harus berupa ID yang dikeluarkan pemerintah yang belum kedaluwarsa dari daftar dokumen yang diterima ini. Itu harus menunjukkan nama, tanggal lahir, dan foto Anda.",
  "identityCheck.secondaryId.instruction":
    "Rekam video 15 detik yang memegang ID sekunder fisik Anda di samping wajah Anda dan kemudian ke kamera. ID sekunder dapat berupa ID yang dikeluarkan pemerintah yang berbeda (misalnya, SIM) atau ID foto yang tidak dikeluarkan pemerintah (misalnya, kartu Costco atau ID pelajar).",
  "identityCheck.primaryIdCountry.select.label":
    "Negara mana yang mengeluarkan ID Anda?",
  "identityCheck.primaryId.select.label": "Jenis ID",
  "identityCheck.primaryId.select.placeholder": "Pilih Jenis ID",
  "identityCheck.primaryId.country.select.placeholder":
    "Pilih Negara Penerbit ID",
  "identityCheck.primaryId.video.instruction":
    "Pegang {requiredDocuments, plural, one {Government} other {Primary}} ID Anda di sebelah wajah Anda, lalu gerakkan ke arah kamera.",
  "identityCheck.primaryId.video.instruction2":
    "Pegang ID Anda 3 inci dari kamera sampai fokus.",
  "identityCheck.primaryId.completed": "ID Utama dan Wajah Anda telah direkam.",
  "identityCheck.governmentId.completed":
    "ID Pemerintah dan Wajah Anda telah dicatat.",
  "identityCheck.secondaryId.completed":
    "ID Sekunder dan Wajah Anda telah direkam.",
  "identityCheck.primary.title":
    "Rekam video berisi ID {requiredDocuments, plural, one {government} other {primary}}",
  "identityCheck.secondaryID.governmentID.radio1":
    "ID yang dikeluarkan pemerintah",
  "identityCheck.secondaryID.governmentID.radio2":
    "ID yang tidak dikeluarkan pemerintah",
  "identityCheck.secondaryId.title":
    "Rekam video yang menyimpan ID sekunder Anda",
  "identityCheck.secondaryId.video.instruction":
    "Pegang ID Sekunder di sebelah wajah Anda, lalu gerakkan ke arah kamera.",
  "identityCheck.secondaryId.video.instruction2":
    "Pegang ID Anda 3 inci dari kamera sampai fokus.",
  "identityCheck.button.goBackAltText": "Kembali",
  "identityCheck.button.submitReview": "Kirim untuk ditinjau",
  "identityCheck.button.loading": "Mengirimkan...",
  "identityCheck.button.tooltip.disallowResubmit":
    "Rekaman video telah dikirimkan.",
  "identityCheck.button.tooltip.missingPrimaryIdType":
    "Silakan pilih {requiredDocuments, plural, one {government} other {primary}} ID dari dropdown pilih.",
  "identityCheck.button.tooltip.missingSecondaryIdGovIssued":
    "Silakan pilih apakah jenis ID sekunder Anda dikeluarkan pemerintah menggunakan tombol radio.",
  "identityCheck.errors.unableToVerify":
    "Kami tidak dapat memverifikasi identitas Anda. Silahkan {link}. Anda akan diminta untuk bergabung dengan panggilan video dan menunjukkan ID Anda kepada agen Dukungan TI Amazon, dan Anda akan diberikan jalur lain untuk mengatur laptop Anda.",
  "identityCheck.errors.unableToVerifyFallback":
    "<b>Kami tidak dapat memverifikasi identitas Anda menggunakan Verifikasi Identitas Manual. </b>Silakan coba gunakan <a>Verifikasi Identitas Biometrik sebagai</a> gantinya.",
  "identityCheck.errors.failedToSubmit":
    "Kami tidak dapat mengirimkan video Anda. Silakan coba lagi dalam beberapa detik atau {link} untuk bantuan lebih lanjut.",
  "identityCheck.errors.chatWithIT": "Mengobrol dengan Dukungan TI",
  "identityCheck.pending.pleaseWait":
    "Saat video Anda sedang diverifikasi, jangan menavigasi keluar dari halaman ini.",
  "identityCheck.pending.estimatedWaitTime":
    "Waktu tunggu tipikal untuk verifikasi identitas adalah {minTimeMin} hingga {maxTimeMin} menit.",
  "identityCheck.verified.successMsg":
    "Kami telah memverifikasi identitas Anda.",
  "identityCheck.ivv.body.intro":
    "Menyiapkan nama pengguna dan kata sandi Amazon Anda memerlukan lapisan keamanan tambahan untuk membuktikan bahwa Anda adalah Anda, dan bukan seseorang yang berpura-pura menjadi Anda.",
  "identityCheck.ivv.body.consentcontent.agreebtnrequired":
    "Silakan centang kedua kotak di atas dan klik {agree} untuk mengakui bahwa Anda telah membaca dan memahami, atau klik Batalkan untuk memverifikasi identitas Anda menggunakan proses manual.",
  "identityCheck.ivv.failedBody":
    "<b>Kami tidak dapat memverifikasi identitas Anda menggunakan Pemindaian Wajah Otomatis. </b>Silakan <a>Rekam Video</a> memegang dokumen ID Anda di sebelah wajah Anda, dan selesaikan serangkaian gerakan tangan.",
  "identityCheck.ivv.failedBodyNoFallback":
    "<b>Kami tidak dapat memverifikasi identitas Anda menggunakan Pemindaian Wajah Otomatis. </b>{chatWithITSupportLink} untuk bantuan lebih lanjut.",
  "identityCheck.ivv.inFlight":
    "Anda sudah memiliki verifikasi dalam penerbangan dan tidak dapat memulai verifikasi baru. Harap segarkan halaman secara berkala untuk memeriksa statusnya.",
  "identityCheck.ivv.pendingBackendAgreementPre":
    "<b>Menyelesaikan pemeriksaan identitas Anda</b>",
  "identityCheck.ivv.pendingBackendAgreement":
    "Ini mungkin memakan waktu beberapa menit. Jika Anda telah menunggu lebih dari 10 menit, gunakan tombol “Dapatkan dukungan” untuk bantuan.",
  "identityCheck.ivv.scanAbortHeader":
    "Mengalami masalah dengan pemindaian otomatis Anda?",
  "identityCheck.ivv.scanAbortBody":
    "Tidak masalah. Anda dapat menggunakan <openModal>proses verifikasi alternatif kami sebagai gant</openModal> inya.",
  "identityCheck.ivv.scanAbortModalHeader":
    "Apakah Anda yakin ingin menggunakan proses verifikasi alternatif?",
  "identityCheck.ivv.scanAbortModalBody":
    " <p>Jika Anda telah memutuskan untuk melewatkan pemindaian biometrik otomatis. Hanya untuk memberi tahu Anda, Anda tidak akan dapat kembali dan menggunakan opsi pemindaian wajah setelah ini. </p><p>Proses verifikasi alternatif mungkin memakan waktu sedikit lebih lama, tetapi tim kami akan memandu Anda melalui semua yang Anda butuhkan untuk mengatur laptop Anda pada hari pertama Anda. </p>",
  "identityCheck.ivv.scanAbortModalCancel": "Batalkan",
  "identityCheck.ivv.scanAbortModalConfirm": "Konfirmasikan",
  "identityCheck.pathchooser.title":
    "Pilih preferensi verifikasi Anda dari salah satu metode berikut.",
  "identityCheck.pathchooser.card.recommended": "Direkomendasikan",
  "identityCheck.pathchooser.card.estCompletionAutomated":
    "Waktu untuk menyelesaikan: <b>Kurang dari {count} {unit}</b>",
  "identityCheck.pathchooser.card.estCompletionManual":
    "Waktu untuk menyelesaikan: <b>{count} {unit}</b>",
  "identityCheck.pathchooser.card.unitMinute": "menit",
  "identityCheck.pathchooser.card.unitMinutes": "menit",
  "identityCheck.pathchooser.card.verificationTypeAutomated":
    "Jenis verifikasi: <b>Otomatis</b>",
  "identityCheck.pathchooser.card.verificationTypeManual":
    "Jenis verifikasi: <b>Manual</b>",
  "identityCheck.pathchooser.card.oneId":
    "Diperlukan ID: <b>1 ID yang dikeluarkan pemerintah</b>",
  "identityCheck.pathchooser.card.twoIdsAutomated":
    "ID yang diperlukan: <b>2 ID yang dikeluarkan pemerintah</b>",
  "identityCheck.pathchooser.card.twoIdsManual":
    "ID yang diperlukan: <b>1 ID yang dikeluarkan Pemerintah, 1 ID Sekunder</b>",
  "identityCheck.pathchooser.card.errorSelectionRequired":
    "Jenis verifikasi harus dipilih",
  "identityCheck.pathchooser.card.errorAnyInFlight":
    "Verifikasi sudah dilakukan dalam penerbangan",
  "identityCheck.pathchooser.card.ivv.title": "Pemindaian Wajah Otomatis",
  "identityCheck.pathchooser.card.ivv.body":
    "Pindai wajah Anda pada hari pertama Anda dan kami akan membandingkannya dengan foto di ID yang dikeluarkan pemerintah Anda. Identitas Anda akan segera dikonfirmasi dan Anda akan siap untuk pergi.",
  "identityCheck.ivv.countDownTimer":
    " tersisa sampai memulai proses verifikasi alternatif (“tinjauan terverifikasi manusia”)",
  "identityCheck.pathchooser.card.manual.title": "Rekam Video",
  "identityCheck.pathchooser.card.manual.body":
    "Rekam video yang memegang dokumen ID Anda di sebelah wajah Anda, dan selesaikan serangkaian gerakan tangan. Kirim video ini ke verifikasi manusia Amazon untuk ditinjau.",
  "identityCheck.pathchooser.card.contactsupport.title": "Hubungi dukungan",
  "identityCheck.pathchooser.card.contactsupport.body":
    "Mengobrol dengan teknisi dukungan TI secara real time, dan verifikasi identitas Anda melalui panggilan konferensi video di Amazon Chime, yang akan memandu Anda melalui proses penyiapan manual.",
  "identityCheck.pathchooser.additionalConcerns":
    "Jika Anda mengalami masalah atau memiliki masalah tambahan, hubungi tim dukungan TI kami.",
  "identityCheck.pathchooser.additionalConcerns.contactIt":
    "Mereka dapat membantu Anda melalui panggilan video aman untuk memverifikasi identitas Anda. {chatWithITSupportLink}.",
  "identityCheck.pathchooser.additionalConcerns.chimeVerificationType":
    "Jenis verifikasi: Pang <b>gilan konferensi video</b>",
  "identityCheck.pathchooser.submitBtn": "Kirimkan",
  "identityCheck.pathchooser.continueBtn": "Lanjutkan",
  "amazonUsername.expander.title": "Nama pengguna Amazon",
  "amazonUsername.expander.body.info":
    "Di Amazon, Anda akan menggunakan nama pengguna Anda untuk masuk ke komputer Anda dan mengakses alat dan sumber daya Amazon. Ini adalah bagian dari alamat email Amazon Anda yang datang sebelum @. Alamat email Anda adalah {emailAddress}.",
  "amazonUsername.expander.body.username":
    "Nama pengguna Amazon Anda adalah: {username}",
  "registerSecurityKey.expander.title": "Daftarkan kunci keamanan",
  "registerSecurityKey.expander.body.intro":
    "Di Amazon, kami menggunakan kunci keamanan Anda sebagai langkah dalam otentikasi multi-faktor untuk memastikan itu Anda. Anda akan diminta untuk menekan kunci keamanan Anda untuk mengakses Jaringan Amazon, setiap hari. Setiap kali Anda menekan tombol Anda, itu akan menghasilkan string acak karakter.",
  "registerSecurityKey.expander.body.securityKeyTitle":
    "Apa itu kunci keamanan?",
  "registerSecurityKey.expander.body.securityKeyDescription1":
    " perangkat plastik yang digunakan untuk mengakses sumber daya Amazon dengan aman. Saat laptop Amazon Anda dikirimkan kepada Anda, Kami juga mengirimi Anda dua kunci keamanan USB. Kunci kedua adalah kunci cadangan Anda - simpan di tempat yang aman jika kunci utama Anda hilang atau rusak. “",
  "registerSecurityKey.expander.body.securityKeyDescription2":
    "Di Amazon, kami menggunakan kunci keamanan Anda sebagai langkah dalam otentikasi multi-faktor untuk memastikan Anda adalah diri Anda. Untuk menggunakan banyak alat dan sumber daya internal Amazon setiap hari, Anda akan memasukkan kata sandi Anda bersama dengan kunci keamanan Anda untuk mengakses jaringan Amazon. Pilih PIN kunci keamanan yang mudah diingat. Anda akan mengetiknya setiap hari ketika Anda masuk ke layanan internal Amazon dan VPN. Itu tidak kedaluwarsa.",
  "registerSecurityKey.expander.body.midwayTitle": "Apa itu Midway?",
  "registerSecurityKey.expander.body.midwayDescription":
    "Midway adalah layanan yang mengelola kunci keamanan dan PIN kunci keamanan.",
  "registerSecurityKey.expander.body.howTitle":
    "Bagaimana cara mendaftarkan kunci keamanan saya?",
  "registerSecurityKey.expander.body.step1":
    "Kirim PIN sementara ke email pribadi Anda untuk mengakses Midway. Buka email pribadi Anda untuk mengakses PIN Sementara ini.",
  "registerSecurityKey.expander.body.pinSentConfirmation":
    "Kami telah mengirimkan kode verifikasi ke email pribadi Anda. PIN Anda akan kedaluwarsa dalam 4 jam. Tidak menerima kode? {resendLink}",
  "registerSecurityKey.expander.body.step2":
    "Setelah Anda memiliki PIN Sementara, daftarkan kunci Anda di Midway. Anda akan diminta memasukkan nama pengguna Amazon dan PIN Sementara Anda.",
  "registerSecurityKey.expander.body.step3":
    "Colokkan salah satu kunci keamanan Anda ke port USB di laptop ini. Anda mungkin perlu menggunakan salah satu adaptor yang Anda terima. (Anda hanya perlu mendaftarkan 1 kunci keamanan Anda. Kunci kedua adalah cadangan.) Masukkan kunci ini dengan hati-hati dengan lubang jarum menghadap ke luar. Anda akan melihat lampu hijau ketika kunci keamanan ini dimasukkan dengan benar.",
  "registerSecurityKey.expander.body.step4":
    "Pada halaman Masuk, masukkan yang berikut ini:",
  "registerSecurityKey.expander.body.step4.1":
    "<bold>Nama pengguna Amazon:</bold> nama pengguna yang ditampilkan pada langkah sebelumnya.",
  "registerSecurityKey.expander.body.step4.2":
    "<bold>PIN (atau PIN kunci keamanan):</bold> PIN sementara yang dikirim ke alamat email pribadi Anda.",
  "registerSecurityKey.expander.body.step5":
    "Setelah memasukkan nama pengguna dan PIN kunci keamanan sementara, klik <bold>Masuk</bold>:",
  "registerSecurityKey.expander.body.step6":
    "Anda akan melihat pesan yang mengatakan “Selamat datang {username}!",
  "registerSecurityKey.expander.body.step7":
    "Klik tautan <bold>portal pendaftaran kunci keamanan</bold> di pesan selamat datang untuk melanjutkan.",
  "registerSecurityKey.expander.body.step8":
    "Pada halaman yang bertuliskan “Selamat datang di dasbor kunci keamanan,”klik <bold>Daftar kunci keamanan</bold>.",
  "registerSecurityKey.expander.body.step9":
    "Pada halaman yang bertuliskan “Memulai dengan kunci keamanan Anda,” klik <bold>Mulai pendaftaran</bold>. Jika Anda melihat dua opsi di sini, pilih Mulai registrasi kunci keamanan perangkat keras.",
  "registerSecurityKey.expander.body.step10":
    "Pilih gambar yang cocok dengan kunci keamanan yang Anda terima.",
  "registerSecurityKey.expander.body.step11":
    "Selanjutnya, Anda akan memasukkan kata sandi satu kali (OTP) yang dihasilkan oleh kunci keamanan Anda. Klik di kotak teks kosong yang bertuliskan “OTP Anda akan muncul di sini, jangan ketik di kotak ini”.",
  "registerSecurityKey.expander.body.step12.1":
    "Tekan tombol keamanan yang dicolokkan ke komputer Anda. Terapkan tekanan ringan selama 3-5 detik sampai serangkaian teks muncul di kotak teks kosong. Ini adalah OTP. Tergantung pada jenis kunci keamanan yang Anda miliki, Anda harus memberikan tekanan ringan ke <bold>sisi</bold> atau <bold>atasnya</bold>.",
  "registerSecurityKey.expander.body.step12.2":
    "<bold>Jika Anda menekan kunci keamanan tetapi tidak masuk ke OTP</bold>, lepaskan jari Anda dari kunci keamanan, klik kotak teks, lalu sentuh kunci keamanan lagi.",
  "registerSecurityKey.expander.body.step13":
    "Ketik PIN kunci keamanan permanen yang ingin Anda gunakan di bidang <bold>PIN baru dan Kon</bold> <bold>firmasikan PIN baru</bold>, lalu klik L <bold>anjutkan</bold>.",
  "registerSecurityKey.expander.body.step13.1":
    "Ketik PIN kunci keamanan permanen yang ingin Anda gunakan di bidang <bold>PIN baru dan Kon</bold> <bold>firmasikan PIN baru</bold>, lalu klik L <bold>anjutkan</bold>.",
  "registerSecurityKey.expander.body.step13.2":
    "<bold>Penting</bold>: Pilih PIN kunci keamanan yang mudah diingat. Anda akan mengetiknya setiap hari ketika Anda masuk ke layanan internal Amazon dan VPN. Itu tidak kedaluwarsa.",
  "registerSecurityKey.expander.body.step14":
    "Pada halaman yang bertuliskan “Silakan baca semua instruksi di bawah ini... “tekan L <bold>anjutkan</bold>.",
  "registerSecurityKey.expander.body.step15":
    "Di halaman berikutnya, tekan dan tahan kunci keamanan Anda lagi. Anda mungkin mendapatkan pop-up yang meminta informasi tentang kunci keamanan Anda. Klik <bold>Izinkan</bold>.",
  "registerSecurityKey.expander.body.screenPossibilityListTitle":
    "Selanjutnya, Anda akan melihat 1 dari 2 layar:",
  "registerSecurityKey.expander.body.screenPossibilityList.1": `Jika Anda melihat "<bold>Anda telah berhasil mendaftarkan kunci keamanan Anda</bold>“, Anda sudah selesai dengan langkah ini. Simpan kunci keamanan kedua Anda dengan aman. Pastikan Anda mendaftarkannya nanti sebagai cadangan jika Anda kehilangan yang baru saja Anda daftarkan.`,
  "registerSecurityKey.expander.body.screenPossibilityList.2":
    "Jika Anda diminta memasukkan PIN, Anda belum selesai. Lanjutkan dengan langkah-langkah berikut:",
  "registerSecurityKey.expander.body.setUpNewPin.1":
    "Ketik <bold>PIN kunci keamanan</bold> Anda ke kolom <bold>PIN</bold> dan <bold>Konfirmasi PIN</bold>, lalu klik Ber <bold>ikutnya</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.2":
    "Pada prompt berikutnya, tekan dan tahan kunci keamanan Anda lagi. Anda mungkin mendapatkan pop-up yang meminta informasi tentang kunci keamanan Anda. Klik <bold>Izinkan</bold>.",
  "registerSecurityKey.expander.body.setUpNewPin.3": `Jika pendaftaran berhasil, Anda akan melihat "<bold>Anda telah berhasil mendaftarkan kunci keamanan Anda</bold>." Jika Anda tidak melihat pesan ini, coba proses pendaftaran lagi di browser lain.`,
  "registerSecurityKey.expander.body.setUpNewPin.3.1": `Jika pendaftaran berhasil, Anda akan melihat <bold>"Anda telah berhasil mendaftarkan kunci keamanan Anda. </bold>"`,
  "registerSecurityKey.expander.body.setUpNewPin.3.2": `Jika Anda tidak melihat pesan ini, Anda harus mencoba lagi langkah pendaftaran. Tutup jendela Midway di sebelah kanan, dan coba lagi langkah ini dengan mengklik tombol “Daftarkan kunci keamanan di Midway” untuk mencoba lagi langkah ini.`,
  "registerSecurityKey.expander.body.secondKeySafe":
    "Simpan kunci keamanan kedua Anda dengan aman. Anda dapat mendaftarkannya nanti sebagai cadangan jika Anda kehilangan yang baru saja Anda daftarkan.",
  "registerSecurityKey.expander.body.note":
    "<bold>Catatan</bold>: Jika Anda mengalami kesalahan terkait “Kepatuhan Perangkat” atau “AEA”, Anda harus memulai ulang Easy Onboarding dengan keluar dari “tokenadmin” dan masuk kembali untuk memungkinkan pembaruan perangkat selesai. Silakan ikuti petunjuk yang diberikan kepada Anda di alamat email pribadi Anda untuk memulai kembali proses ini. Jika ini tidak menyelesaikan kesalahan, silakan gunakan tautan “Hubungi Dukungan” untuk bantuan.",
  "registerSecurityKey.checkbox.confirmation":
    "Saya mengonfirmasi bahwa saya telah mendaftarkan kunci keamanan saya.",
  "registerSecurityKey.button.sendTempPin": "Kirim PIN Sementara",
  "registerSecurityKey.button.sendTempPin.loadingText": "Mengirim...",
  "registerSecurityKey.button.sendTempPin.error":
    "Terjadi kesalahan saat mengirim PIN. Silakan coba lagi dalam beberapa menit.",
  "registerSecurityKey.button.sendTempPin.errorContactSupport":
    "Kami tidak dapat mengirimi Anda PIN sementara. Silakan hubungi dukungan.",
  "registerSecurityKey.button.registerSecurityKey":
    "Daftarkan kunci keamanan di Midway",
  "registerSecurityKey.link.resend": "Kirim ulang.",
  "registerSecurityKey.image.securityKeyGrid.altText": `Kisi enam gambar yang menunjukkan jenis kunci keamanan. Jenisnya adalah “YubiKey 4", “YubiKey 4 Nano”, “USB-C YubiKey”, “USB-C Nano”, “Zukey”, dan “USB-C Zukey”.`,
  "registerSecurityKey.image.tempPinLogin.altText":
    "Tangkapan layar halaman Masuk Amazon Midway. Halaman tersebut mengatakan “Masuk”, termasuk bidang untuk memasukkan nama pengguna Amazon dan PIN Amazon, dan memiliki tombol yang bertuliskan “Masuk”.",
  "registerSecurityKey.image.midwayWelcomeMessage.altText":
    'Tangkapan layar halaman contoh dengan pesan selamat datang. Contoh halaman selamat datang mengatakan “Selamat datang jeffbezos!. Untuk mendaftarkan atau mengelola kunci keamanan Anda, kunjungi portal pendaftaran kunci keamanan."',
  "registerSecurityKey.image.securityKeyDashboard.altText":
    "Tangkapan layar halaman selamat datang dasbor kunci keamanan Amazon Enterprise Access. Halaman tersebut mengatakan “Selamat datang di dasbor kunci keamanan. Amazon Enterprise Access” dan memiliki tombol yang bertuliskan “Daftar Kunci Keamanan”.",
  "registerSecurityKey.image.securityKeyGetStarted.altText":
    "Tangkapan layar halaman Memulai dengan kunci keamanan Anda. Halaman tersebut mengatakan “Mulai dengan kunci keamanan Anda. Ambil kunci keamanan di kantor Dukungan TI terdekat atau mesin penjual otomatis TI. Setelah mengambil kunci keamanan, klik Mulai pendaftaran. Penting: Anda harus membawa kunci keamanan baru Anda untuk mendaftarkannya. Anda harus mencolokkan kunci keamanan baru Anda pada saat ini. “dan memiliki tombol yang mengatakan “Mulai pendaftaran”.",
  "registerSecurityKey.image.securityKeyGetStartedGrid.altText":
    'Tangkapan layar halaman Memulai dengan kunci keamanan Anda. Halaman tersebut mengatakan “Mulai dengan kunci keamanan Anda. Kunci keamanan macam apa yang kau ambil? Pilih kunci keamanan yang cocok dengan yang Anda miliki. “dan memiliki grid enam jenis kunci keamanan. Jenis kunci keamanan yang terdaftar adalah “YubiKey 4", “YubiKey 4 Nano”, “USB-C YubiKey”, “USB-C Nano”, “Zukey”, dan “USB-C Zukey”.',
  "registerSecurityKey.image.securityKeyGetStartedOtp.altText":
    "Tangkapan layar halaman Memulai dengan kunci keamanan Anda. Halaman tersebut mengatakan “Mulai dengan kunci keamanan Anda. Pertama, masukkan kunci keamanan Anda di port USB di komputer Anda. Kedua, tekan dan tahan tombol keamanan selama 3-5 detik hingga teks ditampilkan di kotak di bawah ini. “dan memiliki bidang input teks dengan teks pengisi yang mengatakan “OTP Anda akan muncul di sini, jangan ketik di kotak ini”.",
  "registerSecurityKey.image.pressingSecurityKeySide.altText":
    "Gif gambar animasi seseorang yang menekan sisi kunci keamanan yang dicolokkan ke laptop",
  "registerSecurityKey.image.pressingSecurityKeyTop.altText":
    "Gif gambar animasi seseorang yang menekan bagian atas kunci keamanan yang dicolokkan ke lapop",
  "registerSecurityKey.image.securityKeyCreatePin.altText":
    "Tangkapan layar halaman pembuatan PIN kunci keamanan. Halaman tersebut mengatakan “Anda diminta untuk mengatur PIN kunci keamanan baru untuk mendaftarkan kunci keamanan ini. Jangan lupa PIN kunci keamanan Anda. PIN Anda akan digunakan untuk memungkinkan Anda masuk ke sumber daya Amazon dan terhubung ke VPN dengan kunci keamanan Anda”. Halaman ini juga menyertakan bidang input teks berlabel “PIN baru (8-64 karakter ASCII)”, bidang input teks lain berlabel “Konfirmasi PIN baru”, dan tombol yang bertuliskan “Lanjutkan”.",
  "registerSecurityKey.image.securityKeySuccess.altText":
    "Tangkapan layar pesan sukses. Pesan mengatakan “Anda telah berhasil mendaftarkan kunci keamanan Anda. Diperlukan waktu hingga 1 jam agar kunci keamanan ini berfungsi di semua alat dan sumber daya Amazon. “",
  "registerSecurityKey.image.securityKeyCreateNewPin.altText":
    "Tangkapan layar formulir pop-up untuk mengatur PIN baru. Pop-up bertuliskan “PIN diperlukan. Siapkan PIN baru untuk kunci keamanan Anda”. Pop-up juga memiliki bidang input teks berlabel “PIN” dan bidang input teks lain berlabel “Konfirmasi PIN”.",
  "connectVpn.expander.title": "Hubungkan ke Jaringan Amazon (VPN)",
  "connectVpn.expander.body.intro":
    "Anda harus terhubung ke VPN untuk mengakses sumber daya internal saat Anda bekerja di tempat atau jarak jauh. Waktu sesi VPN habis setiap 18 jam dan kemudian mengharuskan Anda untuk terhubung kembali.",
  "connectVpn.expander.body.mac.step1":
    "Buka Kli <bold>en Mobilitas Cisco AnyConnectSecure</bold>.",
  "connectVpn.expander.body.mac.step2":
    "Klien Cisco AnyConnect akan muncul. <bold>Klik Hubungkan</bold>.",
  "connectVpn.expander.body.mac.step3":
    "Sebuah pop up akan muncul. Di bidang <bold>Grup</bold>, pilih <bold>Orca-Amazon-Onboarding</bold> dari daftar drop-down.",
  "connectVpn.expander.body.mac.step4":
    "Masukkan nama pengguna Amazon Anda di bidang <bold>Nama pengguna</bold>.",
  "connectVpn.expander.body.mac.step5":
    "Di bidang Kunci keamanan PIN + Tekan kunci <bold>keamanan, ketik PIN kunci keamanan</bold> Anda, lalu tekan dan tahan kunci keamanan Anda sampai serangkaian karakter panjang muncul di bidang. Jangan klik OK - ketika Anda menekan dan menahan kunci keamanan Anda, koneksi VPN akan secara otomatis mulai diautentikasi.",
  "connectVpn.expander.body.mac.step5.warning":
    "Bidang <bold>kunci keamanan PIN + Tekan kunci keamanan</bold> mungkin mengatakan <bold>Kata Sandi sebagai</bold> gantinya. Jangan masukkan kata sandi Amazon Anda di sini. Masukkan PIN kunci keamanan Anda, lalu tekan dan tahan kunci keamanan Anda.",
  "connectVpn.expander.body.mac.step6":
    "Munculan akan muncul, mengonfirmasi bahwa Anda terhubung ke jaringan Amazon melalui VPN. Pilih, <bold>OK</bold>.",
  "connectVpn.image.mac.step2.altText":
    "Tangkapan layar aplikasi Cisco AnyConnect Secure Mobility Client.",
  "connectVpn.image.mac.step3.altText":
    "Tangkapan layar pop-up dengan kolom PIN kunci Grup, Nama Pengguna, dan Keamanan untuk terhubung ke VPN dengan aplikasi Cisco AnyConnect Secure Mobility Client dengan Orca-Amazon-onboarding dipilih dari daftar drop-down di bidang Grup.",
  "connectVpn.image.mac.step4.altText":
    "Tangkapan layar pop-up dengan kolom PIN kunci Grup, Nama Pengguna, dan Keamanan untuk terhubung ke VPN dengan aplikasi Cisco AnyConnect Secure Mobility Client dengan kotak merah yang menyoroti bidang Nama Pengguna.",
  "connectVpn.image.mac.step5.altText":
    "Tangkapan layar pop-up dengan kolom PIN Grup, Nama Pengguna, dan Kunci Keamanan untuk terhubung ke VPN dengan aplikasi Cisco AnyConnect Secure Mobility Client dengan kotak merah yang menyoroti PIN kunci keamanan + Tekan bidang kunci keamanan dan tombol OK dicoret dengan X merah.",
  "connectVpn.image.mac.step6.altText":
    "Tangkapan layar koneksi yang berhasil ke VPN.",
  "connectVpn.expander.body.windows.step1":
    "Tekan <bold>tombol Windows</bold> pada laptop ini yang akan memungkinkan akses ke “Start Menu”.",
  "connectVpn.expander.body.windows.step2":
    "Dari menu Start, pilih <bold>Cisco AnyConnect</bold> (ini adalah perangkat lunak Amazon untuk mengakses VPN).",
  "connectVpn.expander.body.windows.step3":
    "Klien Cisco AnyConnect akan muncul. Perangkat lunak Cisco AnyConnect adalah apa yang digunakan untuk VPN Amazon. Klik Hu <bold>bungkan</bold>.",
  "connectVpn.expander.body.windows.step4":
    "Klik untuk membuka menu drop-down di bidang Grup. Pilih <bold>Orca-Amazon-Onboarding</bold>.",
  "connectVpn.expander.body.windows.step5":
    "Masukkan nama pengguna Amazon Anda di bidang <bold>Nama pengguna</bold>.",
  "connectVpn.expander.body.windows.step6":
    "Di bidang <bold>Kata sandi</bold>, ketik PIN kunci keamanan Anda, lalu tekan dan tahan kunci keamanan Anda sampai serangkaian karakter yang panjang muncul di bidang. Jangan klik <bold>OK</bold> - ketika Anda menekan dan menahan kunci keamanan Anda, koneksi VPN akan secara otomatis mulai diautentikasi.",
  "connectVpn.expander.body.windows.step7":
    "Saat VPN terhubung, pemberitahuan keamanan akan muncul mengkonfirmasi koneksi. Klik <bold>OK</bold> untuk menutup kotak dialog.",
  "connectVpn.image.windows.step1.altText": "Tekan tombol Windows.",
  "connectVpn.image.windows.step2.altText":
    "Pilih Cisco AnyConnect dari menu mulai.",
  "connectVpn.image.windows.step4.altText":
    "Pilih Orca-Amazon-Onboarding dari daftar drop-down di bidang Grup.",
  "connectVpn.image.windows.step6.altText":
    "Ketik PIN kunci keamanan Anda, lalu tekan dan tahan keamanan Anda.",
  "connectVpn.button.tooltip.vpnNotConnectedHint": "Sambungkan ke VPN.",
  "amazonPassword.expander.title": "Buat Kata Sandi Amazon",
  "amazonPassword.expander.body.intro1":
    "Selanjutnya, Anda akan membuat kata sandi yang akan Anda gunakan untuk masuk ke laptop Anda setiap hari dan mengakses alat Amazon.",
  "amazonPassword.expander.body.intro2":
    "Anda akan mengetikkan PIN kunci keamanan Anda dan kemudian menekan dan menahan jari Anda pada kunci keamanan USB Anda, untuk mengatur kata sandi Anda untuk pertama kalinya. Harap <bold>ingat kata sandi ini</bold>, karena Anda akan membutuhkannya untuk melanjutkan pengaturan laptop Anda.",
  "amazonPassword.expander.body.banner":
    "Saat membuat kata sandi, Anda akan diminta untuk menekan jari Anda ke kunci keamanan USB Anda. Ini akan menekan “enter” untuk Anda. Tidak perlu mengklik “enter” atau “kirim”. Jika Anda menerima kesalahan, abaikan dan lanjutkan dengan pengaturan laptop Anda.",
  "amazonPassword.expander.body.confirmation":
    "Saya mengonfirmasi bahwa saya telah berhasil membuat kata sandi saya.",
  "amazonPassword.button.createPassword": "Buat kata sandi Amazon",
  "preCacheADMobile.expander.title": "Instal Pre-Cache AD Mobile",
  "preCacheADMobile.expander.body.intro":
    "Di Amazon, kami menggunakan alat yang disebut Precache AD untuk menyinkronkan informasi akun Anda yang terletak di jaringan Amazon ke laptop yang Anda gunakan. Ini memastikan bahwa Anda dapat masuk ke laptop Amazon Anda menggunakan kata sandi dan nama pengguna Amazon Anda setiap hari.",
  "preCacheADMobile.expander.body.step1":
    "Buka Aplikasi <bold>Self Service</bold> {logo} di desktop Anda.",
  "preCacheADMobile.expander.body.step2": "Masukkan nama pengguna Amazon Anda.",
  "preCacheADMobile.expander.body.step3":
    "Masukkan PIN kunci keamanan Anda dan tekan kunci keamanan Anda.",
  "preCacheADMobile.expander.body.step4":
    'Di kotak pencarian di kiri atas, masukkan "<bold>Pre-Cache</bold>”.',
  "preCacheADMobile.expander.body.step5":
    "Saat hasil pencarian dimuat, klik <bold>Cache</bold>.",
  "preCacheADMobile.expander.body.step6":
    "Masukkan nama pengguna Amazon Anda. Klik <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step7":
    "Masukkan kata sandi Amazon Anda. Klik <bold>OK</bold>.",
  "preCacheADMobile.expander.body.step8":
    "Skrip akan berjalan diam-diam selama 2-3 menit. Setelah selesai, sebuah “Sukses! “pop-up muncul. Klik <bold>OK</bold>.",
  "preCacheADMobile.image.step3.altText":
    "Tangkapan layar tampilan untuk masuk dengan nama pengguna Amazon dan PIN kunci keamanan Anda.",
  "preCacheADMobile.image.step4.altText":
    "Tangkapan layar tampilan untuk mencari Pre-Cache.",
  "preCacheADMobile.image.step6.altText":
    "Tangkapan layar tampilan untuk memasukkan nama pengguna Amazon Anda.",
  "preCacheADMobile.image.step7.altText":
    "Tangkapan layar tampilan untuk memasukkan kata sandi Amazon Anda.",
  "preCacheADMobile.image.step8.altText":
    "Tangkapan layar tampilan aplikasi Pre-Cache AD Mobile berhasil diinstal.",
  "preCacheADMobile.logo.altText":
    "Logo aplikasi AD pra-cache yang dapat diinstal melalui layanan mandiri",
  "banner.openViaWelcome":
    "Silakan buka halaman ini dengan mengklik “Mulai” di halaman selamat datang.",
  "allStepsCompleted.title": "👏 Kerja bagus!",
  "allStepsCompleted.mac.nextTo": "Selanjutnya, Anda perlu:",
  "allStepsCompleted.win.nextTo":
    "Selanjutnya, Anda harus masuk untuk pertama kalinya:",
  "allStepsCompleted.win.step1": "Langkah 1: Hubungkan ke VPN",
  "allStepsCompleted.win.instructionSent":
    "Instruksi ini juga telah dikirim melalui email kepada Anda",
  "allStepsCompleted.mac.postLoginStep1": "Keluar dari laptop Anda.",
  "allStepsCompleted.mac.postLoginStep2":
    "Periksa email pribadi Anda untuk instruksi untuk masuk ke akun Amazon Anda.",
  "allStepsCompleted.win.step1.subStep1":
    "Mulai ulang laptop ini dengan menekan tombol Windows dan memilih <bold>Restart</bold> dari menu <bold>Start</bold>.",
  "allStepsCompleted.win.step1.subStep2":
    "Klik ikon login jaringan untuk terhubung ke Amazon VPN. Ikon ini terlihat seperti 2 monitor komputer.",
  "allStepsCompleted.win.step1.subStep2.note":
    "<bold>Catatan:</bold> Menghubungkan ke VPN dari layar login hanya diperlukan untuk pengaturan akun pertama kali. Anda tidak perlu menggunakan VPN untuk login setelah pertama kali.",
  "allStepsCompleted.windows.vpnIcon.altText":
    "Ikon dengan 2 monitor komputer disorot dengan warna kuning.",
  "allStepsCompleted.win.step1.subStep3":
    "Klien Cisco AnyConnect akan muncul. Klik Hu <bold>bungkan</bold>.",
  "allStepsCompleted.win.step1.subStep4":
    "Klik untuk membuka menu drop-down di bidang <bold>Grup</bold>. Pilih <bold>Pra-Login</bold>.",
  "allStepsCompleted.win.step1.preLogin.altText":
    "Gambar klien Cisco VPN dengan grup Pra-Login yang dipilih.",
  "allStepsCompleted.win.step1.subStep5":
    "Masukkan nama pengguna Amazon Anda di bidang <bold>Nama Pengguna</bold> (nama pengguna Anda adalah {username}).",
  "allStepsCompleted.win.step1.subStep6":
    "Di bidang <bold>Kata Sand</bold> i, ketik PIN kunci keamanan Anda dan kemudian tekan dan tahan kunci keamanan Anda sampai serangkaian karakter yang panjang muncul di bidang. Jangan klik <bold>OK</bold> saat Anda menekan dan menahan kunci keamanan Anda, koneksi VPN akan secara otomatis mulai diautentikasi.",
  "allStepsCompleted.win.step1.securityKey.altText":
    "`Gambar jari menyentuh kunci keamanan yang dicolokkan ke komputer. `",
  "allStepsCompleted.win.step1.subStep6.note":
    "<bold>Penting:</bold> Saat menghubungkan ke VPN, jangan gunakan kata sandi Amazon Anda, meskipun tertulis “Kata Sandi”. Gunakan PIN Kunci Keamanan Anda dan kemudian berikan tekanan ringan selama 3-5 detik pada kunci keamanan yang dicolokkan ke komputer Anda.",
  "allStepsCompleted.win.step1.subStep7":
    "Ketika VPN terhubung, pemberitahuan keamanan akan muncul. Klik <bold>OK</bold> untuk menutup kotak dialog. Anda akan dibawa kembali ke layar login Windows.",
  "allStepsCompleted.win.step2": "Langkah 2: Masuk ke Akun Amazon Anda",
  "allStepsCompleted.win.step2.intro":
    "Masuk ke akun Amazon Anda dengan nama pengguna Anda, dan kata sandi yang Anda buat.",
  "allStepsCompleted.win.step2.subStep1":
    "Di bidang Nama pengguna, ketik <bold>ANT\\ {username}</bold>. Pastikan untuk menyertakan “ANT\\” sebelum nama pengguna Anda.",
  "allStepsCompleted.win.step2.subStep2":
    "Di bidang Kata Sandi, ketik <bold>kata sandi Amazon</bold> Anda dan tekan Enter.,",
  "allStepsCompleted.win.step2.subStep2.note1":
    "<bold>Penting:</bold> Setelah masuk ke akun Amazon Anda, Anda harus memutuskan sam <bold>bungan dari VPN</bold> untuk mengakses sumber daya Amazon. Jika Anda tidak memutuskan sambungan, Anda tidak akan dapat mengakses sumber daya apa pun. Anda dapat menemukan Cisco AnyConnect dari “Start Menu” untuk memutuskan sambungan dari VPN.",
  "allStepsCompleted.win.step2.subStep2.note2":
    "Jika VPN diperlukan untuk layanan lain yang spesifik untuk peran pekerjaan Anda, Anda harus menggunakan <bold>Orca-Corp-VPN. </bold>",
  "allStepsCompleted.win.step3": "Langkah 3: Buka Panduan Pengaturan Laptop",
  "allStepsCompleted.win.step3.intro":
    "Setelah Anda masuk, kunjungi {newHirePortalLink} untuk menyelesaikan pengaturan laptop Anda untuk alat umum yang digunakan di dalam Amazon seperti Chime, Slack, dan Outlook.",
  failedToRender:
    "Ada yang tidak beres. Silakan kunjungi {link} dan hubungi Dukungan TI untuk bantuan lebih lanjut.",
  "error.serviceNotAvailable.text1":
    "Aplikasi Amazon Day 1 saat ini tidak tersedia.",
  "error.serviceNotAvailable.step1.title": "Ubah Koneksi Jaringan",
  "error.serviceNotAvailable.step1.windows": "Pengguna Windows",
  "error.serviceNotAvailable.step1.windows.subStep1":
    "Tekan <bold>tombol Windows</bold> dan pilih ikon <bold>Akun</bold>.",
  "error.serviceNotAvailable.step1.windows.subStep2":
    'Pilih <bold>"Keluar"</bold>.',
  "error.serviceNotAvailable.step1.windows.subStep3":
    "Pada layar login <bold>Windows</bold>, cari ikon Wi-Fi di sudut kanan bawah.",
  "error.serviceNotAvailable.step1.windows.subStep4a":
    "Sambungkan ke koneksi jaringan yang berbeda.",
  "error.serviceNotAvailable.step1.windows.subStep4b":
    "Jika Anda berada di gedung perusahaan Amazon dan terhubung ke jaringan Wi-Fi A2Z, coba sambungkan ke Wi-Fi Tamu jika tersedia untuk Anda.",
  "error.serviceNotAvailable.step1.windows.subStep4c":
    "<bold>Penting</bold>: Anda harus terhubung ke Wi-Fi sebelum masuk dengan akun Anda.",
  "error.serviceNotAvailable.step1.windows.subStep5":
    "Masuk sebagai pengguna <bold>tokenadmin</bold> menggunakan kredensional yang disediakan di email Anda.",
  "error.serviceNotAvailable.step1.mac": "Pengguna macOS",
  "error.serviceNotAvailable.step1.mac.subStep1":
    "Pilih jaringan Wi-Fi yang berbeda dari pilihan Wi-Fi di sudut kanan atas layar.",
  "error.serviceNotAvailable.step2.title": "Hubungi Dukungan TI",
  "error.serviceNotAvailable.text2":
    "Jika Anda adalah karyawan baru, buka Panduan Pengaturan TI di firstaid.amazon-corp.com/vnho dari perangkat Amazon Anda atau perangkat pribadi Anda yang terhubung ke internet untuk menyelesaikan pengaturan laptop. Anda akan memerlukan manajer perekrutan atau Dukungan TI untuk membantu proses penyiapan. Jika manajer perekrutan Anda tidak tersedia, silakan gunakan tautan “Dapatkan Dukungan” yang terletak di halaman instruksi Pertolongan Pertama.",
  "error.somethingWentWrong":
    "Ada yang tidak beres. Silakan coba lagi atau {chatWithITSupportLink} untuk bantuan lebih lanjut.",
  "error.accountIssues":
    "Ada masalah dengan akun Anda. Silakan {chatWithITSupportLink}.",
  "error.ineligibleError.text1":
    "Ada masalah dengan akun Anda. Silakan baca di bawah ini sebelum menghubungi dukungan.",
  "error.ineligibleError.text2":
    "Jika Anda adalah karyawan baru, buka Panduan Pengaturan TI di firstaid.amazon-corp.com/vnho dari perangkat pribadi Anda yang terhubung ke internet untuk menyelesaikan pengaturan laptop.",
  "error.ineligibleError.text3":
    "Jika Anda orang Amazon yang menyiapkan laptop pengganti Anda, ikuti instruksi yang disertakan dengan laptop.",
  "error.ineligibleError.text4":
    "Jika Anda masih membutuhkan bantuan, kunjungi firstaid.amazon-corp.com untuk mendapatkan dukungan.",
  "error.notVerifiableError.text1":
    "Maaf, verifikasi identitas saat ini tidak tersedia.",
  "error.notVerifiableError.text2":
    "Untuk memverifikasi identitas Anda, silakan {chatWithITSupportLink}. Salah satu agen kami akan dengan senang hati membantu Anda melalui proses verifikasi. Kami mohon maaf atas ketidaknyamanan ini, dan menghargai pengertian Anda.",
  "error.beforeStartDate":
    "Tanggal mulai dan hari pertama Anda adalah {startDate}. Tolong tutup laptop Anda sampai saat itu. Butuh bantuan? {chatWithITSupportLink}.",
  "itHelpMsg.day1BlockingIssue":
    "Saya mengalami masalah dengan memulai proses perekrutan baru Easy Onboarding dan membutuhkan bantuan dengan pengaturan laptop hari pertama saya.",
  "itHelpMsg.contactSupportOnboardThroughChime":
    "Saya memerlukan bantuan untuk memverifikasi identitas saya untuk orientasi karyawan baru melalui panggilan video Chime.",
  "itHelpMsg.verificationIssueAndAssist":
    "Saya mengalami masalah dengan memverifikasi identitas saya melalui proses perekrutan baru Easy Onboarding dan memerlukan bantuan dengan pengaturan laptop hari pertama saya.",
  "itHelpMsg.verificationIssue":
    "Saya mengalami masalah dengan menyiapkan laptop saya sebagai bagian dari proses orientasi perekrutan baru dan memerlukan bantuan untuk memverifikasi identitas saya.",
  "itHelpMsg.verificationAssist":
    "Saya memerlukan bantuan untuk memverifikasi identitas saya sebagai bagian dari proses perekrutan baru Easy Onboarding dan memerlukan bantuan orientasi.",
  "itHelpMsg.settingUpLaptop":
    "Saya mengalami masalah dengan menyiapkan laptop saya sebagai bagian dari proses orientasi perekrutan baru.",
  "itHelpMsg.usernameIssue":
    "Saya mengalami masalah dengan nama pengguna saya sebagai bagian dari proses orientasi perekrutan baru saya.",
  "itHelpMsg.midwayAssist":
    "Saya memerlukan bantuan dengan Midway dan proses pendaftaran kunci keamanan sebagai bagian dari orientasi karyawan baru saya.",
  "itHelpMsg.midwayIssue":
    "Saya mengalami masalah dengan proses pendaftaran kunci Midway sebagai bagian dari orientasi karyawan baru saya. Saya sudah meminta PIN sementara dikirim.",
  "itHelpMsg.vpnIssue":
    "Saya mengalami masalah terkait koneksi VPN sebagai bagian dari orientasi karyawan baru saya.",
  "itHelpMsg.passwordIssue":
    "Saya mengalami masalah dengan alat Reset Kata Sandi sebagai bagian dari orientasi karyawan baru saya.",
  "itHelpMsg.preCacheADIssue":
    "Saya mengalami masalah dengan langkah Pre-Cache AD dalam orientasi perekrutan baru saya untuk perangkat Mac saya.",
  "mediacheck.failure.permission":
    "Untuk mengizinkan akses, klik ikon kamera {icon} di bilah alamat browser Anda. Anda mungkin perlu memuat ulang halaman agar perubahan diterapkan.",
  "mediacheck.failure.nodevice":
    "Tidak ada perangkat kamera yang ditemukan. Pastikan kamera dicolokkan dan berfungsi lalu muat ulang halaman.",
  "mediacheck.failure.other":
    "Masalah mencegah halaman mendeteksi jika Anda memiliki kamera yang dapat digunakan diinstal.",
  "mediacheck.failure.title": "Kamera diblokir.",
  "mediacheck.failure.cameraalt": "Ikon kamera",
  "cameraOn.text": "Mulai merekam, lalu {text}",
  "getSupport.header": "Dapatkan dukungan",
  "getSupport.footer": "Masih butuh dukungan?",
  "getSupport.chatSupport.clickToStart": "Mulai obrolan",
  "getSupport.chatSupport.clickToStartNew": "Mulai obrolan baru",
  "getSupport.faq.password.header": "FAQ Kata Sandi Amazon",
  "getSupport.faq.password.section1.title":
    "Kapan kata sandi saya kedaluwarsa?",
  "getSupport.faq.password.section1.contents": "",
  "getSupport.faq.vpn.header": "TANYA JAWAB VPN",
  "getSupport.faq.vpn.section1.title": "Kesalahan Konfigurasi CSD Cisco",
  "getSupport.faq.vpn.section1.contents": "Placeholder",
  "getSupport.faq.general.header": "FAQ Umum",
  "getSupport.faq.general.section1.title":
    "Apa yang harus saya lakukan jika saya belum menerima kode verifikasi?",
  "getSupport.faq.general.section1.contents": "kode verifikasi",
  "getSupport.faq.midway.header": "Midway FAQ",
  "getSupport.faq.midway.section1.title": "Apa itu kunci keamanan?",
  "getSupport.faq.midway.section1.contents":
    "Kunci Keamanan adalah stik USB fisik yang akan dimasukkan ke komputer Anda. Kami mengirimi Anda paket dengan dua Kunci Keamanan USB. Simpan kunci cadangan kedua di tempat yang aman, jika kunci utama Anda hilang atau rusak.",
  "getSupport.faq.username.header": "FAQ Nama Pengguna Amazon",
  "getSupport.faq.username.section1.title": "Apa itu Nama Pengguna Amazon?",
  "getSupport.faq.username.section1.contents":
    "Di Amazon, Anda akan menggunakan nama pengguna Anda untuk masuk ke komputer Anda dan mengakses alat dan sumber daya Amazon. Nama pengguna Anda adalah bagian dari alamat email Amazon Anda di depan tanda @.",
  "getSupport.idv.title":
    "Mengobrol dengan Dukungan TI untuk memverifikasi identitas Anda",
  "getSupport.idv.body.content1":
    "Jika Anda menghadapi masalah dengan proses di atas atau memiliki masalah lain, silakan hubungi tim dukungan TI.",
  "getSupport.idv.body.content2":
    "Tim dukungan TI dapat membantu Anda memverifikasi identitas Anda melalui panggilan video.",
  "arialabels.clickToCloseChat": "Klik untuk menutup panel dukungan obrolan",
  "arialabels.clickToStartChat": "Klik untuk memulai dukungan obrolan",
  "arialabels.clickToOpenChat": "Klik untuk membuka panel dukungan obrolan",
  "arialabels.clickToCloseGetSupport":
    "Klik untuk menutup mendapatkan panel dukungan",
  "arialabels.clickToOpenGetSupport": "Klik untuk membuka get support panel",
};

export default indonesian;
